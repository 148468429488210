import { useEffect, useRef, useState } from 'react';
import { THEME_FORM_INPUT } from '@scanow/theme';
import { BsChevronDown } from 'react-icons/bs';
import { classNames } from '@scanow/helpers';
import { useOutsideAlerter } from '@scanow/helpers';
import React from 'react';

export interface SearchSelectorProps {
  onSelected: (asset: {
    value: string;
    label: string;
  } | null) => void;
  values: {
    value: string;
    label: string;
  }[];
  selectedValue: string | null;
}

export function SearchSelector({ onSelected, values, selectedValue }: SearchSelectorProps) {
  const [search, setSearch] = useState<string>('');
  const [filteredValues, setFilteredValues] = useState(values);
  const [searchPanelOpen, setSearchPanelOpen] = useState<boolean>(false);

  const selectedValueLabel = selectedValue ? values.find((value) => value.value === selectedValue)?.label : null;
  const panelRef = useRef<any>();
  useOutsideAlerter(panelRef, () => setSearchPanelOpen(false));


  useEffect(() => {
    if (search === null || search === '') {
      setFilteredValues(values);
    } else {
      const searchString = search.toLowerCase();
      setFilteredValues(values.filter((value) => value.label.toLowerCase().includes(searchString)));
    }
  }, [search, values]);


  return (
    <div className={'relative'} ref={panelRef}>
      <div className={classNames(
        'bg-white border rounded-lg px-2 py-1 flex flex-row justify-between cursor-pointer transition-all border-gray-300',
        searchPanelOpen ? 'border-gray-300 shadow border-b-0 rounded-b-none' : 'border-gray-300',
      )} onClick={() => setSearchPanelOpen(!searchPanelOpen)}>

        <div className={'flex-row flex justify-between flex-grow px-0.5 py-1 '}>
          {
            selectedValueLabel === null ?
              <span className={'text-gray-500'}>--- Sélection ---</span> : <>
                <span>{selectedValueLabel}</span>
                <button
                  className={'text-blue-600 underline text-sm mr-4'}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onSelected(null);
                  }}>Réinitialiser
                </button>
              </>
          }

        </div>

        <BsChevronDown
          className={`h-4 w-4 mt-2 text-gray-500 hover:text-gray-700 transition ease-in-out duration-200 ${searchPanelOpen ? 'transform rotate-180' : ''}`}/>
      </div>
      {searchPanelOpen && (
        <div className={'absolute top-7 left-0 w-full bg-white border rounded-lg py-1 mt-1 z-10 border-gray-300 shadow border-t-0 rounded-t-none shadow-t-0'}>
          <div className={'mt-1 mb-2 mx-2'}>
            <input autoComplete={'off'} className={classNames(THEME_FORM_INPUT)} placeholder={'Recherche...'}
                   type="text" value={search} onChange={(e) => setSearch(e.target.value)}/>
          </div>


          <ul className={'max-h-44 overflow-auto'}>
            {filteredValues.length === 0 && (
              <li className={'text-gray-500 py-2 px-4 text-sm'}>Aucun résultat</li>
            )}
            {filteredValues.map((value) => (
              <li
                className={classNames('cursor-pointer hover:bg-secondary-600 hover:text-white transition-all px-2 py-0.5', selectedValue === value.value ? 'bg-secondary-500 pl-4 text-white' : '')}
                key={value.value}
                onClick={() => {
                  onSelected(value);
                  setSearchPanelOpen(false);
                }}>
                {value.label}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}