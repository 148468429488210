import React from 'react'
import {HiXMark} from "react-icons/hi2";


export interface CustomModalProps {
    children: React.ReactNode;
    isOpen: boolean;
    setState: React.Dispatch<React.SetStateAction<any>>;
    title?: string;
    appendClassName?: string;
    wide?: boolean;
    noMargin?: boolean;
}

export function CustomModal({
        children,
        isOpen,
        setState,
        title,
        appendClassName,
        wide,
        noMargin,
    }: CustomModalProps): JSX.Element {
    function closeModal() {
        setState(false);
    }

    function openModal() {
        setState(true);
    }

    function toggleModal() {
        setState(!isOpen);
    }


    return (
        <div
            onClick={closeModal}
            className={`
        fixed inset-0 w-screen h-screen justify-center items-center overflow-y-auto transition-colors
        ${isOpen ? "visible bg-black/20" : "invisible"}
      `}
        >
            {/* modal */}
            <div
                onClick={(e) => e.stopPropagation()}
                className={`
          bg-white shadow p-6 transition-all
          ${isOpen ? "scale-100 opacity-100" : "scale-125 opacity-0"}
        `}
            >
                <h2 className="text-2xl font-bold text-gray-900 sm:pr-12">{title}</h2>
                <button
                    onClick={closeModal}
                    className="absolute top-2 right-2 p-1 rounded-full text-gray-400 bg-white hover:bg-gray-50 hover:text-gray-600"
                >
                    <HiXMark className="w-7 h-7" aria-hidden="true"/>
                </button>
                {children}
                <button
                    type="button"
                    onClick={closeModal}
                    className="flex w-full mt-6 items-center justify-center rounded-md border border-transparent bg-secondary-600 px-8 py-3 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                    Fermer
                </button>
            </div>
        </div>
    )
}
