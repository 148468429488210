// @ts-nocheck
import dynamic from 'next/dynamic';
import React, { useMemo } from 'react';
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';
import { Label } from '@scanow/ui';
import { FieldErrorsImpl, SetFieldValue, UseFormRegisterReturn } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { fileManagerUploadFile, userAuthReturn } from '@scanow/helpers';


export interface WYSIWYGProps {
  type?: string;
  label?: string;
  onClick?: React.MouseEventHandler<HTMLTextAreaElement>;
  onChange?: React.FormEventHandler<HTMLTextAreaElement>;
  className?: string;
  disabled?: boolean;
  placeholder?: any;
  register?: UseFormRegisterReturn;
  setValue: SetFieldValue<any>;
  setState?: Function;
  state?: any;
  name?: string;
  errors?: FieldErrorsImpl<any>;
  value?: string;
  auth: userAuthReturn;
}

const QuillNoSSRWrapper = dynamic(
  async () => {
    const { default: RQ } = await import('react-quill');

    // @ts-ignore
    return ({ forwardedRef, ...props }) => <RQ ref={forwardedRef} {...props} />;
  },
  {
    ssr: false,
  },
);
const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'video',
  'color',
  'background',
  'code',
  `clean`,
];

export function WYSIWYGField({
                               setValue,
                               label,
                               onClick,
                               onChange,
                               placeholder,
                               className,
                               disabled,
                               register,
                               errors,
                               auth,
                               setState,
                               state,
                               name,
                               value,
                             }: WYSIWYGProps) {
  const quillRef = React.useRef<any>(null);
  const modules = useMemo(()=>({
    toolbar: {
      container: [
        [{ header: ['1', '2', '3', '4', '5', false] }, { font: [] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote', 'code'],
        [{ color: [] }, { background: [] }],
        [
          { list: 'ordered' },
          { list: 'bullet' },
          { indent: '-1' },
          { indent: '+1' },
        ],
        ['link', 'image'],
        ['clean'],
      ],
      handlers: {
        //  image: imageHandler,
        image: () => {

          const input = document.createElement('input');
          input.setAttribute('type', 'file');
          input.setAttribute('accept', 'image/*');
          input.click();
          input.onchange = async () => {
            const quillObj = quillRef.current.getEditor();
            const range = quillObj.getSelection();
            if (!input.files) return;
            const file = input.files[0];
            if (file.size > 5 * 1024 * 1024) {
              alert('Fichier trop volumineux, merci de choisir un fichier de moins de 5Mo');
              return;
            }
            try {
              const fileReturn = await fileManagerUploadFile(file, auth.accessToken as string);
              const position = range.index;
              console.log('Position: ', position);
              quillObj.insertEmbed(position, 'image', fileReturn.full_url);
            } catch (e) {
              console.error(e);
              alert('Une erreur est survenue lors de l\'upload du fichier, verifiez que le fichier est valide et réessayez');
            }
          };
        },

      },
    },
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  }),[]);


  function defaultOnChange(content) {
    if (register && setValue) {
      setValue(register.name, content);
    } else if (setState) {
      setState(content);
    }
  }


  return (
    <fieldset className="my-5">
      <Label htmlFor={register?.name}>{label}</Label>
      {register ? (
        <>

          <QuillNoSSRWrapper
            forwardedRef={quillRef}
            id={register?.name || ''}
            className={className || ''}
            placeholder={placeholder}
            formats={formats}
            modules={modules}
            theme="snow"
            onChange={defaultOnChange}
            value={value || ''}
          />
          {errors && (
            <ErrorMessage
              errors={errors}
              name={register?.name}
              render={({ message }: any) => (
                <small className="absolute text-xs py-1 text-danger-500 w-full h-12 block ">
                  {message}
                </small>
              )}
            />
          )}
        </>
      ) : (
        <>
          {/* @ts-ignore */}
          <QuillNoSSRWrapper
            forwardedRef={quillRef}
            id={name || ''}
            // onChange={onChange || defaultOnChange} TODO OnChange Error
            className={className || ''}
            placeholder={placeholder}
            formats={formats}
            modules={modules}
            theme="snow"
            onChange={defaultOnChange}
            value={value}
          />
        </>
      )
      }
    </fieldset>
  );
};


