import React, { useState } from "react";
import moment from "moment-timezone";
import { IoAddOutline, IoCalendarOutline, IoCreateOutline, IoFolderOutline, IoWarningOutline, } from "react-icons/io5";
import { dateFormat, userAuthReturn } from '@scanow/helpers';
import Image from "next/image";
import { Text, Button, Modal } from "@scanow/ui";
import { ISettingsCompany } from "../Interfaces";
import { CompanyUpdateWrapper } from "../../company/Wrapper/Update";


interface CompanyProps {
  company: ISettingsCompany;
  role: number;
  auth: userAuthReturn;
}

export function ServiceCompany({ company, role, auth }: CompanyProps): JSX.Element {
  const [updateCompany, setUpdateCompany] = useState(false);



  const remainingResource =
    company.licence_count - company.resources_aggregate.aggregate.count;

  const today = moment();

  return (
    <div>
      <header className="flex justify-between center pb-12">
        <div className={"flex items-center"}>
          <Button
            appendClassName={
              "bg-indigo-100 text-indigo-700 text-font w-12 h-12 rounded-full mr-3 shrink-0"
            }
            disabled={true}
            onClick={(e) => {
              e.preventDefault();
              setUpdateCompany(true);
            }}
          >
            <IoCreateOutline size={25} className="mx-auto" />
          </Button>
          <span>
            {/* <h3 className="font-title font-semibold">Mon Entreprise</h3> */}
            <Text.Subtitle>{company.name}</Text.Subtitle>
          </span>
        </div>
        <div className="my-auto w-1/2">
          {today > moment(company.licence_end_date) ? (
            <>
              {/*dateFormat(
                    company.licence_end_date
                  ).formatFullDate()*/}
              <Button
                appendClassName={
                  "text-sm inline-flex bg-error-light text-error rounded-full p-2"
                }
              >
                <IoWarningOutline size={20} />{" "}
                {" Licence expirée" }
              </Button>
            </>
          ) : (
            <Text.Medium>
              Renouvellement licence au{" "}
              {dateFormat(company.licence_end_date).formatFullDate()}
            </Text.Medium>
          )}
        </div>
      </header>
      <div className="grid md:grid-cols-1 xl:grid-cols-2 md:text-sm text-font-light">
        <div className="relative w-full h-full mb-4">
          <Image
            src={company.logo_url || "/images/no_img.jpg"}
            alt={company.name}
            layout="fill"
            objectFit="contain"
          />
        </div>
        <ul>
          <li>
            <Text.Medium>Abonné depuis le {dateFormat(company.created_at).formatFullDate()}</Text.Medium>
          </li>
          {/*<li>Ressources commandées : {company.licence_count}</li>*/}
          <li>
            {remainingResource > 0 ? (
              <>{remainingResource}</>
            ) : (
              <>
                <span className="text-danger">Aucune</span>
                {/* TODO Component commander */}
                <Button appendClassName={"bg-info-light text-info rounded-full p-2"}>
                  <IoAddOutline size={18} />
                </Button>
              </>
            )}{" "}
            ressources restantes
          </li>
          <Text.Subtitle appendClassName="mt-4">Mes Options</Text.Subtitle>

          <li className="mt-4 flex inline">
            {company.licence_option_documentation ? (
              <>
                <Button
                  appendClassName={
                    "bg-indigo-100 text-indigo-700 text-font w-12 h-12 mr-2 rounded-full"
                  }
                >
                  <IoCalendarOutline size={25} className="mx-auto" />
                </Button>
              </>
            ) : (
              <>
                <Button
                  appendClassName={
                    "bg-gray-light text-font-light text-font w-12 h-12 mr-2 rounded-full"
                  }
                >
                  <IoCalendarOutline size={25} className="mx-auto" />
                </Button>
                {/* TODO Component commander */}
                <Button appendClassName="flex items-center">
                  <Text.Small>commander</Text.Small>
                </Button>
              </>
            )}
            {company.licence_option_control ? (
              <>
                <Button
                  appendClassName={
                    "bg-indigo-100 text-indigo-700 text-font w-12 h-12 rounded-full"
                  }
                  disabled={true}
                >
                  <IoFolderOutline size={25} className="mx-auto" />
                </Button>
              </>
            ) : (
              <>
                <Button
                  appendClassName={
                    "bg-gray-light text-font-light text-font w-12 h-12 rounded-full"
                  }
                >
                  <IoFolderOutline size={25} className="mx-auto" />
                </Button>
                {/* TODO Component commander */}
                <Button appendClassName="flex items-center">
                  <Text.Small>commander</Text.Small>
                </Button>
              </>
            )}
          </li>
        </ul>
      </div>

      <Modal isOpen={updateCompany} setState={setUpdateCompany}>
        <CompanyUpdateWrapper id={company.id} role={role} auth={auth} />
      </Modal>
    </div>
  );
}