import React, { FormEventHandler, useState } from "react";
import { Table, ActionCell, Modal } from "@scanow/ui"
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { useTranslation } from "next-i18next";
import { HiOutlineCog8Tooth } from "react-icons/hi2";
import { apolloErrorParser } from "@scanow/helpers";
import { TeamCreateForm } from "../Form";
import { ITeamLite } from "../Interfaces";
import { DELETE_TEAM, GET_ALL_TEAMS_NAME } from "../Queries";
import { TeamUpdateWrapper, TeamDetailWrapper } from "../Wrapper";
import { useRouter } from "next/router";


interface TeamProps {
  teams: ITeamLite[];
  readOnly?: boolean;
}

export function TeamList({ teams, readOnly }: TeamProps): JSX.Element {
  const { t } = useTranslation("team");
  const [updateModalOpen, setUpdateModalOpen] = useState<boolean>(false);
  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);
  const [detailModalOpen, setDetailModalOpen] = useState<boolean>(false);
  const [selectedTeam, setSelectedTeam] = useState<ITeamLite | null>(null);
  // TODO Voir pour delete ou disable
  const [disableUser] = useMutation(DELETE_TEAM, {
    refetchQueries: [GET_ALL_TEAMS_NAME],
  });
  const router = useRouter()


  const columns = React.useMemo(
    () => [
      {
        Header: "Équipe",
        accessor: "name",
      },
      {
        Header: "Téléphone",
        accessor: "phone",
      },
      {
        Header: "Email",
        accessor: "mail",
      },
      {
        Header: "Total membres",
        accessor: "users_aggregate.aggregate.count",
      },
      {
        //@ts-ignore
        Header: <HiOutlineCog8Tooth size={22} alt="Action" />,
        accessor: "action",
        disableSortBy: true,
        Cell: (props: any) => {
          function showHandler(event: FormEventHandler) {
            setSelectedTeam(props.cell.row.original);
            setDetailModalOpen(true);
          }

          function updateHandler(event: FormEventHandler) {
            setSelectedTeam(props.cell.row.original);
            setUpdateModalOpen(true);
          }

          function deleteHandler(event: FormEventHandler) {
            disableUser({ variables: { id: props.cell.row.original.id } })
              .then((data: any) => {
                toast.success(t("message.success_delete"));
              })
              .catch((err) => {
                let parsed_error = apolloErrorParser(err);
                toast.error(t("message.error_delete") + "(" + parsed_error.type + ")");
              });
          }

          return ActionCell({
            showHandler: showHandler,
            updateHandler: readOnly ? undefined : updateHandler,
            deleteHandler: readOnly ? undefined : deleteHandler,
            ...props,
          });
        },
      },
    ],
    []
  );

  return (
    <div>
      <Table
        columns={columns}
        data={teams}
        cb_add={readOnly ? undefined :() => {
          setCreateModalOpen(true);
        }}
        onRowClick={(row: any) => {
          router.push(`/admin/team/${row.original.id}`);
        }}
      />

      {/*Create Modal*/}
      <Modal
        isOpen={createModalOpen}
        setState={setCreateModalOpen}
        title={t("create")}
      >
        <TeamCreateForm
          cb_success={(data: any) => {
            setCreateModalOpen(false);
            toast.success(t("message.success_create"));
          }}
          cb_error={(err: any) => {

            toast.error(t("message.error_create"));
          }}
        />
      </Modal>
      {/*Fin Create Modal*/}

      {/*Update Modal*/}
      <Modal
        isOpen={updateModalOpen}
        setState={setUpdateModalOpen}
        title={t("update", {
          name: selectedTeam?.name ?? "",
        })}
      >
        <>
          {selectedTeam && (
            <TeamUpdateWrapper
              id={selectedTeam.id}
              cb_success={(data: any) => {
                setUpdateModalOpen(false);
                toast.success(t("message.success_update"));
              }}
              cb_error={(err: any) => {
                toast.error(t("message.error_update"));
              }}
            />
          )}
        </>
      </Modal>
      {/*FIN Update Modal*/}

      {/*Detail Modal*/}
      <Modal
        isOpen={detailModalOpen}
        setState={setDetailModalOpen}
        title={t("detail", {
          name: selectedTeam?.name ?? "",
        })}
      >
        <>{selectedTeam && <TeamDetailWrapper id={selectedTeam.id} />}</>
      </Modal>
    </div>
  );
}
