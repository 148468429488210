import { useRouter } from "next/router";
import React, { useState } from "react";
import { CheckboxField, InputField } from "@scanow/ui";
import { SubmitButton } from "@scanow/ui";
import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form"

import { CREATE_TEAM, GET_ALL_TEAMS_NAME } from "../Queries"
import { capitalize, lowercase, REGEX_EMAIL_PATTERN } from '@scanow/helpers';


interface ITeamForm {
  name: string;
  phone?: string;
  mail: string;
  mail_notify_on_incident: boolean
  // phone_notify_on_incident: boolean Pour plus tard

}

interface Props {
  cb_success?: any;
  cb_error?: any;
}

export function TeamCreateForm({ cb_success, cb_error }: Props): JSX.Element {
  const { register, handleSubmit, setValue, getValues, formState: { errors } } = useForm<ITeamForm>()
  const router = useRouter();
  const [submittedData, setSubmitedData] = useState<ITeamForm | null>(null);
  const [createTeam] = useMutation(CREATE_TEAM, {
    refetchQueries: [GET_ALL_TEAMS_NAME],
  });

  function submitHandler(data: ITeamForm) {
    data.name = capitalize(data.name)
    data.mail = lowercase(data.mail)
    createTeam({
      variables: { ...data, mail_notify_on_incident: data.mail_notify_on_incident || false },
    })
      .then((data) => {
        // en cas de succès de la requète
        // Verifier si une fonction callback est dispo
        if (cb_success) {
          cb_success(data.data.team);
        } else {
          //Sinon executer fonction par défaut
          setSubmitedData(data.data.team);
          router.reload();
        }
      })
      .catch((err) => {
        // en cas de d'erreur de la requète
        // Verifier si une fonction callback est dispo
        if (cb_error) {
          cb_error(err);
        } else {
          //Sinon executer fonction par défaut
          console.error(err);
        }
      });

  }


  return (
    <>
      {submittedData ? (
        <p>Resource crée !!</p>
      ) : (
        <form onSubmit={handleSubmit(submitHandler)}>
          <InputField
            errors={errors}
            label={"Nom"}
            register={register("name", { required: "ce champ est requis" })}
          />
          <InputField label={"Téléphone"} register={register("phone", { required: false })} />
          <InputField label={"Email"}
            errors={errors}
            register={register("mail", {
              required: "Ce champ est requis",
              pattern: {
                value: REGEX_EMAIL_PATTERN,
                message: "Ce champ doit être une adresse email valide"
              }
            })}
          />
          <CheckboxField label={"Recevoir les notifications"} register={register("mail_notify_on_incident")} />
          <SubmitButton value="Enregistrer" />
        </form>
      )}
    </>
  );
};
