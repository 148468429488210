import { Avatar } from "@scanow/ui";
import React from "react";
export function AvatarCell({ value, column, row }: any) {
  return (
    <Avatar
      src={
        row.original[column.imgAccessor] || "/images/no_avatar.png"
      }
      appendClassName="w-9 h-9 my-auto"
    />
  );
}
