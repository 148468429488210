import React from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import {
  IoCheckmarkCircle,
  IoWarning,
  IoInformationCircleSharp,
  IoAlertCircle,
  IoBug,
} from "react-icons/io5";

interface AlertProps {
  type: "success" | "info" | "error" | "warning"
  //position: "tl" | "tc" | "tr" | "bl" | "bc" | "br" | "bl" TODO
  message: string
}
// TODO Déprecated je suppose ? importé nulle part
export const displayIcon = (type: any) => {
  switch (type) {
    case "success":
      return <IoCheckmarkCircle />;
    case "info":
      return <IoInformationCircleSharp />;
    case "error":
      return <IoAlertCircle />;
    case "warning":
      return <IoWarning />;
    default:
      return <IoBug />;
  }
};


export const ToastMessage = ({ type, message/*, position*/ }: AlertProps) =>
  toast[type](
    <div>
      <div
      >
        {/*displayIcon(type)*/}
      </div>
      <div>
        {message}
      </div>
    </div>
  );

ToastMessage.propTypes = {
  type: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  // position: PropTypes.string.isRequired, TODO
};

ToastMessage.dismiss = toast.dismiss;

//export default ToastMessage;
