// APP CONFIG
export const APP_NAME = process.env.NEXT_PUBLIC_APP_NAME || "Scanow";
export const APP_VERSION = process.env.NEXT_PUBLIC_APP_VERSION || "v3";
export const APP_URL = process.env.NEXT_PUBLIC_APP_URL || "https://scanow.fr";
export const APP_COLOR = process.env.NEXT_PUBLIC_APP_COLOR || "#FFFFFF";
export const APP_EMAIL = "info@scanow.fr";
export const APP_PHONE = "+33 6 59 22 35 05";
export const APP_ADDRESS = "3 Avenue Joseph Bédier, 75013 Paris - France.";

// META CONFIG
export const APP_TITLE = process.env.NEXT_PUBLIC_APP_NAME || "Scanow";
export const APP_DESCRIPTION = process.env.NEXT_PUBLIC_APP_DESCRIPTION || "Transformez le service desk de votre entreprise, inventoriez vos équipements et assurez une assistance simple, rapide et flexible.";
export const APP_TYPE = process.env.NEXT_PUBLIC_APP_TYPE || "website";
export const APP_AUTHOR = process.env.NEXT_PUBLIC_APP_AUTHOR || "Artdigit";
export const APP_AUTHOR_URL = process.env.NEXT_PUBLIC_APP_AUTHOR_URL || "https://artdigit.io";


// SMTP CONFIG
export const MAIL_HOST = process.env.NEXT_PUBLIC_MAIL_HOST || "mail.scanow.fr";
export const MAIL_USER = process.env.NEXT_PUBLIC_MAIL_USER || "scanow";
export const MAIL_PWD = process.env.NEXT_PUBLIC_MAIL_PWD || "scanow";

export enum APPLICATIONS {
    Helpdesk = "HELPDESK",
    Inventory = "INVENTORY",
    Asset = "ASSET",
}

