//import { useAppSelector } from "app/hooks";
//import { selectCurrentMediaRunning } from "app/mediaRunning/mediaRunning";
import React from "react";

interface VideoPlayerProps {
  id?: string;
  name?: string;
  video?: string;
  className?: string;
  description?: string;
  thumbnail?: string;
  appendClassName?: string;
  sizeClass?: string;
}

export const VideoPlayer = ({
  id,
  className = "inset-0 z-10 flex items-center justify-center mx-auto overflow-hidden",
  appendClassName = "",
  video = "",
  name,
  description,
  thumbnail,
  sizeClass = "w-full h-full",
}: VideoPlayerProps) => {
  /* TODO 
  //const currentMediaRunning = useAppSelector(selectCurrentMediaRunning);
/*
  const IS_PLAY =
    currentMediaRunning.id === id &&
    currentMediaRunning.state === "playing";

  if (!IS_PLAY) {
    return null;
  }*/

  return (
    <div
      title={name}
      dangerouslySetInnerHTML={{
        __html: `<video className="w-full h-full" playsinline autoplay muted loop  >
                    <source src=${video} type="video/mp4" />
                    <meta itemprop="name" content=${name}>
                    <meta itemprop="description" content=${description}>
                    <meta itemprop="thumbnail" content=${thumbnail}>
                    your browser does not support the video tag.
                  </video>`,
      }}
    />
  );
};
