import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { IUserSettings } from "../Interfaces";
import { GET_USER_SETTINGS } from "../Queries";
import {
  HiOutlineBellAlert,
  HiOutlineBellSlash,
  HiOutlineCog8Tooth
} from "react-icons/hi2";
import { dateFormat, userAuthReturn } from "@scanow/helpers";
import { Text, Loading, Button, Card, Avatar, Modal, ErrorMessage } from "@scanow/ui";
import { toast } from "react-toastify";
import { UserUpdateWrapper } from "../Wrapper/Update";
import { UserDetailWrapper } from "../Wrapper";

interface UserProps {
  id: string;
  role: number;
  auth: userAuthReturn;
  application: "sonar" | "scanow";
}

interface ApolloData {
  user: IUserSettings;
}

export function UserSettings({ id, role, auth, application }: UserProps) {
  const [updateModal, setUpdateModal] = useState<boolean>(false);
  const { data, loading, error } = useQuery<ApolloData>(GET_USER_SETTINGS, {
    variables: { id: id },
  });

  function cbSuccess() {
    toast.success("Utilisateur modifié avec succès !");
    setUpdateModal(false);
  }
  function cbError() {
    toast.error("Erreur lors de la tentative de modification de l'utilisateur.");
    setUpdateModal(false);
  }

  if (loading) {
    return <Loading />;
  }
  if (error) {
    console.error(error);
    return (<ErrorMessage message="Une erreur inconnue s'est produite"/>)
  }
  if (data) {
    return (
      <div className="mx-auto flex flex-col justify-center items-center h-full">
        <div>
          <UserDetailWrapper id={id} />
        </div>

        <div className="flex justify-center items-center gap-2">
          <Button.Circle>
            <HiOutlineCog8Tooth
              onClick={() => setUpdateModal(true)}
              title="Modifier les paramètres de l'Utilisateur"
              className="w-5 h-5"
            />
          </Button.Circle>
          {data?.user.mail_notify_on_incident ? (
              <>
              <Button.Variant color="Success" style="Circle" shape="Full"><HiOutlineBellAlert
                  className="w-5 h-5"
                  title="Réception des notifications par mail activée"
              /></Button.Variant>
              </>
            ) : (
              <Button.Variant color="Danger" style="Circle" shape="Full"><HiOutlineBellAlert
                  className="w-5 h-5"
                  title="Réception des notifications par mail désactivée"
              /></Button.Variant>
            )}
        </div>
        <hr className="my-4 w-full lg:w-6/12 mx-auto dark:border-neutral-700" />
        <div>
          <Text.Normal appendClassName=" pb-2 text-center">
           Mes Rôles{" "}
          </Text.Normal>
          <div className="flex gap-2 flex-wrap justify-center items-center">
            {data?.user.user_role.map((r) => (
              <>
                  <Text.Small
                      title={"Rôle" + " " + r.role.name}
                      appendClassName="ml-4 bg-secondary-200 text-secondary-800 hover:opacity-80 dark:border-indigo-300 dark:text-indigo-300 rounded-full px-4 py-2 font-semibold"

                  >
                      {r.role.name}
                  </Text.Small>
              </>
            ))}
          </div>
        </div>
        <Modal
          isOpen={updateModal}
          setState={setUpdateModal}
          title={
            "Modifier " +
            data?.user.firstname +
            " " +
            data?.user.lastname
          }
        >
          {data && (
            <UserUpdateWrapper
              application={application}
              auth={auth}
              id={id}
              cb_error={cbError}
              cb_success={cbSuccess}
              role={role}
            />
          )}
        </Modal>
      </div>
    );
  }

  return (<ErrorMessage message="Une erreur inconnue s'est produite"/>);
}