import React from "react";
import { THEME_FORM_INPUT } from "@scanow/theme";
import { RiSearch2Line, RiSearchLine } from "react-icons/ri";

export function TableSearch({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}: any) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = (value: any) => {
    setGlobalFilter(value || undefined);
  };

  return (
    <div className="relative w-64 h-12">
      <input
        type="search"
        name="search"
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`Rechercher parmi ${count} résultat${count > 1 && "s"}`}
        className="placeholder:text-xs placeholder-neutral-300 rounded-full border-neutral-200 w-full pl-4 py-2 focus:border-secondary-500 focus:ring-transparent bg-white dark:border-neutral-700 dark:focus:border-secondary-500  dark:bg-neutral-900 dark:disabled:bg-neutral-800 invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
      />
      <span className="absolute top-5 -translate-y-1/2 right-3 text-neutral-500">
        <RiSearchLine className="text-neutral-300" />
      </span>
      <input type="submit" hidden value="" />
    </div>
  );
}
