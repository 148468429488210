import { gql } from "@apollo/client";

export const GET_ALL_COMPANIES = gql`
  query allCompanies {
    companies: administration_company(order_by: {created_at: desc}) {
      id
      name
      slug
      created_at
    }
  }
`;

export const GET_ALL_COMPANIES_NAME = gql`
  query allCompaniesByName {
    companies: administration_company(order_by: {created_at: desc}) {
      id
      name
    }
  }
`;

export const GET_COMPANY_AUTH_SETTINGS = gql`
  query getCompanyAuthSettings($id: uuid!) {
    company: administration_company_by_pk(id: $id) {
      id
      name
      logo_url
      main_color
      defaut_team_id
      licence_count
      licence_end_date
      licence_option_control
      licence_option_documentation
    }
  }
`;



export const GET_COMPANY_DETAIL = gql`
query companyDetail($id: uuid!) {
  company: administration_company_by_pk(id: $id) {
    id
    name
    slug
    logo_url
    created_at
    licence_count
    licence_end_date
    licence_option_control
    licence_option_documentation
    setup
    equipments_aggregate {
      aggregate {
        count
      }
    }
    resources_aggregate(where: {deleted: {_eq: false}}) {
      aggregate {
        count
      }
    }
    incidents_aggregate {
      aggregate {
        count
      }
    }
    locations_aggregate {
      aggregate {
        count
      }
    }
    users_aggregate {
      aggregate {
        count
      }
    }
  }
}`;

export const CREATE_COMPANY = gql`
    mutation createCompany($name: String!, $slug: String) {
        company: insert_administration_company_one(object: { name: $name, slug: $slug }) {
            id
            name
            slug
        }
    }
`;

export const UPDATE_COMPANY = gql`
  mutation updateCompany($id: uuid!, $_set: administration_company_set_input) {
    company: update_administration_company_by_pk(
      pk_columns: { id: $id }
      _set: $_set
    ) {
      id
      name
      slug
    }
  }
`;


export const GET_UPDATED_COMPANY = gql`
query getUpdatedCompany($id: uuid!){
    company: administration_company_by_pk(id: $id){
        id
        name
        slug
        logo_url
        main_color
        defaut_team_id
        incident_phone_enabled
        incident_mail_enabled
        incident_phone_required
        incident_mail_required
        licence_end_date
        licence_count
        licence_option_documentation
        licence_option_control
        setup
    }
}
`

export const DELETE_COMPANY = gql`
    mutation deleteCompany($id: uuid!) {
        company: delete_administration_company_by_pk(id: $id) {
            id
        }
    }
`;

export const GET_COMPANY_SETUP = gql`
query companySetup($id: uuid!) {
  company: administration_company_by_pk(id: $id) {
      id
    name
    logo_url
    main_color
  }
}
`


export const GET_COMPANY_SETTINGS = gql`
query getCompanySettings($id: uuid!) {
  company: administration_company_by_pk(id: $id){
    id
    name
    slug
    logo_url
    main_color
    defaut_team_id
    default_team {
      id
      name
    }

  }
}`;

export const GET_COMPANY_HELPDESK_SETTINGS = gql`
query getCompanyHelpDeskSettings($id: uuid!) {
  company: administration_company_by_pk(id: $id){
    id
    name
    incident_phone_enabled
    incident_phone_required
    incident_mail_enabled
    incident_mail_required
  }
}`;

export const GET_COMPANY_INVENTORY_SETTINGS = gql`
query getCompanyInventorySettings($id: uuid!) {
  company: administration_company_by_pk(id: $id){
    id
    name
  }
}`;

export const LICENCE_REQUEST = gql`
mutation LicenceRequest($data: licenceNotifyInput!) {
  licenceNotify(data: $data) {
    done
  }
}`;