import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface NavigationState {
  elements: NavigationElement[];
  lastId: number;
}

interface NavigationElement {
  id: number;
  title: string;
  url: string;
  icon: string;
}

interface NavigationStateInput {
  title: string;
  url: string;
  icon: string;
}

const initialState: NavigationState = {
  lastId: 2,
  elements: [
    {
      id: 1,
      title: "Home",
      url: "/",
      icon: "home",
    },
    {
      id: 2,
      title: "About",
      url: "/about",
      icon: "info",
    },
  ],
};

export const navigationSlice = createSlice({
  name: "navigation",
  initialState,
  reducers: {
    addNavigationItem: (state, action: PayloadAction<NavigationStateInput>) => {
      state.lastId++;
      state.elements.push({
        id: state.lastId,
        ...action.payload
      });
    },
    removeNavigationItem: (state, action) => {
      state.elements = state.elements.filter(
        (element) => element.id !== action.payload
      );
    },
  },
});

// Action creators are generated for each case reducer function
export const { addNavigationItem, removeNavigationItem } =
  navigationSlice.actions;

export default navigationSlice.reducer;
