import { useMutation } from "@apollo/client";
import { classNames } from "@scanow/helpers";
import { Button, SubmitButton, ConfirmationModal, Text } from "@scanow/ui";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { RiDeleteBin6Line } from "react-icons/ri";
import { toast } from "react-toastify";
import {
  CREATE_USER_ROLE_ATTRIBUTION,
  DELETE_USER_ROLE_ATTRIBUTION,
  GET_USER_ROLE_ATTRIBUTION,
} from "../Queries";
import { RoleListSelector } from "./RoleListSelector";

interface Props {
  roles: [
    {
      id: number;
      role: {
        id: number;
        name: string;
        slug: string;
      };
    }
  ];
  user_id: string;
  application: "scanow" | "sonar";
}

export function UserRoleAttribution({
  roles,
  user_id,
  application,
}: Props): JSX.Element {
  const { register, handleSubmit, setValue, getValues, watch } = useForm();
  const [deleteRoleAttribution] = useMutation(DELETE_USER_ROLE_ATTRIBUTION, {
    refetchQueries: [GET_USER_ROLE_ATTRIBUTION],
  });

  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [selectedRoleAttributionId, setSelectedRoleAttributionId] = useState<
    undefined | number
  >(undefined);

  const [createRoleAttribution] = useMutation(CREATE_USER_ROLE_ATTRIBUTION, {
    refetchQueries: [GET_USER_ROLE_ATTRIBUTION],
  });

  function createHandler(data: any) {
    createRoleAttribution({
      variables: {
        role_id: data.role_id,
        user_id: user_id,
      },
    })
      .then((data) => {
        toast.success("Rôle ajouté avec succès !");
      })
      .catch((err) => {
        toast.error("Erreur lors de la tentative d'ajout du rôle.");
      });
  }

  function hasAlreadyApplicationRole(): Boolean{
    return roles.some((r) => r.role.slug.includes(application));
  }

  return (
    <div className="flex flex-col gap-3">
      {roles.map((r) => {
        return (
          <div className="flex items-center mb-2">
            <Button
              appendClassName={classNames(
                "w-8 h-8 rounded-full",
                r.role.slug.includes(application) ? "bg-pink-100 hover:bg-pink-200 text-pink-700" : "border border-gray-300 text-gray-300 cursor-not-allowed"
              )}

              onClick={(e) => {
                console.log(r.role.slug.includes(application))
                console.log(r.role.slug)
                if(r.role.slug.includes(application)){
                  e.preventDefault();
                  setSelectedRoleAttributionId(r.id);
                  setDeleteModal(true);
                }else{
                  toast.error("Vous ne pouvez pas supprimer ce rôle.")
                }

              }}
            >
              <RiDeleteBin6Line size={14} />
            </Button>
            <Text.Small
              title={"Rôle" + " " + r.role.name}
              appendClassName="ml-4 bg-secondary-200 text-secondary-800 hover:opacity-80 dark:border-indigo-300 dark:text-indigo-300 rounded-full px-4 py-2 font-semibold"

            >
              {r.role.name}
            </Text.Small>
          </div>
        );
      })}
      <form onSubmit={handleSubmit(createHandler)}>
        {
          hasAlreadyApplicationRole() ? (
            <div className="text-center">
              <Text.Medium>Pour modifier le rôle de l'utilisateur, vous devez d'abord supprimer son rôle actuel. </Text.Medium>
            </div>
          ) : (<>
        <RoleListSelector
          label={"Rôles"}
          register={register("role_id", { required: true })}
          evict={roles.map((r) => r.role.id)}
          application={application}
        />

        <SubmitButton value="Ajouter"/>
        </>
        )}
      </form>

      <ConfirmationModal
        isOpen={deleteModal}
        setState={setDeleteModal}
        title="Confirmer la suppression ?"
        validate_callback={() => {
          if (selectedRoleAttributionId) {
            deleteRoleAttribution({
              variables: { id: selectedRoleAttributionId },
            })
              .then(() => {
                toast.success("Le rôle a été supprimé avec succès !");
              })
              .catch(() => {
                toast.error("Erreur lors de la tentative de suppression du rôle.");
              })
              .finally(() => {
                setDeleteModal(false);
                setSelectedRoleAttributionId(undefined);
              });
          }
        }}
      >
        <Text.Normal appendClassName="text-center">Êtes-vous sûr de vouloir supprimer ce rôle ?</Text.Normal>
      </ConfirmationModal>
    </div>
  );
}