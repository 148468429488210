import { useQuery } from "@apollo/client";
import { userAuthReturn } from "@scanow/helpers";
import { Loading, ErrorMessage } from "@scanow/ui"
import React from 'react'
import { UserList } from "../Data/List";
import { IUserLite } from "../Interfaces";
import { GET_ALL_USERS } from "../Queries";

interface ApolloData {
  users: IUserLite[];
}

interface Props {
  application: "scanow" | "sonar";
  role: number
  auth : userAuthReturn
  readOnly?: boolean
}

export function UserListWrapper(props: Props): JSX.Element {
  const { data, loading, error } = useQuery<ApolloData>(GET_ALL_USERS)
  if (loading) {
    return (<Loading />)
  }
  if (error) {
    console.error(error)
    return (<ErrorMessage message="Une erreur inconnue s'est produite"/>)
  }
  if (data) {
    return <UserList users={data.users} {...props} />;
  }
  return (<ErrorMessage message="Une erreur inconnue est survenue. Code: 9999"/>)
};