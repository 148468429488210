import { Label } from "@scanow/ui";
import React, { useState } from "react"
import { HiOutlineStar, HiStar } from "react-icons/hi2"

type NoteRange = 1 | 2 | 3 | 4 | 5

interface NoteFieldProps{
  value: NoteRange | null
   setValue: (note: NoteRange) => void 
    label: string
}

export function NoteField({ value, setValue, label }: NoteFieldProps ):JSX.Element {
  const [note, setNote] = useState<NoteRange | null>(value)
  const notes = [{
    value: 1,
    unselectedComponent: <HiStar className="cursor-pointer h-10 w-10 text-neutral-300" />,
    selectedComponent: <HiStar className="cursor-pointer h-10 w-10 text-warning-500" />
  },
  {
    value: 2,
    unselectedComponent: <HiStar className="cursor-pointer h-10 w-10 text-neutral-300" />,
    selectedComponent: <HiStar className="cursor-pointer h-10 w-10 text-warning-500" />
  },
  {
    value: 3,
    unselectedComponent: <HiStar className="cursor-pointer h-10 w-10 text-neutral-300" />,
    selectedComponent: <HiStar className="cursor-pointer h-10 w-10 text-warning-500" />
  },
  {
    value: 4,
    unselectedComponent: <HiStar className="cursor-pointer h-10 w-10 text-neutral-300"/>,
    selectedComponent: <HiStar className="cursor-pointer h-10 w-10 text-warning-500"/>
  },
  {
    value: 5,
    unselectedComponent: <HiStar className="cursor-pointer h-10 w-10 text-neutral-300" />,
    selectedComponent: <HiStar className="cursor-pointer h-10 w-10 text-warning-500" />
  }]

  return (
    <div className="my-5">
      <Label>{label}</Label>
      <i className="w-full h-10 flex space-x-4">
        {notes.map(note=>{
          return (<div key={"note_" + note.value} onClick={()=>{setValue(note.value as any)}}>
            {value ? value >= note.value ? note.selectedComponent : note.unselectedComponent : note.unselectedComponent}
            </div>
          )
        })}
      </i>
    </div>
  )
}