import { Text, Card } from "@scanow/ui"
import { useQuery } from "@apollo/client"
import { ICompanyInvetorySettings } from "../Interfaces"
import { GET_COMPANY_INVENTORY_SETTINGS } from "../Queries"
import { BsGear } from "react-icons/bs"
import React from "react"

interface Props {
  id: string
}

interface ApolloData {
  company: ICompanyInvetorySettings
}

export function CompanyInventorySettings({ id }: Props) {
  const { data, loading, error } = useQuery<ApolloData>(GET_COMPANY_INVENTORY_SETTINGS, { variables: { id: id } })


  return (
    <Card>
      <Card.Header>
        <div className="flex justify-between">
          <Text.Subtitle>Paramètres de Scanow Inventory</Text.Subtitle>
          <div className="bg-green-100 w-10 h-10 rounded-full">
            <BsGear className="m-2 w-6 h-6 text-green-500" />
          </div>
        </div>

      </Card.Header>
      <Card.Body>
        {data &&

          <div className="mt-3 ">
            <div className="h-32 flex justify-center items-center">
              <h2 id="commingSoon" className="animate-bounce text-3xl font-bold tracking-wider" >Bientôt...</h2>
            </div>
          </div>

        }
      </Card.Body >
    </Card >
  )
}