import { THEME_TYPO_MEDIUM } from "./theme_typography";

// FORMS THEME
export const THEME_FORM_OPTION = " text-xs";
export const THEME_FORM_GROUP_FIELD = " form-group my-2 ";
export const THEME_FORM_MULTISELECT = " form-multiselect ";
export const THEME_FORM_RADIO =
  " form-radio appearance-none float-left rounded-full h-4 w-4 border border-neutral-300 bg-white checked:bg-primary-500 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer ";
export const THEME_FORM_CHECKBOX =
  " form-checkbox float-left h-4 w-4 mr-2 outline-none focus:outline-none cursor-pointer transition duration-300"; //TODO A revoir le appearance-none rends le bouton complètement invisible
export const THEME_FORM_SUBMIT =
  " form-submit cursor-pointer inline-flex justify-center px-4 py-2 text-sm font-bold bg-white text-neutral-900 border border-transparent rounded-md hover:bg-neutral-100 focus:outline-none";
export const THEME_FORM_LABEL =
    THEME_TYPO_MEDIUM + " my-12 text-secondary-800 dark:text-white";
export const THEME_FORM_INPUT =
    THEME_TYPO_MEDIUM + " py-3 rounded-lg block w-full border-neutral-200 focus:border-secondary-500 focus:ring-transparent bg-white dark:border-neutral-700 dark:focus:border-secondary-500 dark:bg-neutral-900 dark:text-slate-400 disabled:bg-neutral-200 dark:disabled:bg-neutral-800 duration-300 transition ease-in-out";
export const THEME_FORM_SELECT =
  " form-select cursor-pointer appearance-none w-full h-12 block  mb-4 text-base font-normal text-neutral-700 dark:text-neutral-400 bg-white dark:bg-neutral-800 bg-clip-padding bg-no-repeat border border-solid border-neutral-300 dark:border-neutral-700 rounded transition ease-in-out m-0 focus:text-neutral-700 focus:bg-white focus:border-blue-600 focus:outline-none ";
export const THEME_FORM_TEXTAREA =
  " px-4 py-3 mb-3 text-sm border border-neutral w-full rounded-md focus:text-neutral-700 focus:bg-white focus:border-primary-500 focus:outline-none transition ease-in-out";