import React from "react";

interface IInformation {
  icon?: any;
  children: JSX.Element | string;
}

export function Information({ icon, children }: IInformation): JSX.Element {
  return (
    <div className="flex items-center w-full my-4 p-4 bg-secondary-200 text-secondary-800 dark:bg-neutral-800 dark:text-neutral-300 rounded-md">
      <p className="flex items-center text-sm font-medium">
        <span className="text-warning-500 mr-2"> {icon}</span>
        {children}
      </p>
    </div>
  );
}
