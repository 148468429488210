import { useQuery } from "@apollo/client";
import { Loading, ErrorMessage } from "@scanow/ui"
import React from 'react'
import { UserDetail } from "../Data/Detail";
import { IUser } from "../Interfaces";
import { GET_USER_DETAIL } from "../Queries";

interface Props {
  id: string
}

interface ApolloData {
  user: IUser

}

export function UserDetailWrapper({ id }: Props): JSX.Element {
  const { data, loading, error } = useQuery<ApolloData>(GET_USER_DETAIL, { variables: { id: id } })
  if (loading) {
    return (<Loading />)
  }
  if (error) {
    console.error(error)
    return (<ErrorMessage message="Une erreur inconnue s'est produite"/>)
  }
  if (data) {
    return <UserDetail user={data.user} />;
  }
  return (<ErrorMessage message="Une erreur inconnue est survenue. Code: 9999"/>)
};