import React from "react";
import { SelectField } from "@scanow/ui";
import { useQuery } from "@apollo/client";
import { FieldErrorsImpl, UseFormRegisterReturn } from "react-hook-form";
import { ILocationName } from "../Interfaces";
import { GET_ALL_LOCATIONS_NAME } from "../Queries";


interface LocationProps {
  register: UseFormRegisterReturn;
  label?: string;
  placeholder?: string;
  errors?: FieldErrorsImpl<any>;
}

interface Query {
  locations: ILocationName[];
}

export function LocationSelectorField({ label, register, placeholder, errors }: LocationProps): JSX.Element {
  const { data, error, loading } = useQuery<Query>(GET_ALL_LOCATIONS_NAME);

  return (
    <>
      {data && (
        <SelectField
          options={data.locations.map((i) => {
            return { value: i.id, name: i.name };
          })}
          errors={errors}
          label={label || "Localité"}
          placeholder={placeholder || "--- Sélectionnez la localité ---"}
          register={register}
        />
      )}
    </>
  );
}
