import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { InputField, SubmitButton } from '@scanow/ui';
import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { capitalizeIfNeeded } from '@scanow/helpers';
import { ILocationLite } from '../Interfaces';
import { GET_ALL_LOCATIONS, GET_ALL_LOCATIONS_LIST, GET_ALL_LOCATIONS_NAME, GET_UPDATED_LOCATION, UPDATE_LOCATION } from '../Queries';


interface CompanyProps {
  location: ILocationLite;
  cb_success?: any;
  cb_error?: any;
  role: number;
}

export function LocationUpdateForm({ location, cb_error, cb_success, role }: CompanyProps): JSX.Element {
  const router = useRouter();
  const { register, handleSubmit, watch, setValue, getValues, unregister, reset, formState: { errors } } = useForm({
    defaultValues: {
      name: location.name,
      company_id: location.company_id,
    },
  });
  const [submittedData, setSubmittedData] = useState(null);
  const [updateLocation] = useMutation(UPDATE_LOCATION, {
    refetchQueries: [
      GET_ALL_LOCATIONS,
      GET_ALL_LOCATIONS_NAME,
      GET_UPDATED_LOCATION,
      GET_ALL_LOCATIONS_LIST,
    ],
  });

  useEffect(() => {
    reset();
  }, []);


  function submitHandler(data: any) {
    if (role != 1) {
      delete data['company_id'];
    }
    data.name = capitalizeIfNeeded(data.name);
    updateLocation({
      variables: {
        id: location.id,
        _set: data,
      },
    })
      .then((data) => {
        // en cas de succès de la requète
        // Verifier si une fonction callback est dispo
        if (cb_success) {
          cb_success(data.data.location);
        } else {
          //Sinon executer fonction par défaut
          setSubmittedData(data.data.location);
          router.reload();
        }
      })
      .catch((err) => {
        // en cas de d'erreur de la requète
        // Verifier si une fonction callback est dispo
        if (cb_error) {
          cb_error(err);
        } else {
          //Sinon executer fonction par défaut
          console.error(err);
        }
      });

  }

  return (

    <form onSubmit={handleSubmit(submitHandler)}>
      <fieldset>
        <InputField
          label="Nom"
          errors={errors}
          register={register('name', { required: 'Ce champ est requis !' })}/>
        {/* TODO : A n'afficher QUE pour les admins
        {role == 1 &&
            <CompanySelectorField register={register('company_id', { required: false })}/>}
        */}
        <SubmitButton value="Enregistrer"/>
      </fieldset>
    </form>

  );
}