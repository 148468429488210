import React, { useEffect, useMemo } from 'react';
import {
  useTable,
  useGlobalFilter,
  useFilters,
  useSortBy,
  usePagination,
} from "react-table";
import {
  TableHeader,
  TableBody,
  TableFooter,
  TablePagination,
  TablePaginationButton,
} from "./Section";
import { TableSearch } from "./Search";
import {
  RiAddLine,
  RiPrinterLine,
  RiFileExcel2Line,
  RiSkipForwardMiniFill,
  RiSkipBackMiniFill,
  RiSortAsc,
  RiSortDesc,
  RiArrowUpSFill,
  RiArrowDownSFill,
  RiArrowLeftSLine,
  RiArrowLeftSFill,
  RiArrowRightSFill,
  RiRefreshLine,
  RiArrowRightSLine,
} from "react-icons/ri";
import { Button } from "../Button";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { dateFormat, useLocalStorage } from "@scanow/helpers";
import {SelectField, Text} from "@scanow/ui";

import * as XLSX from "xlsx";

function generateXLSX(data: []) {
  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.json_to_sheet(data);
  XLSX.utils.book_append_sheet(wb, ws, "Export");
  XLSX.writeFile(wb, dateFormat().formatItilFullDate() + "-" + "scanow.xlsx");
}

interface TableSettings {
  pageSize: number;
}

interface TableColumn {
  Header: string | JSX.Element;
  accessor?: string | ((row: any) => any);
  Cell?: (props: any) => JSX.Element;
  disableSortBy?: boolean;
  // TODO Je crois que ce n'est pas utilisé
  imgAccessor?: string;
  emailAccessor?: string;

  exportOnly?: boolean;
}

export interface TableProps {
  columns: TableColumn[];
  data: any;
  loading?: any;
  cb_add?: any;
  onRowClick?: (row: any) => void;
  refetch?: () => void;
}
// TODO : Ajouter un mode minimaliste sans la recherche et les boutons
export function Table({
  loading,
  columns,
  data,
  cb_add,
  onRowClick,
  refetch
}: TableProps): JSX.Element {
  const { t } = useTranslation("common");
  const router = useRouter();

  const [tableSettings, setTableSettings] = useLocalStorage<Record<string, TableSettings>>('user_table_settings', {});
  function defaultOnRowClick(row: any) {
    router.push(router.pathname + "/" + row.original.id);
  }

  function route_to_slug(): string {
    const route = router.pathname.split('/');
    const cleanRoute = route.map((r) => r.replace(/[^a-zA-Z0-9]/g, ''));
    const routeFiltered = cleanRoute.filter(Boolean);

    const cleanCols = columns
      .filter((c: any) => c.exportOnly !== true)
      .map((c: any) => c.Header)
      .filter((c: any) => typeof c === 'string')
      .map((c: any) => c.replace(/[^a-zA-Z0-9]/g, ''));

    return `${routeFiltered.join('_')}_${cleanCols.join('_')}`;
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, globalFilter },
    preGlobalFilteredRows,
    setGlobalFilter,
  }: any = useTable(
    {
      columns: columns as any,
      data,

    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  let currentTableSettings = tableSettings[route_to_slug()]
  if (!currentTableSettings) {
    currentTableSettings = { pageSize: 10 }
    tableSettings[route_to_slug()] = currentTableSettings
    setTableSettings(tableSettings)
  }

  useEffect(() => {

    setPageSize(currentTableSettings.pageSize)
  }, [tableSettings])

  return (
    <>
      <TableHeader>
        <div className="grid justify-items-start order-last md:order-first">
          <TableSearch
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
        </div>
        <div
          className="grid justify-items-end"
        >
          <div className="flex items-center space-x-2">
            {cb_add && (
              <Button
                onClick={cb_add}
                appendClassName="w-9 h-9 rounded-full bg-secondary-200 dark:bg-cyan-200 text-secondary-800 hover:opacity-90 hover:text-secondary-600 duration-300 transition ease-in-out"
              >
                <RiAddLine />
              </Button>
            )}

            {/*<Button
            className="w-9 h-9 rounded-full hover:bg-teal-200 bg-teal-100 text-teal-700"
          >
            <RiPrinterLine size={18} className="mx-auto" />
          </Button>*/}

            <Button
            appendClassName="w-9 h-9 rounded-full bg-secondary-200 dark:bg-green-200 text-secondary-800 hover:opacity-90 hover:text-success-500 duration-300 transition ease-in-out"
              onClick={(e) => {
                // Export sur excel
                e.preventDefault();
                const cols = columns.filter(
                  (c: any) => typeof c.Header === "string"
                );

                let gen_rows = rows.map((r: any) => r.values);
                // replace keys by colums Header
                gen_rows = gen_rows.map((r: any) => {
                  let new_row = {};
                  for (const [key, value] of Object.entries(r)) {
                    // If key is action, skip it
                    if (key === "action") {
                      continue;
                    }
                    const col = cols.find((c) => c.accessor == key)
                    //@ts-ignore
                    new_row[
                      //@ts-ignore
                      col?.Header || key
                    ] = value;
                  }
                  return new_row;
                });
                generateXLSX(gen_rows);
              }}
            >
              <RiFileExcel2Line size={14} className="mx-auto" />
            </Button>
            {
              refetch && (
                <Button
                  appendClassName="w-9 h-9 rounded-full bg-secondary-200 dark:bg-cyan-200 text-secondary-800 hover:opacity-90 hover:text-secondary-600 duration-300 transition ease-in-out"
                  onClick={()=>{refetch()}}
                >
                  <RiRefreshLine size={14} className="mx-auto" />
                </Button>
              )
            }
            {/* TODO to see and test
          {headerGroups.map((headerGroup: any) =>
            headerGroup.headers.map((column: any) =>
              column.Filter ? (
                <div key={column.id}>{column.render("Filter")}</div>
              ) : null
            )
          )}  */}
          </div>
        </div>
      </TableHeader>

      <TableBody>
        <table
          {...getTableProps()}
          className="flex flex-wrap text-left items-center max-w-lg table responsive-table min-w-full overscroll-contain"
        >
          <thead>
            {headerGroups.map((headerGroup: any) => {
              return (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: any) => {
                    if(column.exportOnly) {
                      return null
                    }
                    return (
                      <th
                       scope="col"
                          title={"Trier par"}
                        {...column.getHeaderProps(column.getSortByToggleProps())}
                      >
                        <Text.Medium appendClassName="text-secondary-800 dark:text-white">
                          {column.render('Header')}
                          <span className="">
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <RiArrowDownSFill size={10}/>
                              ) : (
                                <RiArrowUpSFill size={10}/>
                              )
                            ) : column.disableSortBy === true ? (
                              <></>
                            ) : (
                              <RiSortDesc size={10}/>
                            )}
                          </span>
                        </Text.Medium>
                      </th>
                    );
                  })}
                </tr>
              );
            })}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row: any, i: any) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  className="md:border-b border-dotted border-neurtal-200 hover:bg-secondary-200 dark:text-slate-100 dark:border-neutral-700 dark:hover:bg-neutral-800 rounded-full transition duration-500 ease-in-out"
                >
                  {row.cells.map((cell: any) => {
                    if (cell.column.exportOnly) {
                      return null;
                    }
                    return (
                      <td
                        onClick={(e) => {
                          if (cell.value) {
                            e.preventDefault();
                            if (onRowClick) {
                              onRowClick(row);
                            } else {
                              defaultOnRowClick(row);
                            }
                          }
                        }}
                        {...cell.getCellProps()}
                        className="p-2 whitespace-nowrap"
                        role="cell"
                        data-th={
                          typeof cell.column.render("Header") != "string"
                            ? cell.column.Header.props.alt
                            : cell.column.render("Header")
                        }
                      >
                        {cell.column.Cell.name === "defaultRenderer" ? (
                          <Text.Medium >{cell.render("Cell")}</Text.Medium>
                        ) : (
                          cell.render("Cell")
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </TableBody>

      <TableFooter>
        <TablePagination>
          <div className="hidden md:block">
            {loading ? (
              <Text.Small>Patientez...</Text.Small>
            ) : page.length > 0 ? (
              <Text.Small>
                {page.length}{" "}
                {/* TODO afficher le nb total d'entrée of ~ {controlledPageCount * pageSize} */}{" "}
                résultat
                {page.length > 1 && "s"}
              </Text.Small>
            ) : (
              <Text.Small>
                Aucun résultat
              </Text.Small>
            )}
          </div>
          <div className="flex items-center">
              <Text.Small appendClassName="hidden md:inline pr-2">Page{" "}
              <strong>
                {pageIndex + 1} sur {pageOptions.length}
              </strong>
              </Text.Small>|

            <Text.Small appendClassName="hidden md:inline pl-2">
              Se rendre à la page :{" "}
              <input
                  type="number"
                  className={
                    "w-10 h-10 rounded-md px-1 border border-neutral-200 focus:border-info-100 focus:ring focus:ring-info-100 focus:ring-opacity-25 dark:focus:ring-info-100 bg-white dark:border-neutral-700 dark:bg-neutral-900 dark:disabled:bg-neutral-800 transition ease-in-out"
                  }
                  defaultValue={pageIndex + 1}
                  onChange={(e) => {
                    const page = e.target.value ? Number(e.target.value) - 1 : 0;
                    gotoPage(page);
                  }}
              />
              </Text.Small>
          </div>
          <div className="flex items-center">
            <div className="flex items-center space-x-2 pr-4">
            <TablePaginationButton
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            >
              <RiSkipBackMiniFill size={15} />
            </TablePaginationButton>
            <TablePaginationButton
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            >
              <RiArrowLeftSLine size={15} />
            </TablePaginationButton>
            <TablePaginationButton
              onClick={() => nextPage()}
              disabled={!canNextPage}
            >
              <RiArrowRightSLine size={15} />
            </TablePaginationButton>
            <TablePaginationButton
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              <RiSkipForwardMiniFill size={15} />
            </TablePaginationButton>
            </div>
            <SelectField
                value={pageSize}
                onChange={(e) => {
                  const size = Number(e.target.value);
                  currentTableSettings = {
                    ...currentTableSettings,
                    pageSize: size,
                  };

                  setTableSettings({
                    ...tableSettings,
                    [route_to_slug()]: currentTableSettings,
                  });
                }}
                options={[
                  { value: 20, name: "Afficher 20 lignes" },
                  { value: 50, name: "Afficher 50 lignes" },
                  { value: 100, name: "Afficher 100 lignes" },
                  { value: 300, name: "Afficher 300 lignes" },
                ]}
            />
            {/*<!-- <select
              className={
                "rounded-md ml-4 px-6 border border-neutral-200 focus:border-info-100 focus:ring focus:ring-info-100 focus:ring-opacity-25 dark:focus:ring-info-100 bg-white dark:border-neutral-700 dark:bg-neutral-900 dark:disabled:bg-neutral-800 transition ease-in-out"
              }
              value={pageSize}
              onChange={(e) => {
                const size = Number(e.target.value)
                currentTableSettings = {
                  ...currentTableSettings,
                  pageSize: size,
                };

                setTableSettings({
                  ...tableSettings,
                  [route_to_slug()]: currentTableSettings,
                });

              }}
            >
              {[10, 20, 50, 100].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Afficher {pageSize} lignes
                </option>
              ))}
            </select> -->*/}
          </div>
        </TablePagination>
      </TableFooter>
    </>
  );
}