import React, { FormEventHandler, MouseEventHandler } from "react";
import { THEME_FORM_INPUT } from "@scanow/theme";
import { Label } from "@scanow/ui";
import {
  FieldErrors,
  FieldErrorsImpl,
  UseFormRegisterReturn,
} from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

export interface TextAreaFieldProps {
  type?: string;
  label?: string;
  onClick?: React.MouseEventHandler<HTMLTextAreaElement>;
  onChange?: React.FormEventHandler<HTMLTextAreaElement>;
  className?: string;
  disabled?: boolean;
  autoFocus?: boolean;
  placeholder?: any;
  register?: UseFormRegisterReturn;
  appendClassName?: string;
  errors?: FieldErrorsImpl<any>;
  setState?: Function;
  state?: any;
  name?: string;
}

export function TextAreaField({
  label,
  onClick,
  onChange,
  placeholder,
  className,
  disabled,
  autoFocus,
  register,
  errors,
  appendClassName,
  setState,
  state,
  name,
}: TextAreaFieldProps) {
  function defaultOnChange(e: any) {
    if (setState && state) {
      setState({
        ...state,
        [name || ""]: e.target.value,
      });
    }
  }

  return (
    <fieldset className="pt-8">
      <Label htmlFor={register?.name}>{label}</Label>
      {register ? (
        <>
          <textarea //cols="50" rows="10"
            id={register?.name || ""}
            onClick={onClick}
            className={
              className ||
              " rounded-lg " + THEME_FORM_INPUT + " " + appendClassName
            }
            placeholder={placeholder}
            disabled={disabled}
            autoFocus={autoFocus}
            {...register}
          />
          {errors && (
            <ErrorMessage
              errors={errors}
              name={register?.name}
              render={({ message }: any) => (
                <small className="absolute text-xs py-1 text-danger-500 w-full h-12 block ">
                  {message}
                </small>
              )}
            />
          )}
        </>
      ) : (
        <textarea
          id={name || ""}
          onChange={onChange || defaultOnChange}
          className={
            className ||
            " rounded-lg " + THEME_FORM_INPUT + " " + appendClassName
          }
          placeholder={placeholder}
          value={state[name || ""]}
          disabled={disabled}
          autoFocus={autoFocus}
        />
      )}
    </fieldset>
  );
}
