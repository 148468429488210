import React, { createContext, useState, useContext, useEffect } from 'react'
import Router, { useRouter } from 'next/router'
import { useCookies } from 'react-cookie'
import userAuth, { userAuthReturn } from '../auth'
import { useLazyQuery } from '@apollo/client'
import { ICompanyAuthSettings, GET_COMPANY_AUTH_SETTINGS } from '@scanow/services'
import { captureException } from '@sentry/nextjs'


interface IAuthContext {
  auth: userAuthReturn
  company: ICompanyAuthSettings | null
}

//@ts-ignore
const AuthContext = createContext<IAuthContext>(null)

interface Props {
  children: React.ReactNode 
}

interface ApolloData {
  company: ICompanyAuthSettings
}

export const AuthProvider = ({ children }: Props): JSX.Element => {
  const [accessCookie, setAccessCookie, removeAccessCookie] = useCookies([
    "accessToken",
  ]);

  const [auth, setAuth] = useState<userAuthReturn>(userAuth(null))
  const [company, setCompany] = useState<ICompanyAuthSettings | null>(null)
  const [getCompany] = useLazyQuery<ApolloData>(GET_COMPANY_AUTH_SETTINGS)

  useEffect(() => {
    if (accessCookie.accessToken) {
      setAuth(userAuth(accessCookie))
      if (!auth.user) {
        const err = new Error("User not found in auth context")
        err.name = "AuthContext-UserNotFound"
        captureException(err)
        return
      }
      fetchCompanyData()
    }
  }, [])

  function fetchCompanyData(): void {
    if (!auth.user) {
      const err = new Error("User not found in auth context")
      err.name = "AuthContext-UserNotFound"
      captureException(err)
      return
    }
    getCompany({ variables: { id: auth.user.company_id } }).then(data => {
      if (!auth.user) {
        const err = new Error("company not found in auth context")
        err.name = "AuthContext-CompanyNotReturned"
        captureException(err)
        return
      }
      setCompany(data.data?.company || null)
    }).catch(err => {
      captureException(err)
    })
  }

  return (<AuthContext.Provider value={{ auth, company }} >
    {children}
  </AuthContext.Provider>)
}


export const useAuth = () => useContext(AuthContext)