import React, { Children, useEffect, useRef } from "react";
import "./carousel.scss";
export interface CarouselProps {
  children: JSX.Element | JSX.Element[];
  duration: number;
}

export function Carousel({ children, duration }: CarouselProps): JSX.Element {

  const carouselRef = useRef<HTMLUListElement>(null);
  const carouselView = useRef<HTMLDivElement>(null);
  const childrens = Children.toArray(children);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!childrens) {
        return;
      }
      if (!carouselView.current) {
        return;
      }
      const carouselItems = document.getElementsByClassName("carousel__item");
      const childrenWidth = carouselItems[0].clientWidth;
      const scrollLeft = carouselView.current.scrollLeft;
      const newScrollValue = childrenWidth + scrollLeft;
      const maxScroll = carouselItems.length * childrenWidth;
      if (maxScroll <= newScrollValue) {
        carouselView.current.scroll(0, 0);
      } else {
        carouselView.current.scroll(newScrollValue, 0);
      }
    }, duration * 1000);
    return () => clearInterval(interval);
  }, [childrens]);

  useEffect(() => {
    if (!carouselRef.current) {
      return;
    }
    carouselRef.current.style.width = (childrens.length * 100).toString() + "%";
  }, [childrens]);

  return (
    <div
      className="w-full h-full overflow-hidden carousel__wrapper"
      ref={carouselView}
    >
      <ul className="carousel" ref={carouselRef}>
        {childrens.map((child, index) => {
          return (
            <li key={index} className="carousel__item">
              {child}
            </li>
          );
        })}
      </ul>
    </div>
  );
}

