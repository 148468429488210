import React, { FormEventHandler, useState } from "react";
import { Text, Table, ActionCell, AvatarCell, Modal } from "@scanow/ui";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { useTranslation } from "next-i18next";
import { RiSettings3Line } from "react-icons/ri";
import { apolloErrorParser, userAuthReturn } from "@scanow/helpers";
import { UserCreateForm } from "../Form/Create";
import { UserPasswordResetForm } from "../Form/PasswordReset";
import { IUserLite } from "../Interfaces";
import { DISABLE_USER, GET_ALL_USERS, GET_ALL_USERS_NAME } from "../Queries";
import { UserDetailWrapper } from "../Wrapper/Detail";
import { UserUpdateWrapper } from "../Wrapper/Update";
import { useRouter } from "next/router";

interface UserProps {
  users: IUserLite[];
  application: "scanow" | "sonar";
  role: number
  auth: userAuthReturn
  readOnly?: boolean
}

export function UserList({ users, application, role, auth, readOnly }: UserProps): JSX.Element {

  const [disableUser] = useMutation(DISABLE_USER, {
    refetchQueries: [GET_ALL_USERS, GET_ALL_USERS_NAME],
  });
  const router = useRouter()


  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [formName, setFormName] = useState<
    "create" | "update" | "detail" | "reset_password" | undefined
  >(undefined);
  const [selectedUser, setSelectedUser] = useState<IUserLite | undefined>(
    undefined
  );

  function cbSuccess(data: any) {
    toast.success("Utilisateur crée avec succès !" ); //+ formName));
    setModalOpen(false);
    setFormName(undefined);
    setSelectedUser(undefined);
  }
  function cbError(err: any) {
    toast.error("Erreur lors de la tentative de création de l'utilisateur ");// + formName));

  }
  function modalTitleGenerator(): string {
    switch (formName) {
      case "create":
        return "Création";
      case "update":
       return "Modification";
       // return `Modifier le profil de ${ selectedUser?.firstname ?? "l'utilisateur"}`;
      case "detail":
        //return `Profil de ${ selectedUser?.firstname ?? "l'utilisateur"}`;
        return "Affichage";
      case "reset_password":
        return "Changer le mot de passe";
      default:
        return "";
    }
  }



  const columns = React.useMemo(
    () => [
      {
        Header: "Avatar",
        //accessor: "avatar_url",
        Cell: AvatarCell,
        imgAccessor: "avatar_url",
      },
      {
        Header: "Prénom",
        accessor: "firstname",
      },
      {
        Header: "Nom",
        accessor: "lastname",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Rôle",
        accessor: (row: IUserLite) => {

          return row.user_role.find((r) => r.role.slug.includes(application))?.role.name;
        },
      },
      {
        //@ts-ignore
        Header: <RiSettings3Line size={22} alt="Action" />,
        accessor: "action",
        disableSortBy: true,
        Cell: (props: any) => {
          function showHandler(event: FormEventHandler) {
            setSelectedUser(props.cell.row.original);
            setModalOpen(true);
            setFormName("detail");
          }

          function updateHandler(event: FormEventHandler) {
            setSelectedUser(props.cell.row.original);
            setModalOpen(true);
            setFormName("update");
          }

          function passwordResetHandler(event: FormEventHandler) {

            setSelectedUser(props.cell.row.original);
            setModalOpen(true);
            setFormName("reset_password");
          }

          function deleteHandler(event: FormEventHandler) {
            disableUser({ variables: { id: props.cell.row.original.id } })
              .then((data: any) => {
                toast.success("Utilisateur supprimé avec succès !");
              })
              .catch((err) => {
                let parsed_error = apolloErrorParser(err);
                toast.error("Erreur lors de la tentative de suppression de l'utilisateur " + "(" + parsed_error.type + ")");
              });
          }

          return ActionCell({
            showHandler: showHandler,
            updateHandler:readOnly ? undefined : updateHandler,
            passwordResetHandler: passwordResetHandler,
            deleteHandler:readOnly ? undefined : deleteHandler,
            ...props,
          });
        },
      },
    ],
    []
  );

  return (
    <div>
      <Table
        columns={columns}
        data={users}
        cb_add={readOnly ? undefined :() => {
          setFormName("create");
          setModalOpen(true);
        }}
        onRowClick={(row: any) => {
          router.push(`/admin/user/${row.original.id}`);
        }}
      />

      {/* TODO Simplification des formulaires en modals et formulaire reset mot de passe */}

      <Modal
        isOpen={modalOpen}
        setState={setModalOpen}
        title={modalTitleGenerator()}
      >
        {formName == "detail" && selectedUser && (
          <UserDetailWrapper id={selectedUser.id} />
        )}
        {formName == "update" && selectedUser && (
          <UserUpdateWrapper
            auth={auth}
            id={selectedUser.id}
            cb_success={cbSuccess}
            cb_error={cbError}
            role={role}
            application={application}
          />
        )}
        {formName == "create" && (
          <UserCreateForm cb_success={cbSuccess} cb_error={cbError} auth={auth} />
        )}
        {formName == "reset_password" && selectedUser && (
          <UserPasswordResetForm
            id={selectedUser.id}
            cb_success={cbSuccess}
            cb_error={cbError}
          />
        )}
      </Modal>
    </div>
  );
}