import { useRouter } from "next/router";
import { ILocation } from "../Interfaces";
import { Button, Text } from "@scanow/ui";
import { RiMapPin2Line } from "react-icons/ri";
import React from "react";
interface Props {
  location: ILocation;
}

export function LocationDetail({ location }: Props): JSX.Element {
  const router = useRouter();
  return (
      <div className="flex h-full flex-col justify-center items-center gap-2">
        <RiMapPin2Line className="bg-slate-200 text-slate-700 h-16 w-16 p-2 rounded-full" />
        <Text.Title>Localité {location.name}</Text.Title>
          {/* <Text.Subtitle>{location.company.name}</Text.Subtitle>
       <Text.Normal>
          {location.resources_aggregate.aggregate.count} Ressource
          {location.resources_aggregate.aggregate.count > 1 && "s"} référencée
          {location.resources_aggregate.aggregate.count > 1 && "s"}
        </Text.Normal> */}
      </div>
  );
}