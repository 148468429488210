import React from "react";
import { ErrorMessage } from "@hookform/error-message";
import { THEME_FORM_INPUT } from "@scanow/theme";
import { Label } from "@scanow/ui";
import { FieldErrorsImpl, UseFormRegisterReturn } from "react-hook-form";

interface Options {
  value: string | number;
  name: string;
}

interface SelectFieldProps {
  label?: string;
  value?: any;
  id?: string;
  type?: string;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLSelectElement>;
  multiple?: boolean;
  required?: boolean;
  disabled?: boolean;
  hidden?: boolean;
  onChange?: React.ChangeEventHandler<HTMLSelectElement>;
  options: Options[];
  placeholder?: string;
  errors?: FieldErrorsImpl<any>;
  register?: UseFormRegisterReturn;
  appendClassName?: string;
}

export function SelectField({
  label,
  multiple,
  options,
  placeholder,
  errors,
  required,
  className,
  disabled,
  hidden,
  appendClassName,
  register,
  value,
  onChange,
}: SelectFieldProps) {
  return (
    <fieldset className="py-4">
      <Label htmlFor={register?.name}>{label}</Label>
      {register ? (
        <select
          id={register?.name}
          className={className || THEME_FORM_INPUT + " " + appendClassName}
          disabled={disabled}
          hidden={hidden}
          {...register}
        >
          {placeholder && <option value={""}>{placeholder}</option>}
          {options.map((option) => (
            <option
              key={register.name + "_" + option.value}
              value={option.value}
            >
              {option.name}
            </option>
          ))}
        </select>
      ) : (
        <select
          className={className || THEME_FORM_INPUT + " " + appendClassName}
          disabled={disabled}
          hidden={hidden}
          value={value}
          onChange={onChange}
        >
          {placeholder && <option value={"null"}>{placeholder}</option>}
          {options.map((option) => (
            <option key={"" + "_" + option.value} value={option.value}>
              {option.name}
            </option>
          ))}
        </select>
      )}

      {register && errors && (
        <ErrorMessage
          errors={errors}
          name={register.name}
          render={({ message }) => (
            <small className="absolute text-xs py-1 text-danger-500 w-full h-12 block ">
              {message}
            </small>
          )}
        />
      )}
    </fieldset>
  );
}
