import { useQuery } from "@apollo/client";
import { GET_USER_ROLE_ATTRIBUTION } from "../Queries"
import { Loading, ErrorMessage } from "@scanow/ui"
import { UserRoleAttribution } from "../Form/UserRoleAttribution"
import React from 'react'

interface UserProps {
  id: string
  application: "scanow" | "sonar"
}

interface ApolloData {
  roles: [{
    id: number
    role: {
      id: number
      name: string
      slug: string
    }
  }]

}

export function UserRoleAttributionWrapper({ id, application }: UserProps): JSX.Element {
  const { data, loading, error } = useQuery<ApolloData>(GET_USER_ROLE_ATTRIBUTION, { variables: { id: id } })
  if (loading) {
    return (<Loading />)
  }
  if (error) {
    console.error(error)
    return (<ErrorMessage message="Une erreur inconnue s'est produite"/>)
  }
  if (data) {

    return <UserRoleAttribution roles={data.roles} user_id={id} application={application} />;
  }
  return (<ErrorMessage message="Une erreur inconnue est survenue. Code: 9999"/>)
}