import React, { useEffect } from 'react';
import { Text, Button, Label } from '@scanow/ui';
import Image from 'next/image';
import {
  SetFieldValue,
  SetValueConfig,
  FieldErrors,
  UseFormRegisterReturn,
  UseFormSetValue,
  FieldErrorsImpl,
  UseFormSetError,
} from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import {RiDeleteBin6Line} from "react-icons/ri";

export interface ImageFieldProps {
  label: string;
  disabled?: boolean;
  register: UseFormRegisterReturn;
  errors?: FieldErrorsImpl<any>;
  setError?: UseFormSetError<any>;
  // Is url of file
  value: string | null | undefined;
  onFileChange: (file: File, fieldKey: string) => void;
  onReset: () => void;
}

export function ImageField({
                             label,
                             disabled,
                             errors,
                             register,
                             onFileChange,
                             value,
                             setError,
                             onReset,
                           }: ImageFieldProps) {

  const ref = React.useRef<HTMLDivElement>(null);

  function onDrop(event: React.DragEvent<HTMLDivElement>) {
    event.preventDefault();
    event.stopPropagation();
    console.log('drop', event);
    if (event.dataTransfer.files) {
      onFileChange(event.dataTransfer.files[0], register.name);
    }
  }

  useEffect(() => {
    ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
      if (ref.current) {
        ref.current.addEventListener(eventName, preventDefaults, false);
      }
    });
    if (ref.current) {
      ref.current.addEventListener('drop', dropHandle, false);
    }
    return () => {
      ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
        if (ref.current) {
          ref.current.removeEventListener(eventName, preventDefaults, false);
        }
      });
      if (ref.current) {
        ref.current.removeEventListener('drop', dropHandle, false);
      }
    };
  });

  function preventDefaults(e: any) {
    e.preventDefault();
    e.stopPropagation();
  }

  function dropHandle(e: any) {
    console.log('dropHandle', e);
    const dt = e.dataTransfer;
    const files = dt.files;
    uploadFileHandler(files[0]);
  }

  function uploadFileHandler(file: File) {
    console.log('FileUploadRec');
    if (file) {
      if (!file.type.includes('image')) {
        if (setError) {
          setError(register?.name, {
            type: 'custom',
            message: 'Le fichier doit être une image',
          });
          return;
        }
      }

      // check size max 5Mo
      if (file.size > 5 * 1024 * 1024) {
        if (setError) {
          setError(register?.name, {
            type: 'custom',
            message: 'La taille de l\'image ne doit pas dépasser 5Mo',
          });
          return;
        }
      }

      onFileChange(file, register.name);
    }
  }

  return (

    <div className={'mb-2'}>
      <Label>{label}</Label>


      {!value ? (
        <div className="mt-2 flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6"
             onDrop={onDrop} ref={ref}>
          <div className="space-y-1 text-center">
            <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48"
                 aria-hidden="true">
              <path
                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <div className="flex text-sm text-gray-600">
              <label htmlFor="file-upload"
                     className="relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500">
                <span>Téléverser un fichier</span>
                <input id="file-upload" name={register.name} type="file" className="sr-only" accept={'image/*'}
                       onChange={(e) => {
                         if (e.target.files) {
                           uploadFileHandler(e.target.files[0]);
                         }
                       }}/>

              </label>
              <Text.Normal appendClassName="pl-1">ou drag & drop</Text.Normal>
            </div>
            <Text.Small>PNG, JPG, GIF jusqu'à 5MB</Text.Small>
          </div>
        </div>
      ) : (
        <div className={'grid mb-4 mx-auto'}>
          <div className={'relative h-32 '}>
            <Image src={value} objectFit={'contain'} layout={'fill'}/>
          </div>
          <div className="flex items-center mx-auto mb-2">
          <Button type={'button'} appendClassName="w-8 h-8 bg-pink-100 hover:bg-pink-200 text-pink-700 rounded-full" onClick={() => {
            onReset();
          }}><RiDeleteBin6Line size={14} /></Button>
          </div>
        </div>

      )}

      {errors && (
        <ErrorMessage
          errors={errors}
          name={register.name}
          render={({ message }) => (
            <small className=" text-xs py-1 text-danger-500 w-full h-6 block ">
              {message}
            </small>
          )}
        />

      )}

    </div>


  );
}