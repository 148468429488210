import { useQuery } from "@apollo/client";
import { Loading, ErrorMessage } from "@scanow/ui"
import React from "react";
import { TeamList } from "../Data";
import { ITeamLite } from "../Interfaces";
import { GET_ALL_TEAMS_NAME } from "../Queries";

interface TeamData {
  teams: ITeamLite[]
}

interface Props {
  readOnly?: boolean;
}

export function TeamListWrapper({...props}: Props): JSX.Element {
  const { data, loading, error } = useQuery<TeamData>(GET_ALL_TEAMS_NAME)
  if (loading) {
    return (<Loading />)
  }
  if (error) {
    console.error(error)
    return (<ErrorMessage message="Une erreur inconnue s'est produite"/>)
  }
  if (data) {
    return <TeamList teams={data.teams} {...props} />
  }
  return (<ErrorMessage message="Une erreur inconnue est survenue. Code: 9999"/>)
};