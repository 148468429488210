import React from "react";

export interface LoaderProps {
  title?: string;
}

export function Skeleton({ title }: LoaderProps) {
  return (
      <div
          className="flex animate-pulse flex-col space-y-4 border border-slate-150 dark:border-navy-500"
      >
          <div className="px-4 pt-4">
              <div className="h-8 w-10/12 rounded-full bg-slate-150 dark:bg-navy-500"></div>
          </div>
          <div className="h-48 w-full bg-slate-150 dark:bg-navy-500"></div>
          <div className="flex flex-1 flex-col justify-between space-y-4 p-4">
              <div className="h-4 w-9/12 rounded bg-slate-150 dark:bg-navy-500"></div>
              <div className="h-4 w-6/12 rounded bg-slate-150 dark:bg-navy-500"></div>
              <div className="h-4 w-full rounded bg-slate-150 dark:bg-navy-500"></div>
          </div>
      </div>
  );
}