import { useQuery } from "@apollo/client";
import { Loading, ErrorMessage } from "@scanow/ui"
import dynamic from "next/dynamic";
import React from "react"
import { CompanyUpdateForm } from "../Form/Update";
import { GET_UPDATED_COMPANY } from "../Queries";
import { userAuthReturn } from '@scanow/helpers';



interface Props {
  id: string
  cb_success?: any
  role: number
  cb_error?: any
  auth: userAuthReturn;
}

interface ICompany {
  id: string
  name: string
  slug: string
  logo_url?: string
  main_color?: string
  defaut_team_id?: string
  incident_phone_enabled: boolean
  incident_mail_enabled: boolean
  incident_phone_required: boolean
  incident_mail_required: boolean
  licence_end_date?: any // Datetime, trouver un bon type
  licence_count: number
  licence_option_documentation: boolean
  licence_option_control: boolean
  setup: boolean // Si true lance le setup a la prochaine connexion
}

interface CompanyData {
  company: ICompany
}




export function CompanyUpdateWrapper({ id, ...props }: Props): JSX.Element {

  const { data, loading, error } = useQuery<CompanyData>(GET_UPDATED_COMPANY, { variables: { id: id } });
  if (loading) {
    return (<Loading />);
  }
  if (error) {
    console.error(error);
    return (<ErrorMessage message="Une erreur inconnue s'est produite"/>)
  }
  if (data) {

    return <CompanyUpdateForm company={data.company} {...props} />;
  }

  return (<ErrorMessage message="Une erreur inconnue est survenue. Code: 9999"/>)
}