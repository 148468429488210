import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import {
  Button,
    Text,
  SubmitButton,
  CheckboxField,
  ColorPickerField,
  ImageField,
  InputField,
} from "@scanow/ui";
import slugify from "slugify";
import moment from "moment-timezone";
import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import { useCookies } from "react-cookie";
import { UPLOAD_FILE } from "../../fileManager";
import {
  UPDATE_COMPANY,
  GET_ALL_COMPANIES,
  GET_ALL_COMPANIES_NAME,
  GET_UPDATED_COMPANY,
  GET_COMPANY_SETTINGS,
  GET_COMPANY_INVENTORY_SETTINGS,
  GET_COMPANY_HELPDESK_SETTINGS,
} from "../Queries";
import { TeamSelectorField } from "../../team/Form/TeamSelector";
import { HiOutlineArrowPath } from "react-icons/hi2";
import { fileManagerUploadFile, userAuthReturn } from '@scanow/helpers';
import { toast } from 'react-toastify';

interface ICompany {
  id: string;
  name: string;
  slug: string;
  logo_url?: string;
  main_color?: string;
  defaut_team_id?: string;
  incident_phone_enabled: boolean;
  incident_mail_enabled: boolean;
  incident_phone_required: boolean;
  incident_mail_required: boolean;
  licence_end_date?: any; // Datetime, trouver un bon type
  licence_count: number;
  licence_option_documentation: boolean;
  licence_option_control: boolean;
  setup: boolean; // Si true lance le setup a la prochaine connexion

}

interface CompanyProps {
  company: ICompany;
  cb_success?: any;
  cb_error?: any;
  role: number;
  auth: userAuthReturn;
}

export function CompanyUpdateForm({
  company,
  cb_error,
  cb_success,
  auth,
  role,
}: CompanyProps): JSX.Element {

  const router = useRouter();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    unregister,
    reset,
    setError,
    formState: { errors },
  } = useForm<ICompany>({
    defaultValues: {
      ...company,
      licence_end_date: String(company.licence_end_date).replace("+00:00", ""),
    },
  });
  const image_url_watcher = watch("logo_url");
  const color_watcher = watch("main_color");
  const [cookies, setCookie, removeCookie] = useCookies(["theme_color"]);

  const [submittedData, setSubmittedData] = useState(null);
  const [updateCompany] = useMutation(UPDATE_COMPANY, {
    refetchQueries: [
      GET_ALL_COMPANIES,
      GET_ALL_COMPANIES_NAME,
      GET_UPDATED_COMPANY,
      // GET_SETTINGS_DATA,
      GET_COMPANY_SETTINGS,
      GET_COMPANY_INVENTORY_SETTINGS,
      GET_COMPANY_HELPDESK_SETTINGS,
    ],
  });

  useEffect(() => {
    reset();
  }, []);

  function generateSlug() {
    setValue(
      "slug",
      slugify(getValues("name").substring(0, 3)).toLowerCase() +
        moment().format("DDMM") +
        Math.floor(Math.random() * 10) +
        Math.floor(Math.random() * 10)
    );
  }

  function submitHandler(data: any) {
    delete data.id;
    if (role != 1) {
      delete data.licence_end_date;
      delete data.licence_count;
      delete data.licence_option_control;
      delete data.licence_option_documentation;
    }
    updateCompany({
      variables: {
        id: company.id,
        _set: data,
      },
    })
      .then((_data) => {
        localStorage.setItem("main_color", data.main_color);
        // en cas de succès de la requète
        // Verifier si une fonction callback est dispo
        if (cb_success) {
          cb_success(_data.data.company);
          router.reload();
        } else {
          //Sinon executer fonction par défaut
          setSubmittedData(_data.data.company);
          router.reload();
        }
      })
      .catch((err) => {
        console.error(err);
        // en cas de d'erreur de la requète
        // Verifier si une fonction callback est dispo
        if (cb_error) {
          cb_error(err);
        } else {
          //Sinon executer fonction par défaut
          console.error(err);
        }
      });
  }

  async function fileUpload(file: File): Promise<void> {
    const uploadedFile = await fileManagerUploadFile(file, auth!.accessToken as string);
    setValue('logo_url', uploadedFile.full_url);
  }

  function onFileChange(file: File) {
    toast.promise(fileUpload(file), {
        pending: 'Téléchargement du fichier',
        success: 'Fichier téléchargé avec succès',
        error: 'Erreur lors du téléchargement du fichier',
      },
    );
  }

  return (
    <form onSubmit={handleSubmit(submitHandler)}>
      <InputField
        label="Nom"
        register={register("name", {
          required: "Ce champ est requis !",
          minLength: 3,
          maxLength: 32,
        })}
      />

      {errors.name && errors.name.type === "minLength" && (
        <Text.Small appendClassName="py-2 text-danger-500 block">
          Le nom doit contenir au moins 3 caractères !
        </Text.Small>
      )}

      {/* TODO Administrateurs   
        <div>
          <span className={THEME_FORM_LABEL}>{t("form.slug")}</span>
          <div className="relative flex items-center w-full h-12 mb-2 bg-transparent ">
            <div className="grid place-items-center w-12 h-12 text-neutral-300">
              <Button
                onClick={generateSlug}
                //icon={<IoReloadCircleSharp className="h-6 w-6" />} TODO
                title="Générer automatiquement"
                className={
                  "bg-secondary-500 w-full h-full" +
                  " rounded-full "
                }
              >
                <HiOutlineArrowPath />
              </Button>
            </div>

            <InputField
              register={register("slug", { required: true })}
              className={" rounded-r-lg " + THEME_FORM_INPUT}
            />
          </div>
        </div>*/}
      <div className="grid gap-12 grid-cols-1 lg:grid-cols-2 p-2">
        <ImageField
          register={register('logo_url', { required: false })}
          onFileChange={onFileChange}
          setError={setError}
          errors={errors}
          value={image_url_watcher}
          label={"Logo de l'entreprise"}
          onReset={() => {
            //@ts-ignore
            setValue('logo_url', null);
          }}
        />

        <ColorPickerField
          value={color_watcher}
          setValue={setValue}
          register={register("main_color", { required: false })}
          label="Couleur de l'entreprise"
        />
      </div>

      <TeamSelectorField register={register("defaut_team_id")} />
      {/*<CheckboxField
          label="Demander le téléphone de l'utilisateur à l'ouverture d'incident"
          register={register("incident_phone_enabled")}
        
        />*/}

      <CheckboxField
        label="Demander l'email de l'utilisateur à l'ouverture d'incident"
        register={register("incident_mail_enabled")}
      />

      {/*<CheckboxField
          label="Téléphone obligatoire lors de l'ouverture d'un incident"
          register={register("incident_phone_required")}
        />*/}

      <CheckboxField
        label="Email obligatoire lors de l'ouverture d'un incident"
        register={register("incident_mail_required")}
      />

      {role === 1 && (
        <>
          <InputField
            type="datetime-local"
            label="Date fin de licence"
            register={register("licence_end_date", { required: true })}
          />
          <InputField
            type="number"
            label="Nombre de ressources"
            register={register("licence_count", { required: true })}
          />
          <CheckboxField
            label="Option documentation"
            register={register("licence_option_documentation")}
          />

          <CheckboxField
            label="Option Maintenance"
            register={register("licence_option_control")}
          />

          <CheckboxField label="Option Setup" register={register("setup")} />
        </>
      )}
      <SubmitButton value="Enregistrer" />
    </form>
  );
}