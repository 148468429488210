import { Button } from "../Button";
import React from "react";
import { Dialog, Transition } from "@headlessui/react";
import { HiXMark } from "react-icons/hi2";
import {classNames} from "@scanow/helpers";

export interface ModalProps {
  children: React.ReactNode;
  isOpen: boolean;
  setState: React.Dispatch<React.SetStateAction<any>>;
  title?: string;
  appendClassName?: string;
  wide?: boolean;
  noMargin?: boolean;
}

export function Modal({
  children,
  isOpen,
  setState,
  title,
  appendClassName,
  wide,
  noMargin,
}: ModalProps): JSX.Element {
  function closeModal() {
    setState(false);
  }
  function openModal() {
    setState(true);
  }
  function toggleModal() {
    setState(!isOpen);
  }
  return (
    <>
      <Transition appear show={isOpen}>
        <Dialog
          as="div"
          className="modal fixed inset-0 z-40 overflow-y-auto "
          onClose={closeModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 backdrop-blur-sm bg-black/30 " />
            </Transition.Child>
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div
                className={`z-50 inline-block w-full overflow-hidden text-left align-middle dark:bg-neutral-900 transition-all transform bg-white shadow-xl rounded-2xl ${
                  wide ? "max-w-4xl" : "max-w-xl"
                } ${appendClassName}`}
              >
                <header className="text-white flex flex-col rounded-lg">
                  <section className="flex space-x-1 bg-secondary-600 p-1 pr-0 rounded-t-lg">
                    <nav className="-m-2">
                      <ul className="flex p-2 space-x-2">
                        <li className="p-2 rounded-md flex text-white cursor-default">
                          {title && (
                            <Dialog.Title as="h3">{title}</Dialog.Title>
                          )}
                        </li>
                      </ul>
                    </nav>
                    <div className="flex-grow"></div>

                    <Button
                      appendClassName="px-4 -m-1 -ml-3 hover:bg-secondary-500 rounded-tr-md"
                      onClick={closeModal}
                    >
                      <HiXMark className="w-7 h-7" />
                    </Button>
                  </section>
                </header>
                <div className={classNames(noMargin ? "p-0" : "p-4")}>
                  {children}
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
