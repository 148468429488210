import { useForm, FieldErrors, UseFormRegisterReturn, FieldErrorsImpl } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import React from "react";

export interface FormMessageErrorProps {
  name?: string;
  register?: UseFormRegisterReturn;
  errors?: FieldErrorsImpl<any>;
  type: "required" | "minLength" | "maxLength"; // TODO Rajouter tous les cas de figure
  message?: string;
  appendClassName?: string;
}

function DisplayMessageError(type: any) {
  switch (type) {
    case "required":
      return "Champ requis";
    case "minLength":
      return " Pas assez de charactères";
    case "maxLength":
      return "Trop de charactères";
    default:
      return "";
  }
};

export function FormMessageError({
  name,
  register,
  errors,
  type,
  message,
  appendClassName,
}: FormMessageErrorProps) {
  return (
    <>
      {register ? (
        <>
          {errors && (
            <ErrorMessage
              errors={errors}
              name={register?.name}
              render={({ message }: any) => (
                <small className={"text-pink-600 w-full block mb-3 " + appendClassName}>
                  {message}
                </small>
              )}
            />
          )}
        </>
      ) : (
        <>
          {errors && (
            <p
              // errors={errors} Ces 3 proriétés n'existent pas dans la balise p
              // name={name}
              // type={type}
              className={"text-pink-600 w-full block mb-3 " + appendClassName}
            >
              {message}
            </p>
          )}
        </>
      )}
    </>
  );
};
