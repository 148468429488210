import React from "react";
import { THEME_FORM_LABEL } from "@scanow/theme";

export interface LabelProps {
  name?: string;
 className?: string;
  appendClassName?: string;
  htmlFor?:string;
  children?: React.ReactNode;
}

export const Label = ({ name, className , appendClassName = "", children }: LabelProps) => {
  return (
    <label
      className={` ${className || THEME_FORM_LABEL } ${appendClassName}`}
      htmlFor={name || "LABEL"}
    >
      {children}
    </label>
  );
};
