import { useQuery } from "@apollo/client";
import { CompanyDetail } from "../Data/Detail"
import { Loading, ErrorMessage } from "@scanow/ui"
import React from "react"
import { GET_COMPANY_DETAIL } from "../Queries";


interface Props {
  id: string | string[];
}
interface CompanyData {
  company: any
}

export function CompanyDetailWrapper({ id }: Props): JSX.Element {
  const { data, loading, error } = useQuery<CompanyData>(GET_COMPANY_DETAIL, { variables: { id: id } })
  if (loading) {
    return (<Loading />)
  }
  if (error) {
    console.error(error)
    return (<ErrorMessage message="Une erreur inconnue s'est produite"/>)
  }
  if (data) {
    return <CompanyDetail company={data.company} />;
  }

  return (<ErrorMessage message="Une erreur inconnue est survenue. Code: 9999"/>)

};