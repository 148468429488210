import React from "react";
import {Paragraph, Text} from "@scanow/ui";

export interface IErrorMessage {
  className?: string;
  appendClassName?: string;
  message?: string;
}

export function ErrorMessage({
  appendClassName = "",
  className,
  message,
}: IErrorMessage) {
  return (
    <Paragraph
      className={`${className}`}
      appendClassName="py-2 px-4 inline-flex items-center justify-center bg-danger-500 text-white"
    >
     <Text.Small>{message} :(</Text.Small>
    </Paragraph>
  );
}