import { useRouter, withRouter } from "next/router";
import Link from "next/link";
import React, { Children } from "react";

/*
interface IActiveLink extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  children: JSX.Element
  href: string
  as?: string
  prefetch?: boolean;
  className?: string
  activeClassName?: string
  activeSubClassName?: string
}*/
interface ILinkActive{
    children: JSX.Element;
    href: string;
    //path: string;
    as?: string;
    prefetch?: boolean;
    className?: string;
    activeClassName?: string;
    activeSubClassName?: string;
  }
  export const LinkActive = ({ children, as, href, prefetch, activeClassName, activeSubClassName, ...rest }:ILinkActive)=> {
//export default function ActiveLink({ children, as, href, prefetch, activeClassName, activeSubClassName, ...rest }: IActiveLink/*, ref: any*/): JSX.Element {
  const router = useRouter();
  const child = Children.only(children);
  const childClassName = child.props.className || "";
  // remove URL parameters
  const sanitizedPath = router.asPath.split("#")[0].split("?")[0];
  // activeClassName and activeSubClassName are optional and default to "active" and "active-sub"
  const activeClass = activeClassName || "active";
  const activeSubClass = activeSubClassName || "active-sub";
  // remove trailing slash if present
  href = href && href !== "/" && href.endsWith("/") ? href.slice(0, -1) : href;
  as = as && as !== "/" && as.endsWith("/") ? as.slice(0, -1) : as;
  // check if the link or a sub-page is active and return the according class name
  const activityClassName = sanitizedPath === href || sanitizedPath === as ? activeClass : sanitizedPath.startsWith(href + "/") || sanitizedPath.startsWith(as + "/") ? activeSubClass : "";
  // combine the child class names with the activity class name
  const className = `${childClassName} ${activityClassName}`.trim();
  return (
    <Link href={href} as={as} /*prefetch={prefetch || false}*/ {...rest}>   
      {React.cloneElement(child, {
        className: className || null,
      }) as JSX.Element}
    </Link>
  );
};  


/*
// Forward Refs, is useful
export default React.forwardRef(({ to, prefetch, ...props }: IProps, ref: any) => {
  return (
    <Link href={to} prefetch={prefetch || false}>
      <a className="text-info-400 hover:text-info-500 visited:text-info-600" {...props} ref={ref} />
    </Link>
  );
});
*/