import * as React from "react";
import Link from "next/link";

// allow this component to accept all properties of "a" tag
interface IProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  to: string;
  prefetch?: boolean;
}
 
// Forward Refs, is useful
export default React.forwardRef(
  ({ to, prefetch, ...props }: IProps, ref: any) => {
    return (
      <Link href={to} prefetch={prefetch || false}>
        <a
          className="text-info-500 hover:text-info-600 visited:text-info-700"
          {...props}
          ref={ref}
        />
      </Link>
    );
  }
);
