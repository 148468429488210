import { SubmitButton } from "@scanow/ui";
import { InputField } from "@scanow/ui";
import { useMutation } from "@apollo/client";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { GET_USER_DETAIL, UPDATE_USER } from "../Queries";
import { generateHash, REGEX_PASSWORD_PATTERN } from "@scanow/helpers";
import React from 'react'

interface Props {
  user_id: string;
  old_password: string;
}

interface FormData {
  password: string;
  password_confirmation: string;
  old_password: string;
}

export function UserUpdatePasswordForm({ user_id, old_password }: Props): JSX.Element {
  const router = useRouter();
  const [updateUserPassword] = useMutation(UPDATE_USER, {
    refetchQueries: [
      GET_USER_DETAIL
    ],
  });
  const { register, handleSubmit, watch, setValue, getValues, reset, formState: { errors } } = useForm<FormData>()

  useEffect(() => {
    reset()
  }, [])

  function submitHandler(data: FormData) {

    // Check if old password is correct
    if (generateHash(data.old_password) === old_password) {

      if (data.password === data.password_confirmation) {
        updateUserPassword({
          variables: {
            id: user_id,
            _set: {
              password: generateHash(data.password)
            }
          }
        }).then(() => {
          toast.success("Le mot de passe à été réinitialisé avec succès !")
          reset()
        }).catch(() => {
          toast.error("Erreur lors de la tentative de réinitialisation du mot de passe.")
          reset()
        })
      } else {
        toast.error("Les mot de passe ne sont pas identiques")
        reset({ password: "", password_confirmation: "" })
      }

    } else {
      reset({ old_password: "" })
      toast.error("Les mot de passe ne sont pas identiques")

    }
  }
  return (
    <form onSubmit={handleSubmit(submitHandler)}>
      <InputField label="Ancien mot de passe" type="password" errors={errors} register={register("old_password", {
        required: "Ce champs est requis !"
      })} />
      <InputField label="Nouveau mot de passe" type="password" errors={errors} register={register("password", {
        required: "Ce champs est requis !",
        pattern: {
          value: REGEX_PASSWORD_PATTERN,
          message: "Le mot de passe doit contenir au moins 8 caractères, une majuscule, une minuscule, un chiffre et un caractère spécial"
        }
      })} />
      <InputField label="Confirmation du mot de passe" type="password" errors={errors} register={register("password_confirmation", {
        required: "Ce champs est requis !",
        pattern: {
          value: REGEX_PASSWORD_PATTERN,
          message: "Le mot de passe doit contenir au moins 8 caractères, une majuscule, une minuscule, un chiffre et un caractère spécial"
        }
      })} />

      <SubmitButton value="Enregistrer" />
    </form>
  )
}