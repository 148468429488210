import { useQuery } from "@apollo/client";
import { CompanyList } from "../Data/List"
import { Loading, ErrorMessage } from "@scanow/ui"
import React from "react";
import { ICompanies } from "../Interfaces";
import { GET_ALL_COMPANIES } from "../Queries";
import { userAuthReturn } from '@scanow/helpers';

interface Props {
  role: number
  readOnly?: boolean
  auth: userAuthReturn
}
export function CompanyListWrapper(props: Props): JSX.Element {
  const { data, loading, error } = useQuery<ICompanies>(GET_ALL_COMPANIES);
  if (loading) {
    return (<Loading />);
  }
  if (error) {
    console.error(error);
    return (<ErrorMessage message="Une erreur inconnue s'est produite"/>)
  }
  if (data) {
    return <CompanyList companies={data.companies} {...props} />;
  }
  return (<ErrorMessage message="Une erreur inconnue est survenue. Code: 9999"/>)
}