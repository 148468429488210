import { useQuery } from "@apollo/client";
import { Loading, ErrorMessage } from "@scanow/ui"
import dynamic from "next/dynamic";
import React from "react";
import { LocationUpdateForm } from "../Form/Update";
import { ILocationLite } from "../Interfaces";
import { GET_UPDATED_LOCATION } from "../Queries";

interface Props {
  id: string
  cb_success?: any
  cb_error?: any
  role: number
}

interface LocationData {
  location: ILocationLite
}



export function LocationUpdateWrapper({ id, ...props }: Props): JSX.Element {

  const { data, loading, error } = useQuery<LocationData>(GET_UPDATED_LOCATION, { variables: { id: id } });
  if (loading) {
    return (<Loading />);
  }
  if (error) {
    console.error(error);
    return (<ErrorMessage message="Une erreur inconnue s'est produite"/>)
  }
  if (data) {

    return <LocationUpdateForm location={data.location} {...props} />;
  }


  return (<ErrorMessage message="Une erreur inconnue s'est produite"/>)
}