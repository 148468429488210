import * as React from "react";
import {Text, Heading} from "@scanow/ui";
import {classNames} from "@scanow/helpers";

interface ICard {
  children: React.ReactNode;
  displayName?: string;
  className?: string;
  appendClassName?: string;
  cardTitle?: string;
  props?: JSX.Element;
  activeTab: string;
  onTabChange: (tab: string) => void;
  noMargin?: boolean;
}

const getChildrenDisplayName = (children: any, displayName: any): any =>
    React.Children.map(children, (child) => {
      return child.type.displayName === displayName ? child : null;
    });

export function TabCard({
                          children,
                          className = "p-6 rounded-xl",
                          appendClassName,
                          cardTitle,
                          activeTab,
                          onTabChange,
                          noMargin,
                        }: ICard): JSX.Element {
  const tabs = getChildrenDisplayName(children, "Tab");

  const tabsNames = tabs.map((tab: any) => {
    return {
      name: tab.props.title,
      active: tab.props.title === activeTab,
    };
  });


  var header =
      getChildrenDisplayName(children, "Header") &&
      getChildrenDisplayName(children, "Header")[0];

  if (header) {
    header = React.cloneElement(header, {
      tabs: tabsNames,
      onTabChange: onTabChange,
    });
  } else {
    header = <Header tabs={tabsNames} onTabChange={onTabChange} cardTitle={cardTitle}/>
  }

  const footer = getChildrenDisplayName(children, "Footer");
  return (
      // <section className={`${className} ${cardTitle && ""} ${appendClassName}`}>
      <section className={classNames(
          className,
          cardTitle && "",
          appendClassName,
          noMargin && "m-0 p-0"
      )}>
        {header}

        {tabs.map((tab: any) => {
          return (
              <div
                  key={`tabBody-${tab.props.title}`}
                  className={`${tab.props.title === activeTab ? "flex" : "hidden"}`}
              >
                {tab}
              </div>
          );
        })}
        {footer}
      </section>
  );
}

interface IHeader {
  children?: React.ReactNode;
  appendClassName?: string;
  cardTitle?: string;
  create_button_cb?: () => void;
  update_button_cb?: () => void;
  print_qrcode_button_cb?: () => void;
  setting_button_cb?: () => void;
  list_button_disable?: boolean;
  tabs?: {
    name: string;
    active: boolean;
  }[];
  onTabChange?: (tab: string) => void;
  back_button_callback?: () => void;
}

const Header = ({
                  children,
                  appendClassName = "",
                  cardTitle,
                  tabs,
                  onTabChange,
                  back_button_callback,
                  ...props
                }: IHeader) => {
  return (
      <>
        <header
            className={`my-2 border-b border-neutral-200 dark:border-neutral-800 ${appendClassName}`}
        >
          {cardTitle && (
              <Heading
                  title={cardTitle}
                  back_button_cb={back_button_callback}
                  {...props}
              />
          )}

          <ul className="flex flex-wrap space-x-2 pt-4">
            {tabs &&
                tabs.map((tab) => (
                    <li
                        key={`tabHead-${tab.name}`}
                        onClick={() => {
                          console.log("clicked");
                          onTabChange && onTabChange(tab.name);
                        }}
                        className={`cursor-pointer p-1.5 uppercase ${
                            tab.active
                                ? "border-b-2 border-secondary-800 text-secondary-800 dark:text-white dark:border-white font-semibold"
                                : "border-transparent text-gray-400 pb-0 mt-1"
                        }`}
                    >
                     <Text.Medium>{tab.name}</Text.Medium>
                    </li>
                ))}
          </ul>
          {/*props.update_button_cb && (
           <Button.Variant
           onClick={props.update_button_cb}
           color="Light"
           style="Circle"
           shape="Full"
         >
           <HiOutlinePencil />
         </Button.Variant>
        )*/}

          {children}
        </header>
      </>
  );
};
Header.displayName = "Header";
TabCard.Header = Header;

interface TabProps {
  children: React.ReactNode;
  appendClassName?: string;
  title: string;
}

const Tab = ({children, appendClassName = "", title}: TabProps) => (
    <div className={`break-words flex-grow py-6 ${appendClassName}`}>
      {children}
    </div>
);
Tab.displayName = "Tab";
TabCard.Tab = Tab;

const Footer = ({children, appendClassName = "", ...props}: any) => (
    <footer
        className={`border-t border-neutral-200 dark:border-neutral-800 ${appendClassName}`}
        {...props}
    >
      {children}
    </footer>
);
Footer.displayName = "Footer";
TabCard.Footer = Footer;