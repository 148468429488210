import { SubmitButton } from '@scanow/ui';
import { ColorPickerField, ImageField, InputField } from '@scanow/ui';
import { UPLOAD_FILE } from '../../fileManager/Queries';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'next-i18next';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { TeamSelectorField } from '../../team/Form/TeamSelector';
import { UPDATE_COMPANY } from '../Queries';
import React from 'react';
import { userAuthReturn } from '@scanow/helpers';
import { fileManagerUploadFile } from '@scanow/helpers';

interface FormData {
  id: string;
  name: string;
  logo_url?: string;
  main_color: string;
  defaut_team_id?: string;
}

interface Props {
  default_values: FormData;
  cb_success: any;
  cb_error: any;
  auth: userAuthReturn;
}

export function CompanyUpdateSettings({
                                        default_values,
                                        cb_error,
                                        cb_success,
                                        auth,
                                      }: Props): JSX.Element {
  //  Initialize React-hook-form
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
    setError,
    setValue,
  } = useForm<FormData>({
    defaultValues: default_values,
  });


  const image_url_watcher = watch('logo_url');

  const [updateMutation] = useMutation(UPDATE_COMPANY, {
    refetchQueries: 'all',
  });
  useEffect(() => {
    reset();
  }, []);

  async function fileUpload(file: File): Promise<void> {
    const uploadedFile = await fileManagerUploadFile(file, auth!.accessToken as string);
    setValue('logo_url', uploadedFile.full_url);
  }

  function onFileChange(file: File) {
    toast.promise(fileUpload(file), {
        pending: 'Téléchargement du fichier',
        success: 'Fichier téléchargé avec succès',
        error: 'Erreur lors du téléchargement du fichier',
      },
    );
  }

  function onSubmit(data: FormData) {
    updateMutation({
      variables: {
        id: default_values.id,
        _set: {
          name: data.name,
          logo_url: data.logo_url,
          main_color: data.main_color,
          defaut_team_id: data.defaut_team_id,
        },
      },
    })
      .then((res) => {
        toast.success("L'entreprise a été modifiée avec succès ! ");
        if (cb_success) {
          cb_success(res);
        }
      })
      .catch((err) => {
        toast.error("Erreur lors de la tentative de modification de l'entreprise.");
        if (cb_error) {
          cb_error(err);
        }
      });
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <InputField
        label={'Nom'}
        register={register('name', {
          required: 'Ce champ est requis !',
          minLength: {
            value: 3,
            message: 'Le nom doit contenir au moins 3 caractères !',
          },
          maxLength: {
            value: 32,
            message: 'Le nom ne doit pas contenir plus de 32 caractères !',
          },
        })}
        errors={errors}
      />

      <ImageField
        register={register('logo_url', { required: false })}
        onFileChange={onFileChange}
        setError={setError}
        errors={errors}
        value={image_url_watcher}
        label={"Logo de l'entreprise"}
        onReset={() => {
          //@ts-ignore
          setValue('logo_url', null);
        }}
      />

      <ColorPickerField
        label="Couleur de l'entreprise"
        value={watch('main_color')}
        setValue={setValue}
        register={register('main_color', { required: false })}
      />

      <TeamSelectorField
        register={register('defaut_team_id', { required: true })}
        label="Équipe par défaut"
      />
      <SubmitButton/>
    </form>
  );
}