import React from "react"
import Link from "next/link";
//import {ISlider} from "@/interfaces/slider";
import {Avatar} from "../Avatar";
import { Button, NextPrev } from "../Button"

//import ItemTypeVideoIcon from "components/ItemTypeVideoIcon";
//import CountDown from "@/components/Shared/CountDown";
//import VerifyIcon from "components/VerifyIcon";

//Fake Data
let src1 = "/images/no_avatar.png";
let src2 = "images/1.png";
let src3 = "images/1.png";
export const LargeImgs = [src1, src2, src3];

export interface CardLargeProps {
  className?: string;
  onClickNext?: () => void;
  onClickPrev?: () => void;
  isShowing?: boolean;
  featuredImgUrl?: string;
}

interface ISlider {
  id?: string;
  title: string;
  description?: string;
  price?: string;
  src?: string;
}
const SLIDER_DATA: ISlider[] = [
  {
    id: "1",
    title: "Scanow Helpdesk",
    description: "Déclarer un incident en quelques secondes via qrcode ",
    price: "32",
    src: "/images/no_avatar.jpg",
  },
  {
    id: "2",
    title: "Scanow Inventory",
    description: "Inventoriez vos équipements ",
    price: "41",
    src: "/images/no_avatar.jpg",
  },
];
export const CardLarge = ({
  className = "",
  isShowing = true,
  onClickNext = () => {},
  onClickPrev = () => {},
  featuredImgUrl = LargeImgs[0],
}: CardLargeProps) => {
  const randomTitle = [
    "Walking On Air ",
    "Amazing Nature",
    "Beautiful NFT",
    "Lovely NFT",
    "Wolf Face #1",
  ];
  return (
    <div
      className={`bg-error-500 FadeZoom FadeZoom--hasAnimation relative flex flex-col-reverse lg:flex-row justify-end ${className}`}
    >
      <div className="lg:absolute z-10 lg:left-0 lg:top-1/2 lg:transform lg:-translate-y-1/2 -mt-2 lg:mt-0 sm:px-5 lg:px-0 w-full lg:max-w-lg ">
        <div className="FadeZoom__left p-4 sm:p-8 xl:py-14 md:px-10 bg-white dark:bg-neutral-900 shadow-lg rounded-3xl space-y-3 sm:space-y-8 ">
          {/* TITLE */}
          <h2 className="text-2xl lg:text-3xl 2xl:text-5xl font-semibold ">
            <Link href={"/nft-detailt"} title="Walking On Air">
              <a>
                {/* TODO LOOP of SLIDER DATA */}
                {SLIDER_DATA[0].title}
                {randomTitle[Math.floor(Math.random() * randomTitle.length)]}
              </a>
            </Link>
          </h2>

          {/* AUTHOR AND COLLECTION */}
          <div className="flex flex-col sm:flex-row space-y-3 sm:space-y-0 sm:space-x-12">
            <div className="flex items-center">
              <div className="flex-shrink-0 h-10 w-10">
                <Avatar src="/images/avatar.png" appendClassName="w-10 h-10" />
              </div>
              <div className="ml-3">
                <div className="text-xs dark:text-neutral-400">Creator</div>
                <div className="text-sm font-semibold flex items-center">
                  <span>Jane Cooper</span>
                  {/*<VerifyIcon />*/}+
                </div>
              </div>
            </div>
            <div className="flex items-center">
              <div className="flex-shrink-0 h-10 w-10">
                <Avatar appendClassName="w-10 h-10" src="/images/avatar.png" />
              </div>
              <div className="ml-3">
                <div className="text-xs dark:text-neutral-400">Collection</div>
                <div className="text-sm font-semibold ">Marscapes</div>
              </div>
            </div>
          </div>

          {/* PRICE */}
          <div className="pt-6">
            <div className="flex flex-col sm:flex-row items-baseline p-6 border-2 border-green-500 rounded-xl relative">
              <span className="block absolute bottom-full translate-y-1.5 py-1 px-1.5 bg-white dark:bg-neutral-900 text-sm text-neutral-500 dark:text-neutral-400 ring ring-offset-0 ring-white dark:ring-neutral-900">
                à partir de
              </span>
              <span className="text-3xl xl:text-4xl font-semibold text-green-500">
                1.09 €
              </span>
              <span className="text-lg text-neutral-400 sm:ml-3.5">/mo</span>
            </div>
          </div>

          {/* AUTION TIME 
          <CountDown />*/}

          <div className="w h-[1px] bg-neutral-100 dark:bg-neutral-700"></div>

          {/* DESCRIPTION */}
          <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-3">
            <Button.Primary href={"/nft-detailt"}>
              Plus d'info ?
            </Button.Primary>
            <Button.Primary href={"/nft-detailt"} >
              Demander un devis
            </Button.Primary>
          </div>
        </div>
        <div className="p-4 sm:pt-8 sm:px-10 ">
          <NextPrev
            btnClassName="w-11 h-11 text-xl"
            onClickNext={onClickNext}
            onClickPrev={onClickPrev}
          />
        </div>
      </div>

      <div className="w-full lg:w-[64%] relative ">
        <div className="FadeZoom__right ">
          <Link href={"/nft-detailt"}>
            {/*<Images
              containerClassName="aspect-w-1 aspect-h-1 relative"
              className="absolute inset-0 object-cover rounded-3xl sm:rounded-[40px] border-4 sm:border-[14px] border-white dark:border-neutral-800"
              src={featuredImgUrl}
              alt={"title"}
            />*/}IMG
          </Link>
          {/* META TYPE 
          <ItemTypeVideoIcon className="absolute w-8 h-8 md:w-10 md:h-10 left-3 bottom-3 sm:left-7 sm:bottom-7 " />
*/}
          video icon
          {/* META FAVORITES */}
          {/* <ButtonLike className="absolute right-3 top-3 sm:right-7 sm:top-7" /> BUTTON LIKE n'existe plus dans notre lib */}
        </div>
      </div>
    </div>
  );
};

//export default CardLarge;
