import { gql, TypedDocumentNode } from "@apollo/client";
import { IUserHelpdeskStats, IUserInventoryStats } from "./Interfaces";

interface VariableID {
  id: string;
}

export const GET_ALL_USERS = gql`
  query allUsers {
    users: administration_user(
      order_by: { firstname: asc }
      where: { deleted: { _eq: false } }
    ) {
      id
      firstname
      lastname
      avatar_url
      email
      company_id
      created_at
      team_id
      role_id
      company {
        name
      }
      role {
        name
      }
      user_role {
        role {
          name
          slug
        }
      }
      team {
        name
      }
    }
  }
`;

export const GET_ALL_USERS_NAME = gql`
  query allUsersByName {
    users: administration_user {
      id
      firstname
      lastname
    }
  }
`;

export const GET_USER_DETAIL = gql`
  query userDetail($id: uuid!) {
    user: administration_user_by_pk(id: $id) {
      id
      firstname
      password
      lastname
      avatar_url
      email
      company_id
      password
      created_at
      team_id

      role_id
      company {
        name
      }
      role {
        id
        name
        slug
      }
      user_role {
        role {
          id
          name
          slug
        }
      }
      mail_notify_on_incident
      team {
        name
      }
    }
  }
`;
//TODO company en dur
export const CREATE_USER = gql`
  mutation createUser(
    $firstname: String!
    $lastname: String!
    $avatar_url: String
    $email: String!
    $password: String!
    $team_id: Int
  ) {
    insert_administration_user_one(
      object: {
        firstname: $firstname
        lastname: $lastname
        avatar_url: $avatar_url
        email: $email
        password: $password
        team_id: $team_id
      }
    ) {
      id
      firstname
      lastname
      avatar_url
      email
      company_id
      created_at
      team_id
      role_id
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser($id: uuid!, $_set: administration_user_set_input) {
    user: update_administration_user_by_pk(
      pk_columns: { id: $id }
      _set: $_set
    ) {
      id
      firstname
      lastname
      avatar_url
      email
      company_id
      created_at
      team_id
      role_id
    }
  }
`;

export const DELETE_USER = gql`
  mutation deleteUser($id: uuid!) {
    user: delete_administration_user_by_pk(id: $id) {
      id
    }
  }
`;

export const DISABLE_USER = gql`
  mutation disableUser($id: uuid!) {
    user: update_administration_user_by_pk(
      pk_columns: { id: $id }
      _set: { deleted: true }
    ) {
      id
      firstname
      lastname
      avatar_url
      email
      company_id
      created_at
      team_id
      role_id
    }
  }
`;

export const GET_ALL_ROLES_NAME = gql`
  query allRolesByName {
    roles: administration_role {
      id
      name
      slug
    }
  }
`;

// TODO voir pour password update
export const GET_UPDATE_USER = gql`
  query userDetail($id: uuid!) {
    user: administration_user_by_pk(id: $id) {
      id
      firstname
      lastname
      avatar_url
      password
      email
      company_id
      mail_notify_on_incident
      team_id
    }
  }
`;

export const SIGNIN_MUTATION = gql`
  mutation login($email: String!, $password: String!) {
    login: loginv2(data: { email: $email, password: $password }) {
      accessToken
      user {
        company {
          id
          logo_url
          name
          main_color
        }
      }
    }
  }
`;

export const GET_USER_ROLE_ATTRIBUTION = gql`
  query getUserRoleAttribution($id: uuid!) {
    roles: administration_user_role(where: { user_id: { _eq: $id } }) {
      id
      role {
        id
        name
        slug
      }
    }
  }
`;

export const DELETE_USER_ROLE_ATTRIBUTION = gql`
  mutation deleteUserRoleAttribution($id: Int!) {
    delete_administration_user_role_by_pk(id: $id) {
      id
    }
  }
`;

export const CREATE_USER_ROLE_ATTRIBUTION = gql`
  mutation inserUserRoleAttribution($role_id: Int!, $user_id: uuid!) {
    insert_administration_user_role_one(
      object: { role_id: $role_id, user_id: $user_id }
    ) {
      id
    }
  }
`;
// Requete pour générer le token de reset de mot de passe
export const AUTH_FORGET_PASSWORD = gql`
  mutation authForgetPassword($email: String!) {
    password_reset(data: { email: $email }) {
      done
    }
  }
`;
// Requete pour refaire le mot de passe avec le token
export const AUTH_SET_NEW_PASSWORD = gql`
  mutation authSetNewPassword($new_password: String!, $token: String!) {
    set_password_reset(data: { new_password: $new_password, token: $token }) {
      done
    }
  }
`;

export const GET_USER_SETTINGS = gql`
  query getUserSettings($id: uuid!) {
    user: administration_user_by_pk(id: $id) {
      id
      firstname
      lastname
      avatar_url
      email
      team_id
      user_role {
        role {
          id
          name
        }
      }
      mail_notify_on_incident
      team {
        name
        id
      }
    }
  }
`;

export const GET_USER_HELPDESK_STATS: TypedDocumentNode<
  { user: IUserHelpdeskStats },
  VariableID
> = gql`
  query getUserHelpdeskStats($id: uuid!) {
    user: administration_user_by_pk(id: $id) {
      id
      articles_aggregate {
        aggregate {
          count
        }
      }

      incidents_aggregate {
        aggregate {
          count
        }
      }

      histories_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;

export const GET_USER_INVENTORY_STATS: TypedDocumentNode<
  { user: IUserInventoryStats },
  VariableID
> = gql`
  query getUserInventoryStats($id: uuid!) {
    user: administration_user_by_pk(id: $id) {
      id
      items_aggregate {
        aggregate {
          count
        }
      }
      checkings_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;
