import React from "react";
import { VideoPlayer } from "../VideoPlayer/index";

export interface VideoCoverProps {
  className?: string;
  video?: string;
}
// TODO

export const VideoCover = ({
  // className = "absolute inset-x-0 md:top-10 min-h-0 pl-20 py-24 flex overflow-hidden z-0",
  video,
  className = "",
}: VideoCoverProps) => {
  return <VideoPlayer video={video} className={` ${className}`} />;
};
