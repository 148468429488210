
export const REGEX_EMAIL_PATTERN = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const REGEX_PHONE_PATTERN = /^[0-9]{10}$/;

export const REGEX_ZIPCODE_PATTERN = /^[0-9]{7}$/;

//Regex password pattern strong password with at least one lowercase letter, one uppercase letter, one number and one special character and at least 8 characters 
export const REGEX_PASSWORD_PATTERN = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;


export function capitalize(str: string): string {
  return str.charAt(0).toUpperCase() + lowercase(str.slice(1));
}

export function capitalizeIfNeeded(str: string): string {
  // If the string contains atleast one uppercase letter, we skip it
  if (str.match(/[A-Z]/)) {
    return str;
  } else {
    return capitalize(str);
  }
}

export function capitalizeOnlyFirst(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function lowercase(str: string): string {
  return str.toLowerCase()
}

export function uppercase(str: string): string {
  return str.toUpperCase()
}

export function isEmail(str: string): boolean {
  return REGEX_EMAIL_PATTERN.test(str);
}

export function isPhone(str: string): boolean {
  return REGEX_PHONE_PATTERN.test(str);
}