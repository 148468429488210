import {gql} from '@apollo/client';

export const GET_ALL_FILES = gql`
    query allFiles {
        files: file_manager_file {
            id
            mime
            name
            url
        }
    }
`

export const GET_ALL_FILES_BY_COMPANY = gql`
    query allFilesByCompany($_eq: uuid!) {
        files: file_manager_file(where: {company: {id: {_eq: $_eq}}}) {
            id
            mime
            name
            url
        }
    }
`


export const UPLOAD_FILE = gql`
    mutation fileUpload($encodedfile: String!, $filename: String!, $company_id: uuid!) {
        new_file: file_upload(data: {encodedfile: $encodedfile, filename: $filename, company_id: $company_id}) {
            id
            name
            url
            mime
        }
    }

`