import React, { HTMLAttributes, ReactNode } from "react";
import { Title, Button, NextPrev } from "@scanow/ui";
import {
  HiOutlineArrowUturnLeft,
  HiOutlinePlus,
  HiOutlinePencil,
  HiOutlineCog8Tooth,
  HiOutlinePencilSquare,
  HiOutlinePrinter,
  HiArrowsRightLeft,
  HiOutlineQrCode,
  HiOutlineListBullet
} from "react-icons/hi2";
import {
  TfiReload
} from "react-icons/tfi";
import { useRouter } from "next/router";


export interface IHeading extends HTMLAttributes<HTMLHeadingElement> {
  fontClass?: string;
  desc?: ReactNode;
  hasNextPrev?: boolean;
  isCenter?: boolean;
  title?: string;
  create_button_cb?: any;
  update_button_cb?: any;
  transfert_button_cb?: any;
  setting_button_cb?: any;
  print_qrcode_button_cb?: any;
  list_button_disable?: boolean;
  back_button_disable?: boolean;
  back_button_cb?: () => void;
  reload_button_cb?: () => void;
}
 
export const Heading = ({
  children,
  desc = "Page heading top ",
  className = "text-neutral-900 dark:text-neutral-100",
  isCenter = false,
  hasNextPrev = false,
  title,
  create_button_cb,
  update_button_cb,
  transfert_button_cb,
  print_qrcode_button_cb,
  setting_button_cb,
  list_button_disable,
  back_button_disable = false,
  back_button_cb,
  reload_button_cb,
  ...args
}: IHeading) => {
  const router = useRouter();

  return (
    <div
      className={`py-2 relative flex items-center justify-between  ${className}`}
    >
      <div
      className={`items-center flex ${isCenter ? "text-center w-full mx-auto " : ""}`}
      >
        <Title title={title} />
      </div>
      <div>
        <div className="flex items-center space-x-2 mt-4 lg:m-0">
          {create_button_cb && (
            <Button.Variant
              onClick={() => {
                if (create_button_cb) {
                  create_button_cb();
                } 
              }}
              color="Light"
              style="Circle"
              shape="Full"
              title={"Créer"}
            >
              <HiOutlinePlus />
            </Button.Variant>
          )}
          {!back_button_disable && (
            <Button.Variant
              onClick={()=>{
                if(back_button_cb){
                  back_button_cb()
                }else{
                  router.back()
                }
              }}
              color="Light"
              style="Circle"
              shape="Full"
              title={"Page précédente"}
            >
              <HiOutlineArrowUturnLeft/>
            </Button.Variant>
          )}
          {update_button_cb && (
            <Button.Variant
              onClick={() => {
                if (update_button_cb) {
                  update_button_cb();
                }
              }}
              color="Light"
              style="Circle"
              shape="Full"
              title={"Modifier"}
            >
              <HiOutlinePencil />
            </Button.Variant>
          )}
          {setting_button_cb && (
            <Button.Variant
              onClick={() => {
                if (setting_button_cb) {
                  setting_button_cb();
                }
              }}
              color="Light"
              style="Circle"
              shape="Full"
              title={"Paramètres"}
            >
              <HiOutlineCog8Tooth />
            </Button.Variant>
          )}
          {transfert_button_cb && (
            <Button.Variant
              onClick={() => {
                if (transfert_button_cb) {
                  transfert_button_cb();
                }
              }}
              color="Light"
              style="Circle"
              shape="Full"
              title={"Transfert de stock"}
            >
              <HiArrowsRightLeft />
            </Button.Variant>
          )}
          {print_qrcode_button_cb && (
              <Button.Variant
                  onClick={() => {
                    if (print_qrcode_button_cb) {
                      print_qrcode_button_cb();
                    }
                  }}
                  color="Light"
                  style="Circle"
                  shape="Full"
                  title={"Imprimer des QR Codes"}
              >
                <HiOutlineQrCode />
              </Button.Variant>
          )}
          {!list_button_disable && (
            <Button.Variant
              //title=""
              onClick={() => {
                let splitted = router.asPath.split("/");
                delete splitted[splitted.length - 1];
                router.push(splitted.join("/"));
              }}
              color="Light"
              style="Circle"
              shape="Full"
              title={"Afficher la liste"}
            >
              <HiOutlineListBullet/>
            </Button.Variant>
          )}
          {reload_button_cb && (
            <Button.Variant
              //title=""
              onClick={() => {
                if (reload_button_cb) {
                  reload_button_cb();
                }
              }}
              color="Light"
              style="Circle"
              shape="Full"
              title={"Rafraîchir la liste"}
            >
              <TfiReload />
            </Button.Variant>
          )}
        </div>
        {hasNextPrev && !isCenter && (
          <div className="mt-4 flex justify-end sm:ml-2 sm:mt-0 flex-shrink-0">
            <NextPrev onClickNext={() => {}} onClickPrev={() => {}} />
          </div>
        )}
      </div>
    </div>
  );
};