import React from 'react';
import { THEME_FORM_LABEL, THEME_TYPO_NORMAL, THEME_TYPO_MEDIUM, THEME_TYPO_SMALL, THEME_TYPO_SUBTITLE, THEME_TYPO_TITLE } from '@scanow/theme';

interface TextProps {
  children: React.ReactNode;
  title?: string;
  className: string;
  appendClassName?: string;
}

export function Text({ children, title, className }: TextProps): JSX.Element {
  return <span className={className || THEME_TYPO_NORMAL} title={title}>{children}</span>;
}
 
type TextVariant = Omit<TextProps, 'className'>;

Text.Title = function TextTitle({ children, appendClassName = '' }: TextVariant): JSX.Element {
  return (
    <h1 className={`${THEME_TYPO_TITLE} ${appendClassName}`}>{children}</h1>
  );
};

Text.Subtitle = function TextSubtitle({ children, appendClassName = '' }: TextVariant): JSX.Element {
  return (
    <h2 className={`${THEME_TYPO_SUBTITLE} ${appendClassName}`}>{children}</h2>
  );
};

Text.Normal = function TextNormal({ children, appendClassName = '' }: TextVariant): JSX.Element {
  return <Text className={`${THEME_TYPO_NORMAL} ${appendClassName}`}>{children}</Text>;
};

Text.Medium = function TextMedium({ children, appendClassName = '' }: TextVariant): JSX.Element {
  return <Text className={`${THEME_TYPO_MEDIUM} ${appendClassName}`}>{children}</Text>;
};

Text.Small = function TextSmall({ children, appendClassName = '' }: TextVariant): JSX.Element {
  return <Text className={`${THEME_TYPO_SMALL} ${appendClassName}`}>{children}</Text>;
};

Text.Default = Text.Normal;

/****** PARTIE A REVOIR EN TANT QUE PARAMETRE PLUTOT *********/

Text.Bold = function TextBold({ children, appendClassName = '' }: TextVariant): JSX.Element {
  return <strong className={`font-medium ${appendClassName}`}>{children}</strong>;
};

Text.Italic = function TextItalic({ children, appendClassName = '' }: TextVariant): JSX.Element {
  return <i className={`font-italic ${appendClassName}`}>{children}</i>;
};

Text.Strong = function TextStrong({ children, appendClassName = '' }: TextVariant): JSX.Element {
  return <strong className={`font-bold ${appendClassName}`}>{children}</strong>;
}

Text.Light = function TextLight({ children, appendClassName = '' }: TextVariant): JSX.Element {
  return <span className={`font-light ${appendClassName}`}>{children}</span>;
};


Text.Warning = function TextWarning({ children, appendClassName = '' }: TextVariant): JSX.Element {
  return <span className={`text-warning-600 ${appendClassName}`}>{children}</span>;
};
/****** PARTIE A REVOIR EN TANT QUE PARAMETRE PLUTOT *********/
