import { SubmitButton, CheckboxField } from "@scanow/ui"
import { UPLOAD_FILE } from "../../fileManager/Queries"
import { useMutation } from "@apollo/client"
import { useTranslation } from "next-i18next"
import React, { useEffect } from "react"
import { useForm } from "react-hook-form"
import { toast } from "react-toastify"
import { UPDATE_COMPANY } from "../Queries"


interface FormData {
  id: string
  incident_phone_enabled: boolean
  incident_mail_enabled: boolean
  incident_phone_required: boolean
  incident_mail_required: boolean
}

interface Props {
  default_values: FormData,
  cb_success: any,
  cb_error: any
}

export function CompanyUpdateHelpDeskSettings({ default_values, cb_error, cb_success }: Props): JSX.Element {
  //  Initialize React-hook-form
  const { register, handleSubmit, reset, formState: { errors }, watch, setValue } = useForm<FormData>({
    defaultValues: default_values
  })


  const { t } = useTranslation("company");

  const [updateMutation] = useMutation(UPDATE_COMPANY, { refetchQueries: 'all' })
  const [fileUploadMutation] = useMutation(UPLOAD_FILE)
  useEffect(() => {
    reset()
  }, [])

  function onSubmit(data: FormData) {

    updateMutation({
      variables: {
        id: default_values.id,
        _set: {
          incident_phone_enabled: data.incident_phone_enabled,
          incident_mail_enabled: data.incident_mail_enabled,
          incident_phone_required: data.incident_phone_required,
          incident_mail_required: data.incident_mail_required

        }
      }
    }).then(res => {
      toast.success("L'entreprise a été modifiée avec succès !")
      if (cb_success) {
        cb_success(res)
      }
    }).catch(err => {
      console.error(err)
      toast.error("Erreur lors de la tentative de modification de l'entreprise.")
      if (cb_error) {
        cb_error(err)
      }
    })

  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} >
      <fieldset>
        <legend className="text-center px-2 py-1 rounded">Champ téléphone</legend>
        <CheckboxField label="Demander le téléphone de l'utilisateur à l'ouverture d'un incident" register={register("incident_phone_enabled")} />
        <CheckboxField label="Obliger l'utilisateur à indiquer son téléphone pour ouvrir un incident" register={register("incident_phone_required")} />
      </fieldset>
      <fieldset>
        <legend className="text-center px-2 py-1 rounded">Champ Email</legend>
        <CheckboxField label="Demander l'email de l'utilisateur à l'ouverture d'un incident" register={register("incident_mail_enabled")} />
        <CheckboxField label="Obliger l'utilisateur à indiquer son email pour ouvrir un incident" register={register("incident_mail_required")} />
      </fieldset>
      <SubmitButton value="Enregistrer"/>
    </form>
  )



}