export const THEME_BUTTON =
  "relative w-full cursor-pointer flex justify-center my-4 p-4 rounded-md border border-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 duration-300 transition ease-in-out";

export const THEME_BUTTON_PRIMARY = ` text-white bg-primary-600 hover:bg-opacity-90`;
export const THEME_BUTTON_SECONDARY = ` text-white bg-secondary-600 hover:bg-opacity-90`;
export const THEME_BUTTON_TERTIARY = ` text-neutral-900 bg-tertiary-600 hover:bg-opacity-90`;
export const THEME_BUTTON_NEUTRAL = ` bg-neutral-200 hover:bg-neutral-300 dark:bg-neutral-700 dark:text-neutral-200 dark:border-none`;
export const THEME_BUTTON_INFO = ` text-white bg-info-500 hover:bg-info-600`;
export const THEME_BUTTON_SUCCESS = ` text-white bg-success-500 hover:bg-opacity-90`;
export const THEME_BUTTON_WARNING = ` text-secondary-800 bg-yellow-200 hover:bg-yellow-300`;
export const THEME_BUTTON_DANGER = ` text-white bg-danger-500 hover:bg-opacity-90`;
export const THEME_BUTTON_ERROR = ` text-white bg-error-500 hover:bg-opacity-90`;
export const THEME_BUTTON_LIGHT = ` bg-white text-secondary-800 border border-neutral-200 hover:bg-opacity-90 hover:border-neutral-300 dark:border-none dark:text-neutral-200 dark:hover:bg-neutral-700 dark:bg-neutral-800`;