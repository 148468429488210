import React from "react";
import { useRouter } from "next/router";
import { IUserLite } from "../../user/Interfaces";
import { Avatar, Button, Text } from "@scanow/ui";
import {HiOutlineUsers} from "react-icons/hi2";

interface TeamProps {
  company: {
    name: string;
  },
  team: {
    name: string;
    users: IUserLite[];
  };
}

export function TeamUserList({ team, company }: TeamProps): JSX.Element {
  const router = useRouter();
  return (
    <div className="flex h-full flex-col justify-center items-center gap-2">
      <div className="flex items-center justify-center bg-slate-200 text-slate-700 h-16 w-16 p-2 rounded-full" mx-auto>
      <HiOutlineUsers size={32} />
      </div>
    
    <Text.Title>Équipe {team.name}</Text.Title>
    {/*<Text.Subtitle>{company.name}</Text.Subtitle>*/}
    <Text.Normal>
      {team.users.length === 0 && "Cette équipe ne contient aucun membre"}
      {team.users.length === 1 && "1 membre"}
      {team.users.length > 1 && `${team.users.length} membres`}
    </Text.Normal>
    <div className=" flex flex-wrap gap-4 max-w-5xl justify-center ">
      {team.users.map((user) => (
        <div className="flex flex-col items-center justify-center">
            <Avatar
              src={user.avatar_url || "/images/no_avatar.png"}
              alt={user.firstname}
              title={user.firstname}
             appendClassName="w-16 h-16"
              />

          <Text.Normal>{user.firstname} {user.lastname}</Text.Normal>
          <Text.Small>{user.email}</Text.Small>
        </div>
        ))}
    </div>

  </div>


  );
}


    // OLD Version
    // <ul className="flex flex-wrap items-center justify-center w-full pt-4">
    //   {team.users.map((user: IUserLite) => (
    //     <li
    //       key={`BadgesUser-${user.id}`}
    //       className="flex flex-col space-y-2 p-4 transition-all duration-150"
    //     >
    //       <Button
    //         onClick={(e) => {
    //           //Add ping class to element
    //           const elem = e.currentTarget;
    //           elem.classList.toggle("animate-ping");
    //           router.prefetch("/admin/user/[id]", `/admin/user/${user.id}`);
    //           setTimeout(() => {

    //             elem.classList.toggle("animate-ping");
    //             elem.classList.toggle("opacity-0");
    //             router.push("/admin/user/[id]", `/admin/user/${user.id}`);
    //           }, 600);
    //         }}
    //         className="block p-1 rounded-full"
    //       >
    //         <Avatar
    //           src={user.avatar_url || "/images/no_avatar.png"}
    //           alt={user.firstname + " " + user.lastname}
    //           width={60}
    //           height={60}
    //         />
    //       </Button>
    //       <p className="text-xs text-center text-indigo-600">
    //         {user.firstname} {user.lastname}
    //       </p>
    //     </li>
    //   ))}
    // </ul>