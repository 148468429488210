import React, { MouseEventHandler } from "react";
import { THEME_FORM_RADIO } from "@scanow/theme";
import { Label } from "@scanow/ui";
import { FieldErrorsImpl, UseFormRegisterReturn } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import Image from "next/image";

interface Options {
  value: string;
  name: string;
  image_url?: string;
}

export interface RadioFieldProps {
  label?: string;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLInputElement>;
  disabled?: boolean;
  hidden?: boolean;
  options: Options[];
  register?: UseFormRegisterReturn;
  setState?: Function;
  errors?: FieldErrorsImpl<any>;
  state?: any;
  name?: string;
  appendClassName?: string;
}

export function RadioField({
  label,
  className,
  errors,
  disabled,
  hidden,
  onClick,
  options,
  register,
  name,
  setState,
  state,
  appendClassName,
}: RadioFieldProps) {
  function defaultOnClick(e: any): void {
    if (setState && state) {
      setState({
        ...state,
        [name || ""]: e.target.value,
      });
    }
  }

  return (
    <fieldset className="my-5">
      <Label htmlFor={register?.name}>{label}</Label>
      {options.map((option) => (
        <React.Fragment key={register?.name + "_" + option.value}>
          {register ? (
            <input
              onClick={onClick}
              className={className || THEME_FORM_RADIO + " " + appendClassName}
              type="radio"
              id={register?.name + "_" + option.value}
              {...register}
              value={option.value}
              disabled={disabled}
              hidden={hidden}
            />
          ) : (
            <input
              onClick={onClick || defaultOnClick}
              className={className || THEME_FORM_RADIO + " " + appendClassName}
              type="radio"
              id={name + "_" + option.value}
              value={option.value}
              disabled={disabled}
              hidden={hidden}
              checked={state[name || ""] == option.value}
            />
          )}

          <label
            //className={THEME_FORM_OPTION}
            htmlFor={register?.name || name + "_" + option.value}
          >
            {option.image_url && (
              <Image src={option.image_url} width={100} height={100} alt="" />
            )}

            {option.name}
          </label>
          <br />
        </React.Fragment>
      ))}

      {register && errors && (
        <ErrorMessage
          errors={errors}
          name={register?.name}
          render={({ message }) => (
            <small className="absolute text-xs py-1 text-danger-500 w-full h-12 block ">
              {message}
            </small>
          )}
        />
      )}
    </fieldset>
  );
}
