import { Loading } from "@scanow/ui";
import Link from "next/link";
import React from "react";
import { classNames } from "@scanow/helpers";
import { 
  THEME_BUTTON, 
  THEME_BUTTON_PRIMARY,
  THEME_BUTTON_SECONDARY, 
  THEME_BUTTON_TERTIARY,
  THEME_BUTTON_NEUTRAL,
  THEME_BUTTON_INFO,
  THEME_BUTTON_WARNING,
  THEME_BUTTON_SUCCESS,
  THEME_BUTTON_DANGER,
  THEME_BUTTON_LIGHT
} from "@scanow/theme";

/* Utilisation
  <Button.Primary> Primary </Button.Primary>
  <Button.Secondary> Secondary </Button.Secondary>
  <Button.Danger> Tertiary </Button.Danger>
  <Button.Primary href="/">PrimaryLink</Button.Primary>

  // Mode Manuel 
  <Button appendClassName="bg-red-200" >Manu</Button>

  // Mode semi manuel /
  <Button.Variant color="Primary" size="Medium">Semi manuel</Button.Variant>
  <Button.Variant color="Secondary">Semi manuel</Button.Variant>

  // Exemples Button Variant
  <Button.Primary> Primary </Button.Primary>
  <Button.Variant color="Default" size="Medium">Default</Button.Variant>
  <Button.Variant color="Light" size="Medium" shape="Full">L</Button.Variant>
  <Button.Variant color="Primary" size="Large">Primary</Button.Variant>
  <Button.Variant color="Secondary" size="Small">Secondary</Button.Variant>
  <Button.Variant color="Tertiary" size="Medium" >Tertiary</Button.Variant>
  <Button.Variant color="Info" size="Medium">Info</Button.Variant>
  <Button.Variant color="Success" size="Medium">Success</Button.Variant>
  <Button.Variant color="Warning" size="Medium">Warning</Button.Variant>
  <Button.Variant color="Danger" size="Medium" style="Wide" shape="Full">Danger</Button.Variant>
          
*/

interface ButtonBaseProps {
  appendClassName?: string;
  children: React.ReactNode;
  href?: string;
  title?: string;
  type?: "button" | "submit" | "reset";
}

interface LinkButtonProps extends ButtonBaseProps {
  href: string;
  targetBlank?: boolean;
  onClick: never;
  type?: never;
}

interface ButtonProps extends ButtonBaseProps {
  href?: never;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  type?: "button" | "submit" | "reset";
  loading?: boolean;
  disabled?: boolean;
}

export function Button(props: LinkButtonProps | ButtonProps): JSX.Element {
  const BUTTON_CLASS = classNames("",
   props.appendClassName,
    //focusClass(true) à voir
  );
  if (!props.href) {
    return (
      <ClassicButton {...(props as ButtonProps)} className={BUTTON_CLASS } />
    );
  }
  return (
    <LinkButton {...(props as LinkButtonProps)} className={BUTTON_CLASS} />
  );
}

// Variants

const styles = {
 //Default: "w-full md:w-64", // Taille par défault
 Default: "w-full", 
  Circle: "w-9 h-9",
  Wide: "w-full", 
};

const shapes = {
  Default: "rounded-lg", // Shape par défault
  Square:"rounded-none",
  Small: "rounded-sm",
  Medium: "rounded-lg",
  Full: "rounded-full",
};

const sizes = {
  Default: THEME_BUTTON,  //"p-2",
  Small: "px-2 py-1 text-xs",
  Medium: "px-3 py-2 text-base font-medium",
  Large: "px-4 py-3 text-lg font-semibold",
};

const colors = {
 Default: THEME_BUTTON_NEUTRAL,
 // Default:"bg-neutral-100 hover:bg-neutral-200 text-neutral-300 border border-neutral-300 dark:bg-neutral-800 dark:text-neutral-700 dark:border-neutral-700",
  Light:THEME_BUTTON_LIGHT,
  Primary: THEME_BUTTON_PRIMARY,
  Secondary: THEME_BUTTON_SECONDARY,
  Tertiary: THEME_BUTTON_TERTIARY,
  Info: THEME_BUTTON_INFO,
  Warning: THEME_BUTTON_WARNING,
  Success: THEME_BUTTON_SUCCESS,
  Danger: THEME_BUTTON_DANGER,
};

Button.Default = function DefaultButton(
  props: Omit<LinkButtonProps | ButtonProps, "appendClassName">&{
    styles?: keyof typeof styles;
  }
) {
  return (
    <Button
      {...(props as LinkButtonProps | ButtonProps)}
      appendClassName={classNames(
        colors.Default,
        //shapes.Default,
        sizes.Default,
        styles.Default
      )}
    />
  );
};

Button.Light = function LightButton(
  props: Omit<LinkButtonProps | ButtonProps, "appendClassName">&{
    styles?: keyof typeof styles;
  }
) {
  return (
    <Button
      {...(props as LinkButtonProps | ButtonProps)}
      appendClassName={classNames(
        colors.Light,
        shapes.Default,
        sizes.Default,
        props.styles? styles[props.styles] : styles.Default
      )}
    />
  );
};

Button.Primary = function PrimaryButton(
  props: Omit<LinkButtonProps | ButtonProps, "appendClassName">&{
    styles?: keyof typeof styles;
  }
) {
  return (
    <Button
      {...(props as LinkButtonProps | ButtonProps)}
      appendClassName={classNames(
        colors.Primary,
        shapes.Default,
        sizes.Default,
        props.styles? styles[props.styles] : styles.Default
      )}
    />
  );
};

Button.Secondary = function SecondaryButton(
  props: Omit<LinkButtonProps | ButtonProps, "appendClassName">&{
    styles?: keyof typeof styles;
  }
) {
  return (
    <Button
      {...(props as LinkButtonProps | ButtonProps)}
      appendClassName={classNames(
        colors.Secondary,
        shapes.Default,
        sizes.Default,
        props.styles? styles[props.styles] : styles.Default
      )}
    />
  );
};

Button.Tertiary = function TertiaryButton(
  props: Omit<LinkButtonProps | ButtonProps, "appendClassName">&{
    styles?: keyof typeof styles;
  }
) {
  return (
    <Button
      {...(props as LinkButtonProps | ButtonProps)}
      appendClassName={classNames(
        colors.Tertiary,
        shapes.Default,
        sizes.Default,
        props.styles? styles[props.styles] : styles.Default
      )}
    />
  );
};

Button.Info = function InfoButton(
  props: Omit<LinkButtonProps | ButtonProps, "appendClassName">&{
    styles?: keyof typeof styles;
  }
) {
  return (
    <Button
      {...(props as LinkButtonProps | ButtonProps)}
      appendClassName={classNames(
        colors.Info,
        shapes.Default,
        sizes.Default,
        props.styles? styles[props.styles] : styles.Default
      )}
    />
  );
};

Button.Warning = function WarningButton(
  props: Omit<LinkButtonProps | ButtonProps, "appendClassName">&{
    styles?: keyof typeof styles;
  }
) {
  return (
    <Button
      {...(props as LinkButtonProps | ButtonProps)}
      appendClassName={classNames(
        colors.Warning,
        shapes.Default,
        sizes.Default,
        props.styles? styles[props.styles] : styles.Default
      )}
    />
  );
};

Button.Success = function SuccessButton(
  props: Omit<LinkButtonProps | ButtonProps, "appendClassName">&{
    styles?: keyof typeof styles;
  }
) {
  return (
    <Button
      {...(props as LinkButtonProps | ButtonProps)}
      appendClassName={classNames(
        colors.Success,
        shapes.Default,
        sizes.Default,
        props.styles? styles[props.styles] : styles.Default
      )}
    />
  );
};

Button.Danger = function DangerButton(
  props: Omit<LinkButtonProps | ButtonProps, "appendClassName">&{
    styles?: keyof typeof styles;
  }
) {
  return (
    <Button
      {...(props as LinkButtonProps | ButtonProps)}
      appendClassName={classNames(
        colors.Danger,
        shapes.Default,
        sizes.Default,
        props.styles? styles[props.styles] : styles.Default
      )}
    />
  );
};

// Prends toutes les size et toutes les colors définit plus haut
Button.Variant = function VariantButton(
  props: Omit<LinkButtonProps | ButtonProps, "appendClassName"> & {
    color?: keyof typeof colors;
    //size?: keyof typeof sizes;
    style?: keyof typeof styles;
    shape?: keyof typeof shapes;
  }
) {
  return (
    <Button
      {...(props as LinkButtonProps | ButtonProps)}
      appendClassName={classNames(
        props.color ? colors[props.color] : colors.Default,
        //props.size ? sizes[props.size] : sizes.Default,
        props.style ? styles[props.style] : styles.Default,
        props.shape ? shapes[props.shape] : shapes.Default
      )}
    />
  );
};

Button.Circle = function CircleButton(
  props: Omit<LinkButtonProps | ButtonProps, "appendClassName">
) {
  return (
    <Button
      {...(props as LinkButtonProps | ButtonProps)}
      appendClassName={classNames(
        shapes["Full"],
        styles["Circle"],
        colors["Default"],
        //sizes["Small"]
      )}
    />
  );
};


function LinkButton({
  href,
  title,
  targetBlank,
  children,
  className,
}: LinkButtonProps & { className: string }) {
  return (
    <Link
      href={href}
      target={targetBlank ? "_blank" : undefined}
      title={title}
      rel="noopener noreferrer"
    >
      <a className={className}>{children || `LinkButton`}</a>
    </Link>
  );
}

function ClassicButton({
  onClick,
  type = "button",
  title,
  loading,
  disabled,
  children,
  className,
}: ButtonProps & { className: string }) {
  return (
    <button
      disabled={disabled || loading}
      className={className}
      onClick={onClick}
      title={title}
      type={type}
    >
      {loading && <Loading />}
      {children || `Button`}
    </button>
  );
}