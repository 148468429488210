import React, { useEffect, useState } from "react";
import { HiOutlineMoon, HiOutlineSun } from "react-icons/hi";
import {Button} from "./Button";


export interface IButtonSwitchTheme {
  className?: string;
}
export const ButtonSwitchTheme = ({ className = "" }: IButtonSwitchTheme) => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    if (
      localStorage.theme === "dark" ||
      (!("theme" in localStorage) &&
        window.matchMedia("(prefers-color-scheme: dark)").matches)
    ) {
      toDark();
    } else {
      toLight();
    }
  }, []);

  const toDark = () => {
    setIsDarkMode(true);
    const root = document.querySelector("html");
    if (!root) return;
    !root.classList.contains("dark") && root.classList.add("dark");
    localStorage.theme = "dark";
  };

  const toLight = () => {
    setIsDarkMode(false);
    const root = document.querySelector("html");
    if (!root) return;
    root.classList.remove("dark");
    localStorage.theme = "light";
  };

  function _toogleDarkMode() {
    if (localStorage.theme === "light") {
      toDark();
    } else {
      toLight();
    }
  }

  return (
    <Button.Circle
      onClick={_toogleDarkMode}
    >
      <span className="sr-only">Switch theme</span>
      {isDarkMode ? (
        <>
          <HiOutlineSun title="Passer en mode clair" />
        </>
      ) : (
        <>          
          <HiOutlineMoon title="Passer en mode sombre"/>
        </>
      )}
    </Button.Circle>
  );
};
