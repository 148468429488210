import userAuth from "@/helpers/auth";
import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject
} from "@apollo/client";
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { captureException } from "@sentry/nextjs";
import merge from "deepmerge";
import { parseCookies } from "helpers/cookies";
import type { IncomingMessage } from "http";
import type { GetServerSidePropsContext } from "next";
import { useMemo } from "react";

interface PageProps {
  props?: Record<string, any>;
}

export const APOLLO_STATE_PROPERTY_NAME = "__APOLLO_STATE__";

const handleError = () => {
  return onError((err) => {
    if (err.graphQLErrors) {

      err.graphQLErrors.forEach(gqlErr => {
        const erro = new Error()
        erro.name = `Apollo-GraphQLError: ${gqlErr.extensions.code}`
        erro.message = gqlErr.message
        if (gqlErr.stack) {
          erro.stack = gqlErr.stack
        }
        captureException(erro)
      });

    }
    if (err.networkError) {
      captureException(err.networkError)
    }

  })
}

const getToken = (req?: IncomingMessage) => {
  const parsedCookie = parseCookies(req)
  return parsedCookie.accessToken;
};

// let apolloClient: ApolloClient<NormalizedCacheObject> = null;
// TODO : Erreur lors de la compilation avec le type ci dessus
let apolloClient: any = null;

const typenameMiddleware = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    operation.variables = JSON.parse(JSON.stringify(operation.variables), (key, value) => {
      if (key === '__typename') {
        return undefined;
      }
      return value;
    });
  }
  return forward(operation)
});

const createApolloClient = (ctx?: GetServerSidePropsContext) => {
  const httpLink = new HttpLink({
    uri: process.env.NEXT_PUBLIC_API_URL,
    credentials: "same-origin",
  });



  const authLink = setContext((_, { headers }) => {
    // Get the authentication token from cookies
    const auth = userAuth()
    const token = getToken(ctx?.req);
    let AuthHeader = token && {
      Authorization: token,
      'x-hasura-role': auth.role_header
    }
    try {
      if (!auth.isLogged) {
        //@ts-ignore
        delete AuthHeader['x-hasura-role']
        //@ts-ignore
        delete AuthHeader['Authorization']
      }
    } catch { }



    return {
      headers: {
        ...headers,
        // "x-hasura-admin-secret": process.env.HASURA_SECRET || "Scanow2000", // a delete plus tard (seulement phase de dev !!)
        ...AuthHeader
      },
    };
  });

  const errorLink = handleError()


  return new ApolloClient({
    ssrMode: typeof window === "undefined",
    link: ApolloLink.from([errorLink, typenameMiddleware, authLink.concat(httpLink)]),
    cache: new InMemoryCache({
      addTypename: true
    }),
  });
};

export function initializeApollo(initialState = null, ctx: any = null) {
  const client = apolloClient ?? createApolloClient(ctx);


  // If your page has Next.js data fetching methods that use Apollo Client,
  // the initial state gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = client.extract();

    // Merge the existing cache into data passed from
    // getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache);

    // Restore the cache with the merged data
    client.cache.restore(data);
  }

  // For SSG and SSR always create a new Apollo Client
  if (typeof window === "undefined") {
    return client;
  }

  // Create the Apollo Client once in the client
  if (!apolloClient) {
    apolloClient = client;
  }


  return client;
}

export function addApolloState(
  client: ApolloClient<NormalizedCacheObject>,
  pageProps: PageProps
) {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROPERTY_NAME] = client.cache.extract();
  }

  return pageProps;
}

export function useApollo(pageProps: PageProps) {
  // @ts-ignore
  const state = pageProps[APOLLO_STATE_PROPERTY_NAME];
  const store = useMemo(() => initializeApollo(state), [state]);

  return store;
}
