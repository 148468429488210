import {
  Button,
  InputField,
  SelectField,
  SubmitButton,
  Text,
  TextAreaField,
  Paragraph
} from "@scanow/ui";
import React from "react";
import { gql, TypedDocumentNode, useMutation } from "@apollo/client";
import {
  FieldErrors,
  FieldErrorsImpl,
  UseFormRegisterReturn,
  useForm,
} from "react-hook-form";

interface FormData {
  firstName: string;
  lastName: string;
  email: string;
  application: string
  subject: string;
  message: string;
}

interface Props {}

export function RGPDForm({}: Props): JSX.Element {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();
  const [contactSubmitMutation] = useMutation(CONTACT_MUTATION);
  const [submitted, setSubmitted] = React.useState<"no"|"success"|"error">("no");
  const onSubmit = (data: FormData) => {
    const variables = {
      from: data.email,
      subject: data.subject,
      content:
          `<Paragraph>
        <Text.Normal>Prénom: ${data.firstName}</Text.Normal> <br/>
        <Text.Normal>Nom: ${data.lastName}</Text.Normal> <br/>
        <Text.Normal>Logiciel: ${data.application}</Text.Normal> <br/>
        <Text.Normal>Message: ${data.message}</Text.Normal> </Paragraph>
      `,
      to: "rgpd@artdigit.fr",
    };
    if (submitted != "no") {
      return;
    }
    contactSubmitMutation({
      variables: {
        data: variables,
      },
    }).then(()=>{
      setSubmitted("success")
    }).catch(()=>{
      setSubmitted("error")
    })
  };
  if (submitted == "success") {
    return <Paragraph>
      <Text.Subtitle>Votre demande a été prise en compte !</Text.Subtitle>
      <Text.Normal>
        Vous recevrez un retour par email sous 72h. <br/>
        Dans le cas contraire, merci de nous contacter à l'adresse mail suivante: rgpd@artdigit.fr
      </Text.Normal>
      </Paragraph>;
  }
  if (submitted == "error") {
    return <Paragraph>
      <Text.Subtitle>Erreur lors de la tentative d'envoi du formulaire !</Text.Subtitle>
      <Text.Normal>
        Veuillez nous contacter à l'adresse mail suivante: rgpd@artdigit.fr
      </Text.Normal>
      </Paragraph>;
      }
  
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <InputField
        label="Prénom"
        register={register("firstName", { required: "Ce champ est requis !" })}
        errors={errors}
      />
      <InputField
        label="Nom"
        register={register("lastName", { required: "Ce champ est requis !" })}
        errors={errors}
      />
      <InputField
        label="Email"
        register={register("email", { required: "Ce champ est requis !" })}
        errors={errors}
      />
      <SelectField
        label="Logiciel"
        register={register("application", { required: "Ce champ est requis !" })}
        errors={errors}
        options={[
          { value: "scanow-helpdesk", name: "Scanow Helpdesk" },
          { value: "scanow-inventory", name: "Scanow Inventory" },
          { value: "scanow-asset", name: "Scanow Asset Management" },
          { value: "noApp", name: "Aucune/Autre" },
        ]}
      />        
      <SelectField
        label="Sujet"
        register={register("subject", { required: "Ce champ est requis !" })}
        errors={errors}
        options={[
          {
            value: "Demande de consultation",
            name: "Demande de consultation",
          },
          {
            value: "Demande de suppression",
            name: "Demande de suppression",
          },
          {
            value: "Demande de modification",
            name: "Demande de modification",
          },
          {
            value: "Autre",
            name: "Autre",
          },
        ]}
      />
      <TextAreaField
        label="Message"
        register={register("message", { required: "Ce champ est requis !" })}
        errors={errors}
      />
      <SubmitButton value="Envoyer" />
      {/* <Button.Primary type="submit">Submit</Button.Primary> Migrer UIKIT pour utiliser ce bouton */}
    </form>
      
  );
}

interface ContactMutationVariables {
  data: {
    from: string;
    subject: string;
    content: string;
    to: string;
  };
}
interface ContactMutationOutput {
  done: boolean;
}

export const CONTACT_MUTATION: TypedDocumentNode<
  ContactMutationOutput,
  ContactMutationVariables
> = gql`
  mutation ContactMutation($data: ContactInput!) {
    contact(data: $data) {
      done
    }
  }
`;