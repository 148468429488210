import { ApolloProvider } from "@apollo/client";
import { useApollo } from "config/apollo";
import { appWithTranslation } from "next-i18next";
import { AppProps } from "next/app";
import Router from 'next/router';
import nProgress from "nprogress";
import { CookiesProvider } from "react-cookie";
import { ToastContainer } from "react-toastify";
import { Provider } from "react-redux";
import { store } from "../stores/index";
import { PageTransition } from "@scanow/ui";

/* Styles */
import { AuthProvider } from "@/helpers/contexts/auth";
//import "@/styles/documentation.scss";
//import "@/styles/main.scss";
import "@/styles/index.scss";
//import "@/styles/progressBar.scss";
import "react-toastify/dist/ReactToastify.css";

/* ProgressBar */
Router.events.on("routeChangeStart", nProgress.start);
Router.events.on("routeChangeError", nProgress.done);
Router.events.on("routeChangeComplete", nProgress.done);

function App({ Component, pageProps }: AppProps) {
  const apolloClient = useApollo(pageProps);

  return (
    <ApolloProvider client={apolloClient}>
      <Provider store={store}>
        <CookiesProvider>
          <AuthProvider>
            {/* <PageTransition> TODO: A fix, le routeur fait n'importe quoi, lors du changement de page, il décharge les props mais tente de refaire un rendu de la pge
              précédente, ce qui fait que les props sont undefined + effectue environ 4 rendu complet de la page pour juste l'animation, donc très gourmand en ressources*/}
              {/* @ts-ignore */}
              <Component {...pageProps} />
            {/* </PageTransition> */}
            <ToastContainer
              position="top-right"
              autoClose={8000}
              hideProgressBar={false}
              newestOnTop={false}
              draggable={false}
              closeOnClick
              pauseOnHover
            />
          </AuthProvider>
        </CookiesProvider>
      </Provider>
    </ApolloProvider>
  );
}

export default appWithTranslation(App);
