import React from "react";

export interface BackgroundProps {
  className?: string;
}

export const Background = ({
  className = "bg-neutral-100/80 dark:bg-black/20 ",
}:BackgroundProps) => {
  return (
    <div
      className={`Background absolute inset-y-0 w-screen xl:max-w-[1340px] 2xl:max-w-screen-2xl left-1/2 transform -translate-x-1/2 xl:rounded-[40px] z-0 ${className}`}
      data-sw-id="Background"
    ></div>
  );
};

