import { useQuery } from "@apollo/client";
import { Loading, ErrorMessage } from "@scanow/ui"
import React from "react";
import { TeamUpdateForm } from "../Form";
import { ITeamLite } from "../Interfaces";
import { GET_UPDATED_TEAM } from "../Queries";

interface TeamProps {
  id: number
  cb_success?: any
  cb_error?: any
}

interface TeamData {
  team: ITeamLite
}

export function TeamUpdateWrapper({ id, ...props }: TeamProps): JSX.Element {
  const { data, loading, error } = useQuery<TeamData>(GET_UPDATED_TEAM, { variables: { id: id } })
  if (loading) {
    return (<Loading />)
  }
  if (error) {
    console.error(error)
    return (<ErrorMessage message="Une erreur inconnue s'est produite"/>)
  }
  if (data) {

    return <TeamUpdateForm team={data.team} {...props} />;
  }
  return (<ErrorMessage message="Une erreur inconnue est survenue. Code: 9999"/>)
};