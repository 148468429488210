import { Button } from "./Default";
import { IoLogInOutline } from "react-icons/io5";
import React from "react";

export interface SigninButtonProps {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export function SigninButton({ onClick }: SigninButtonProps) {


  return (
    <Button
      onClick={onClick}
      className={"p-2 bg-black rounded-full text-white"}
    >
      Signin
    </Button>
  );
}
