import { Button, Modal, Loading, ErrorMessage  } from "@scanow/ui";
import { useQuery } from "@apollo/client";
import { useState } from "react";
import { RiSettings4Line } from "react-icons/ri";
import { IUserLite } from "../../user/Interfaces";
import { GET_COMPANY_DEFAULT_TEAM } from "../Queries";
import React from "react";
import { TeamDetailWrapper } from "../Wrapper";
import { TeamUpdateWrapper } from "../Wrapper";
import { TeamUserList } from "./UserList";

interface Props {
  company_id: string;
  role: number
}

interface ApolloData {
  company: {
    name: string;
    defaut_team_id?: number;
    default_team?: {
      name: string
      users: IUserLite[]
    }
  };
}

export function TeamSettings({ company_id, role }: Props): JSX.Element {
  const { data, loading, error } = useQuery<ApolloData>(
    GET_COMPANY_DEFAULT_TEAM,
    {
      variables: { company_id },
    }
  );
  const [updateModal, setUpdateModal] = useState<boolean>(false);
  if (loading) {
    return (<Loading />)
  }
  if (error) {
    console.error(error)
    return (<ErrorMessage message="Une erreur inconnue s'est produite"/>)
  }
  if (data) {
    if (data.company.defaut_team_id && data.company.default_team) {
      return (
        <div className="flex flex-col items-center justify-center h-full">
          <div className="mb-4">
            <TeamDetailWrapper id={data.company.defaut_team_id} />
          </div>
          {/* <TeamUserList team={data.company.default_team} company={data.company} /> */}
          {role <= 2 &&
            <Button appendClassName="w-9 h-9 bg-cyan-100 rounded-full">
              <RiSettings4Line
                onClick={() => setUpdateModal(true)}
                title="Modifier les paramètres de l'Équipe"
                className="text-cyan-600 w-5 h-5"
              />
            </Button>}

          <Modal
            isOpen={updateModal}
            setState={setUpdateModal}
            title="Modifier les paramètres de l'équipe"
          >
            <TeamUpdateWrapper id={data.company.defaut_team_id} />
          </Modal>
        </div>
      );
    } else {
      return <p>Aucun résultat.</p>
    }
  }
  return (<ErrorMessage message="Une erreur inconnue est survenue. Code: 9999"/>)
}