import { useQuery } from "@apollo/client";
import { Text, Loading } from "@scanow/ui";
import React from "react";
import { GET_USER_HELPDESK_STATS, GET_USER_INVENTORY_STATS } from "../../Queries";




export function UserInventoryStats({ user_id }: { user_id: string }) {

  const { data, loading, error } = useQuery(GET_USER_INVENTORY_STATS, {
    variables: {
      id: user_id,
    },
  });

  if (loading) return <Loading />;
  if (error || !data) return <div>Error..</div>
  const user = data.user
  return(
    <div className="">

    
    <ul className="text-sm flex flex-wrap justify-center items-center gap-2 my-3">
        <li className="text-center mx-4">
          <Text.Medium appendClassName="text-indigo-600">
            {user.checkings_aggregate.aggregate.count}
          </Text.Medium>
          <Text.Small >Mouvements effectués</Text.Small>
        </li>
        <li className="text-center mx-4">
          <Text.Medium appendClassName="text-indigo-600">
            {user.items_aggregate.aggregate.count}
          </Text.Medium>
          <Text.Small > Modèles d'équipements créés</Text.Small>
        </li>
      </ul>
      </div>
  )

}