import { classNames } from "@scanow/helpers";
import React from "react";

interface Props{
  classNames: string;
  children: React.ReactNode;
  onClick?: (e:any) => void;
}

export function Tag({ classNames, children, onClick }: Props) {
  return (
    <div className={classNames} onClick={onClick}>
      {children}
    </div>
  );
}


const colors = new Map ([
  ["Purple", "bg-purple-200 text-purple-600 border-purple-600"],
  ["Blue", "bg-blue-200 text-blue-600 border-blue-600"],
  ["Green", "bg-green-200 text-green-600 border-green-600"],
  ["Yellow", "bg-yellow-200 text-yellow-600 border-yellow-600"],
  ["Orange", "bg-orange-200 text-orange-600 border-orange-600"],
  ["Red", "bg-red-200 text-red-600 border-red-600"],
])


const BASE_CLASS = "px-2 py-1 rounded-full text-sm font-medium border flex-shrink-0 hover:shadow-md transition-all"

Tag.Purple = function ({ children, ...props }: Omit<Props,"classNames">) {
  return <Tag {...props} classNames={classNames(BASE_CLASS, colors.get("Purple"))}>{children}</Tag>
}

Tag.Blue = function ({ children, ...props }: Omit<Props,"classNames">) {
  return <Tag  {...props} classNames={classNames(BASE_CLASS, colors.get("Blue"))}>{children}</Tag>
}

Tag.Green = function ({ children, ...props }: Omit<Props,"classNames">) {
  return <Tag {...props} classNames={classNames(BASE_CLASS, colors.get("Green"))}>{children}</Tag>
}

Tag.Yellow = function ({ children, ...props }: Omit<Props,"classNames">) {
  return <Tag {...props} classNames={classNames(BASE_CLASS, colors.get("Yellow"))}>{children}</Tag>
}

Tag.Orange = function ({ children, ...props }: Omit<Props,"classNames">) {
  return <Tag {...props} classNames={classNames(BASE_CLASS, colors.get("Orange"))}>{children}</Tag>
}

Tag.Red = function ({ children, ...props }: Omit<Props,"classNames">) {
  return <Tag {...props} classNames={classNames(BASE_CLASS, colors.get("Red"))}>{children}</Tag>
}

Tag.Random = function ({ children, ...props }: Omit<Props,"classNames">) {
  const randomColor = colors.get(Array.from(colors.keys())[Math.floor(Math.random() * colors.size)]);
  return <Tag {...props} classNames={classNames(BASE_CLASS, randomColor)}>{children}</Tag>
}

interface VariantProps{
  onClick?: (e:any) => void;
  children: React.ReactNode;
  textColor: string;
  backgroundColor: string;
}
export function TagManualColors({ children, ...props  }: VariantProps) {
  return (
    <div
      className={BASE_CLASS}
      style={{
        color: props.textColor,
        backgroundColor: props.backgroundColor,
        borderColor: props.textColor,
      }}
      onClick={props.onClick}
      >
      {children}
    </div>
  )
}