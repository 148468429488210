import { useMutation } from "@apollo/client";
import { capitalize, lowercase, REGEX_EMAIL_PATTERN, uppercase, userAuthReturn } from "@scanow/helpers";
import {Text, Button, ImageField, InputField, Modal, SubmitButton, CheckboxField} from "@scanow/ui";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { UPLOAD_FILE } from "../../fileManager";
import { GET_SETTINGS_DATA } from "../../settings/Queries";
import { TeamSelectorField } from "../../team";
import { GET_ALL_USERS, GET_ALL_USERS_NAME, GET_UPDATE_USER, GET_USER_SETTINGS, UPDATE_USER } from "../Queries";
import { UserRoleAttributionWrapper } from "../Wrapper/UserRoleAttribution";
import { RiAlertFill } from "react-icons/ri";
import { fileManagerUploadFile } from '@scanow/helpers';
import { toast } from 'react-toastify';
import {AxiosProgressEvent} from "axios";

interface UserData {
  id: string;
  company_id: string;
  firstname: string;
  lastname: string;
  email: string;
  avatar_url?: string;
  team_id: string;
  password: string;
  mail_notify_on_incident: boolean;
}

interface Props {
  user: UserData;
  cb_success?: any;
  cb_error?: any;
  role: number
  auth:userAuthReturn
  application: "scanow" | "sonar";
}

export function UserUpdateForm({ user, cb_error, cb_success, role, auth, application }: Props): JSX.Element {
  const router = useRouter();

  const [attributionModalOpen, setAttributionModalOpen] = useState<boolean>(false);

  const { register, handleSubmit, watch, setValue, getValues, unregister, setError, reset, formState: { errors } } = useForm({
    defaultValues: user
  })
  const [updateUser] = useMutation(UPDATE_USER, {
    refetchQueries: 'all',
  });

  const avatar_url_watcher = watch("avatar_url")

  useEffect(() => {
    reset()
  }, [])


  function submitHandler(data: any) {
    data.firstname = capitalize(data.firstname)
    data.lastname = uppercase(data.lastname)
    data.email = lowercase(data.email)
    if (role != 1) {
      delete data.company_id
    }
    if (role > 2) {
      delete data.team_id
    }
    delete data.id
    updateUser({
      variables: {
        id: user.id,
        _set: data,
      },
    })
      .then((data) => {
        // en cas de succès de la requète
        // Verifier si une fonction callback est dispo
        if (cb_success) {
          cb_success(data.data.user);
        } else {
          //Sinon executer fonction par défaut
          router.reload();
        }
      })
      .catch((err) => {
        console.error(err)
        // en cas de d'erreur de la requète
        // Verifier si une fonction callback est dispo
        if (cb_error) {
          cb_error(err);
        } else {
          //Sinon executer fonction par défaut
          console.error(err);
        }
      });
  }

  async function fileUpload(file: File, onProgressUpdate: (e: AxiosProgressEvent)=>void): Promise<void> {
    const uploadedFile = await fileManagerUploadFile(file, auth!.accessToken as string, onProgressUpdate);
    setValue('avatar_url', uploadedFile.full_url);
  }

  function onProgress(progress: AxiosProgressEvent) {
    console.log("progressUpdate",progress)
  }

  function onFileChange(file: File) {
    toast.promise(fileUpload(file, onProgress), {
        pending: 'Téléchargement du fichier',
        success: 'Fichier téléchargé avec succès',
        error: 'Erreur lors du téléchargement du fichier',
      },
    );
  }

  return (
    <>
      <form onSubmit={handleSubmit(submitHandler)}>
              {role <= 2 &&
        <>
        <Text.Medium appendClassName="flex items-center w-full p-4 bg-secondary-200 text-secondary-800 dark:text-secondary-200">
              <RiAlertFill size={22} className="text-warning-500" /> <Button onClick={e => {
            e.preventDefault()
            setAttributionModalOpen(true)
          }}
          appendClassName="mx-2 font-semibold hover:text-warning-500"
          >Cliquez-ici </Button> pour modifier les rôles de cet utilisateur.
              </Text.Medium>
          

        </>
      }
        <InputField errors={errors} label={"Prénom"} register={register("firstname", { required: "Ce champ est requis !" })} />
        <InputField errors={errors} label={"Nom"} register={register("lastname", { required: "Ce champ est requis !" })} />
        <InputField errors={errors} label={"Email"} register={register("email", {
          required: "Ce champ est requis !",
          pattern: {
            value: REGEX_EMAIL_PATTERN,
            message: "L'adresse email n'est pas valide !"
          }
        })} />
        {role <= 2 &&
          <TeamSelectorField label={"Équipe"} errors={errors} register={register("team_id",
            {
              required: "Ce champ est requis !",
              validate: (value) => {
                if (value && value != "null") {
                  return true
                }
                return "Ce champ est requis !"
              }
            })} />
        }

        <ImageField
          register={register('avatar_url', { required: false })}
          onFileChange={onFileChange}
          setError={setError}
          errors={errors}
          value={avatar_url_watcher}
          label={'Image'}
          onReset={() => {
            //@ts-ignore
            setValue('avatar_url', null);
          }}
        />

        {application == "scanow" && <CheckboxField label={'Notification par email'} register={register('mail_notify_on_incident')} />}

        <SubmitButton value="Enregistrer" />
      </form>
      {/* <Button onClick={e => {
        e.preventDefault()
        setPasswordModalOpen(true)
      }}>Changer le mot de passe</Button> */}

      {/* <Modal isOpen={passwordModalOpen} setState={setPasswordModalOpen}>
        <UserUpdatePasswordForm user_id={user.id} old_password={user.password} />
      </Modal> */}
      <Modal isOpen={attributionModalOpen} setState={setAttributionModalOpen} title={`Définir les rôles pour ${user.firstname}`}>
        <UserRoleAttributionWrapper id={user.id} application={application} />
      </Modal>
    </>

  );
};