import { gql } from "@apollo/client";

export const GET_ALL_TEAMS_NAME = gql`
  query allTeams {
    teams: administration_team {
      id
      name
      mail
      phone
      phone_notify_on_incident
      mail_notify_on_incident
      users_aggregate(where: { deleted: { _eq: false } }) {
        aggregate {
          count
        }
      }
    }
  }
`;

export const CREATE_TEAM = gql`
  mutation createTeam(
    $mail: String!
    $mail_notify_on_incident: Boolean!
    $name: String!
    $phone: String
  ) {
    team: insert_administration_team_one(
      object: {
        mail: $mail
        mail_notify_on_incident: $mail_notify_on_incident
        name: $name
        phone: $phone
      }
    ) {
      id
    }
  }
`;

export const DELETE_TEAM = gql`
  mutation deleteTeam($id: Int!) {
    delete_administration_team_by_pk(id: $id) {
      id
    }
  }
`;

export const GET_TEAM_DETAILS = gql`
  query getTeamDetail($id: Int!) {
    team: administration_team_by_pk(id: $id) {
      id
      name
      mail
      phone
      phone_notify_on_incident
      mail_notify_on_incident
      company {
        default_team_id: defaut_team_id
        name
      }
      users(where: { deleted: { _eq: false } }) {
        id
        firstname
        lastname
        avatar_url
        email
        user_role {
          role {
            id
            name
            slug
          }
        }
      }
    }
  }
`;

export const GET_UPDATED_TEAM = gql`
  query getUpdatedTeam($id: Int!) {
    team: administration_team_by_pk(id: $id) {
      id
      name
      mail
      phone
      phone_notify_on_incident
      mail_notify_on_incident
    }
  }
`;

export const UPDATE_TEAM = gql`
  mutation updateTeam($id: Int!, $_set: administration_team_set_input) {
    update_administration_team_by_pk(pk_columns: { id: $id }, _set: $_set) {
      id
    }
  }
`;

export const SETUP_SET_TEAM_DEFAULT = gql`
  mutation setupSetTeamDefault(
    $team_id: Int!
    $company_id: uuid!
    $user_id: uuid!
  ) {
    update_administration_company_by_pk(
      pk_columns: { id: $company_id }
      _set: { defaut_team_id: $team_id }
    ) {
      id
    }
    update_administration_user_by_pk(
      pk_columns: { id: $user_id }
      _set: { team_id: $team_id }
    ) {
      id
    }
  }
`;

export const GET_COMPANY_DEFAULT_TEAM = gql`
  query getCompanyDefaultTeam($company_id: uuid!) {
    company: administration_company_by_pk(id: $company_id) {
      id
      name
      defaut_team_id
      default_team {
        users(where: { deleted: { _eq: false } }) {
          firstname
          lastname
          id
          avatar_url
          user_role {
            role {
              name
              slug
            }
          }
          email
        }
      }
    }
  }
`;
