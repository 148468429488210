import Link from "next/link";
import React from "react";


export interface LocaleButtonProps {
  locale: "fr" | "en";
  router: any; // TODO A mettre le bon type
}

export function LocaleButton({ locale, router }: LocaleButtonProps) {
  // const { t } = useTranslation("common");
  function t(data: string) {
    return data;
  } //TODO Inclure les translations

  return (
    <>
      {router.locale === "fr" ? (
        <Link href={router.asPath} locale={router.locale === "fr" ? "en" : "fr"}>
          <a>
            <img
              src={"/images/flag_fr.png"}
              alt={t("change_locale")}
              title={t("change_locale")}
              className="w-5 h-5" />
          </a>
        </Link>
      ) : (
        <Link href={router.asPath} locale={router.locale === "fr" ? "en" : "fr"}>
          <a>
            <img
              src={"/images/flag_us.png"}
              alt={t("change_locale")}
              title={t("change_locale")}
              className="w-5 h-5" />
          </a>
        </Link>
      )}
    </>
  );
}

