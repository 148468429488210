import React from "react"
import { QRCodeCanvas as ReactQRCodeCanvas, QRCodeSVG } from 'qrcode.react';



export interface QRCodeProps {
  value: string;
  size?: number;
  level?: 'L' | 'M' | 'Q' | 'H';
  bgColor?: string;
  fgColor?: string;
  imageSettings?: {
    src: string;
    excavate: boolean;
    height: number;
    width: number;
  },
  width?: number;
  height?: number;
}

export function QRCode({ value, imageSettings, ...settings }: QRCodeProps) {
  return (
    <QRCodeSVG
      value={value}
      {...settings}
      imageSettings={imageSettings}
      className="w-full h-full"
    />
  )
}

export function QRCodeCanvas({ value, imageSettings, ...settings }: QRCodeProps) {
  return (
    <ReactQRCodeCanvas
      value={value}
      {...settings}
      imageSettings={imageSettings}
      className="w-full h-full"
    />
  )
}