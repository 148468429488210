import React, { useState } from "react";
import { Table } from "@scanow/ui";
import { ActionCell } from "@scanow/ui";
import { ICompanyLight } from "../Interfaces";
import { dateFormat, userAuthReturn } from '@scanow/helpers';
import { Modal } from "@scanow/ui";
import { useMutation } from "@apollo/client";
import {
  DELETE_COMPANY,
  GET_ALL_COMPANIES,
  GET_ALL_COMPANIES_NAME,
} from "../Queries";
import { CompanyUpdateWrapper } from "../Wrapper/Update";
import { CompanyCreateForm } from "../Form/Create";
import { CompanyDetailWrapper } from "../Wrapper/Detail";
import { toast } from "react-toastify";
import { apolloErrorParser } from "@scanow/helpers";
import { RiSettings3Line } from "react-icons/ri";

interface Props {
  companies: Array<ICompanyLight>;
  role: number
  readOnly?: boolean;
  auth: userAuthReturn;
}

export function CompanyList({ companies, role, readOnly, auth }: Props): JSX.Element {

  const [updateModalOpen, setUpdateModalOpen] = useState<boolean>(false);
  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);
  const [detailModalOpen, setDetailModalOpen] = useState<boolean>(false);
  const [selectedCompany, setSelectedCompany] = useState<ICompanyLight>({
    id: "",
    created_at: "",
    name: "",
    slug: "",
  });
  const [deleteCompany] = useMutation(DELETE_COMPANY, {
    refetchQueries: [GET_ALL_COMPANIES, GET_ALL_COMPANIES_NAME],
  });

  const columns = React.useMemo(
    () => [
      {
        Header: "Entreprise",
        accessor: "name",
      },
      {
        Header: "Slug",
        accessor: "slug",
      },
      {
        Header: "Date de création",
        accessor: (row: any) => dateFormat(row.created_at).formatFullDate(),
      },
      {
        //@ts-ignore
        Header: <RiSettings3Line size={22} alt="Action" />,
        accessor: "action",
        disableSortBy: true,
        Cell: (props: any) => {
          function showHandler(event: any) {
            setSelectedCompany(props.cell.row.original);
            setDetailModalOpen(true);
          }

          function updateHandler(event: any) {
            setSelectedCompany(props.cell.row.original);
            setUpdateModalOpen(true);
          }

          function deleteHandler(event: any) {
            deleteCompany({ variables: { id: props.cell.row.original.id } })
              .then((data: any) => {
                toast.success("Entreprise supprimée avec succès !");
              })
              .catch((err) => {
                let parsed_error = apolloErrorParser(err);
                toast.error(
                  "Erreur lors de la tentative de supression de l'entreprise " + "(" + parsed_error.type + ")"
                );
              });
          }

          return ActionCell({
            showHandler: showHandler,
            updateHandler: readOnly ? undefined : updateHandler,
            deleteHandler: readOnly ? undefined : deleteHandler,
            ...props,
          });
        },
      },
    ],
    []
  );

  return (
    <div>
      <Table
        columns={columns}
        data={companies}
        cb_add={readOnly ? undefined :() => {
          setCreateModalOpen(true);
        }}
      />

      {/*Create Modal*/}
      <Modal
        isOpen={createModalOpen}
        setState={setCreateModalOpen}
        //title={t("create")}
        title="Créer une entreprise"
      >
        <CompanyCreateForm
          cb_success={(data: any) => {
            setCreateModalOpen(false);
            toast.success("Entreprise créée avec succès !");
          }}
          cb_error={(err: any) => {
            toast.error("Erreur lors de la tentative de création de l'entreprise ");
          }}
        />
      </Modal>
      {/*Fin Create Modal*/}

      {/*Update Modal*/}
      <Modal
        isOpen={updateModalOpen}
        setState={setUpdateModalOpen}
        title={"Modifier" + " " + selectedCompany.name}
      >
        <>
          {selectedCompany.id != "" && (
            <CompanyUpdateWrapper
              role={role}
              auth={auth}
              id={selectedCompany.id}
              cb_success={(data: any) => {
                setUpdateModalOpen(false);
                toast.success("Entreprise modifiée avec succès !");
              }}
              cb_error={(err: any) => {
                toast.error("Erreur lors de la tentative de modification de l'entreprise ");
              }}
            />
          )}
        </>
      </Modal>
      {/*FIN Update Modal*/}

      {/*Detail Modal*/}
      <Modal
        isOpen={detailModalOpen}
        setState={setDetailModalOpen}
        title={"Entreprise" + " " + selectedCompany.name}
      >
        <>
          {selectedCompany.id != "" && (
            <CompanyDetailWrapper id={selectedCompany.id} />
          )}
        </>
      </Modal>
    </div>
  );
}