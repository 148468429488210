import { useQuery } from "@apollo/client"
import { ICompanySettings } from "../Interfaces"
import { GET_COMPANY_SETTINGS } from "../Queries"
import { RiSettings4Line } from "react-icons/ri"
import Image from "next/image"
import React, { useState } from "react"
import { CompanyUpdateSettings } from "../Form/UpdateSettings"
import { Loading, Button, ErrorMessage, Text , Modal} from "@scanow/ui"
import { userAuthReturn } from '@scanow/helpers';
import {HiOutlineBellAlert} from "react-icons/hi2";

interface Props {
  id: string
  role: number
  auth: userAuthReturn
}

interface ApolloData {
  company: ICompanySettings
}

export function CompanySettings({ id, role, auth }: Props) {
  const [updateModal, setUpdateModal] = useState<boolean>(false)
  const { data, loading, error } = useQuery<ApolloData>(GET_COMPANY_SETTINGS, { variables: { id: id } })



  function cbSuccess() {
    setUpdateModal(false)
  }
  function cbError() {
    setUpdateModal(false)
  }



  if (data) {
    return (
      <>
        <div className="flex flex-col items-center justify-center h-full">

          {data.company.logo_url &&
            <div className="h-24 w-1/2 relative mb-5">
              <Image src={data.company.logo_url} layout="fill" objectFit="contain" alt="companyLogo" />
            </div>
          }

          <Text.Subtitle>{data.company.name}</Text.Subtitle>
          <Text.Normal>Référence: <i>{data.company.slug}</i></Text.Normal>
          {data.company.default_team ? <Text.Medium>Equipe par défaut: {data.company.default_team.name} </Text.Medium>
          : <Text.Medium>Aucune équipe par défaut n'a été choisie</Text.Medium>}
          <Text.Medium>Couleur principale:
            <span className={`inline-block px-2 py-1 ml-1 rounded-full`} style={{
              backgroundColor: data.company.main_color,

            }}>
              <span className="font-bold" style={{
                filter: "invert(100%)"
              }}>
                {data.company.main_color}
              </span>
            </span>
          </Text.Medium>
          {role <= 2 &&

            <Button.Circle onClick={() => setUpdateModal(true)}><RiSettings4Line className="w-5 h-5"  title="Modifier les paramètres de l'Entreprise" /></Button.Circle>
          }
        </div>
        <Modal isOpen={updateModal} setState={setUpdateModal} title={"Paramètres de l'entreprise " + data?.company.name}>
          {data && <CompanyUpdateSettings default_values={data?.company} cb_error={cbError} cb_success={cbSuccess} auth={auth} />}
        </Modal>
      </>
    )
  }
  if (loading) {
    return <Loading />
  }
  if (error) {
    console.error(error)
    return <ErrorMessage message="Une erreur inconnue s'est produite"/>
  }
  return <ErrorMessage message="Une erreur inconnue s'est produite"/>

}