import * as React from 'react';
import { Heading } from '@scanow/ui';

export interface ICard {
  children: React.ReactNode;
  displayName?: string;
  className?: string;
  appendClassName?: string;
  cardTitle?: string;
  props?: JSX.Element;
}

const getChildrenDisplayName = (children: any, displayName: any) =>
  React.Children.map(children, (child) => {
    return child.type.displayName === displayName ? child : null;
  });

export function Card({
                       children,
                       className = '',
                       appendClassName = '',
                       cardTitle = '',
                     }: ICard): JSX.Element {
  const header = getChildrenDisplayName(children, 'Header');
  const body = getChildrenDisplayName(children, 'Body');
  const footer = getChildrenDisplayName(children, 'Footer');
  return (
    <div
      className={
        className || `p-6 rounded-xl ${cardTitle && ''} ` + appendClassName
      }
    >
      {header}
      {body}
      {footer}
    </div>
  );
}

interface IHeader {
  children?: React.ReactNode;
  appendClassName?: string;
  cardTitle?: string;
  props?: any;
  create_button_cb?: () => void;
  update_button_cb?: () => void;
  print_qrcode_button_cb?: () => void;
  setting_button_cb?: () => void;
  list_button_disable?: boolean;
  back_button_disable?: boolean;
  reload_button_cb?: () => void;
}

const Header = ({
                  children,
                  appendClassName = '',
                  cardTitle,
                  ...props
                }: IHeader) => (
  <>
    <header
      className={`my-2 border-b border-neutral-200 dark:border-neutral-800 ${appendClassName}`}
    >
      {cardTitle && <Heading title={cardTitle} {...props} />}<br />
      {children}
    </header>
  </>
);
Header.displayName = 'Header';
Card.Header = Header;

const Body = ({ children, appendClassName = '', ...props }: any) => (
  <div className={`break-words flex-grow ${appendClassName}`} {...props}>
    {children}
  </div>
);
Body.displayName = 'Body';
Card.Body = Body;

const Footer = ({ children, appendClassName = '', ...props }: any) => (
  <footer
    className={`border-t border-neutral-200 dark:border-neutral-800 ${appendClassName}`}
    {...props}
  >
    {children}
  </footer>
);
Footer.displayName = 'Footer';
Card.Footer = Footer;