import { SubmitButton } from "@scanow/ui"
import { CheckboxField, ImageField, InputField } from "@scanow/ui"
import { useMutation } from "@apollo/client"
import { useTranslation } from "next-i18next"
import React, { useEffect } from "react"
import { useForm } from "react-hook-form"
import { toast } from "react-toastify"
import { UPDATE_USER } from "../Queries"
import { REGEX_EMAIL_PATTERN, userAuthReturn } from "@scanow/helpers"
import { UPLOAD_FILE } from "../../fileManager"
import { TeamSelectorField } from "../../team/Form/TeamSelector"
import { fileManagerUploadFile } from '@scanow/helpers';


interface FormData {
  id: string
  firstname: string
  lastname: string
  email: string
  team_id: string
  avatar_url?: string
  mail_notify_on_incident: boolean
}

interface Props {
  default_values: FormData,
  cb_success: any,
  cb_error: any
  auth: userAuthReturn
}

export function UserUpdateSettings({ default_values, cb_error, cb_success, auth }: Props): JSX.Element {
  //  Initialize React-hook-form
  const { register, handleSubmit, reset, setError, formState: { errors }, watch, setValue } = useForm<FormData>({
    defaultValues: default_values
  })

  const { t } = useTranslation("company");
  const avatar_url_watcher = watch("avatar_url")

  const [updateMutation] = useMutation(UPDATE_USER, { refetchQueries: 'all' })
  const [fileUploadMutation] = useMutation(UPLOAD_FILE)
  useEffect(() => {
    reset()
  }, [])

  function onSubmit(data: FormData) {
    updateMutation({
      variables: {
        id: default_values.id,
        _set: {
          firstname: data.firstname,
          lastname: data.lastname,
          email: data.email,
          team_id: data.team_id,
          avatar_url: data.avatar_url,
          mail_notify_on_incident: data.mail_notify_on_incident
        }
      }
    }).then(res => {
      toast.success("L'utilisateur a été modifié avec succès !")
      if (cb_success) {
        cb_success(res)
      }
    }).catch(err => {
      toast.error("Erreur lors de la modification de l'utilisateur")
      if (cb_error) {
        cb_error(err)
      }
    })
  }

  async function fileUpload(file: File): Promise<void> {
    const uploadedFile = await fileManagerUploadFile(file, auth!.accessToken as string);
    setValue('avatar_url', uploadedFile.full_url);
  }

  function onFileChange(file: File) {
    toast.promise(fileUpload(file), {
        pending: 'Téléchargement du fichier',
        success: 'Fichier téléchargé avec succès',
        error: 'Erreur lors du téléchargement du fichier',
      },
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} >
      <InputField label={"Prénom"} register={register('firstname', {
        required: "Ce champ est requis !",
      })} errors={errors} />

      <InputField label={"Nom"} register={register('lastname', {
        required: "Ce champ est requis !",
      })} errors={errors} />

      <InputField label={"Email"} register={register('email', {
        required: "Ce champ est requis !",
        pattern: {
          value: REGEX_EMAIL_PATTERN,
          message: "L'email n'est pas valide !"
        }
      })} errors={errors} />


      <ImageField
        register={register('avatar_url', { required: false })}
        onFileChange={onFileChange}
        setError={setError}
        errors={errors}
        value={avatar_url_watcher}
        label={'Image'}
        onReset={() => {
          //@ts-ignore
          setValue('avatar_url', null);
        }}
      />
      <br />

      <TeamSelectorField register={register("team_id", { required: true })} label="Équipe" />
      <CheckboxField register={register("mail_notify_on_incident")} label={"Recevoir les notifications"} />

      < SubmitButton />

    </form>
  )



}