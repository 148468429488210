import { useQuery } from "@apollo/client";
import { Loading, ErrorMessage } from "@scanow/ui"
import React from "react";
import { LocationList } from "../Data/List";
import { ILocations } from "../Interfaces";
import { GET_ALL_LOCATIONS, GET_ALL_LOCATIONS_LIST } from "../Queries";

interface LocationListWrapperProps {
  role: number;
  readOnly?:boolean;
}

export function LocationListWrapper({ ...props }: LocationListWrapperProps): JSX.Element {
  const { data, loading, error } = useQuery<ILocations>(GET_ALL_LOCATIONS_LIST);
  if (loading) {
    return (<Loading />);
  }
  if (error) {
    console.error(error);
    return (<ErrorMessage message="Une erreur inconnue s'est produite"/>)
  }
  if (data) {
    return <LocationList locations={data.locations} {...props} />;
  }

  return (<ErrorMessage message="Une erreur inconnue s'est produite"/>)
}