import { gql } from "@apollo/client";

export const GET_ALL_LOCATIONS = gql`
  query allLocations {
    locations: scanow_location(order_by: { name: asc }) {
      name
      id
      latitude
      longitude
      company_id
      company {
        id
        name
        slug
        created_at
      }
      resources_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;

export const GET_ALL_LOCATIONS_LIST = gql`
  query allLocationsList {
    locations: scanow_location(order_by: { name: asc }) {
      name
      id
      latitude
      longitude
      company_id
      company {
        id
        name
        slug
        created_at
      }
    }
  }
`;


export const GET_ALL_LOCATIONS_INVENTORY = gql`
  query allLocations {
    locations: scanow_location(order_by: { name: asc }) {
      name
      id
      latitude
      longitude
      company_id
      company {
        id
        name
        slug
        created_at
      }
    }
  }
`;

export const GET_ALL_LOCATIONS_NAME = gql`
  query allLocationsByName {
    locations: scanow_location(order_by: { name: asc }) {
      id
      name
    }
  }
`;

export const DELETE_LOCATION = gql`
  mutation deleteLocation($id: Int!) {
    location: delete_scanow_location_by_pk(id: $id) {
      id
    }
  }
`;

export const GET_UPDATED_LOCATION = gql`
  query getUpdatedLocation($id: Int!) {
    location: scanow_location_by_pk(id: $id) {
      id
      name
      company_id
    }
  }
`;

export const UPDATE_LOCATION = gql`
  mutation updateLocation($id: Int!, $_set: scanow_location_set_input) {
    location: update_scanow_location_by_pk(
      pk_columns: { id: $id }
      _set: $_set
    ) {
      id
      name
      company_id
      company {
        name
      }
    }
  }
`;

export const CREATE_LOCATION = gql`
  mutation createLocation(
    $name: String
    $latitude: String
    $longitude: String
  ) {
    new_location: insert_scanow_location_one(
      object: { name: $name, latitude: $latitude, longitude: $longitude }
    ) {
      id
      name
      company_id
    }
  }
`;

export const GET_LOCATION_DETAIL = gql`
  query locationDetail($id: Int!) {
    location: scanow_location_by_pk(id: $id) {
      id
      name
      company_id
      latitude
      longitude
      company {
        id
        name
      }
      # resources_aggregate {
      #   aggregate {
      #     count
      #   }
      # }
      # resources {
      #   id
      #   name
      # }
    }
  }
`;
