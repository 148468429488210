import React, { useRef } from "react";
import {VscFeedback} from "react-icons/vsc";
interface Props {
  onClick: () => void;
}


export function FeedbackButton({onClick}: Props):JSX.Element{

  const feedbackButtonRef = useRef<HTMLDivElement>(null);
  
  React.useEffect(() => {
    // Delete preload-animation class after 0.5 seconds
    setTimeout(() => {
      feedbackButtonRef.current?.classList.remove("preload-animation");

    }, 500);


  }, [])
  

  return(
    <div ref={feedbackButtonRef} onClick={onClick} id="feedbackButton" className="preload-animation feedback__button bg-purple-900 cursor-pointer w-14 h-12 fixed bottom-24 right-0 text-white rounded-l-full flex items-center" style={{zIndex: 99}}>
      <VscFeedback className="ml-2 h-8 w-8 shrink-0" />
      <p>
        Feedback
      </p>
    </div>
  )

}