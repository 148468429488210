
import axios, {AxiosProgressEvent} from 'axios';


interface FileManagerFileUploadResponse {
  file_name: string;
  full_url: string;
  file_path: string;
}

export const APP_FILE_MANAGER_URL = process.env.NEXT_PUBLIC_APP_FILE_MANAGER_URL


export async function fileManagerUploadFile(file: File, authToken: string, onUploadProgressCb?: (e: AxiosProgressEvent) => void): Promise<FileManagerFileUploadResponse> {

  if (!APP_FILE_MANAGER_URL || APP_FILE_MANAGER_URL === "") {
    throw new Error("NEXT_PUBLIC_APP_FILE_MANAGER_URL env variable is undefined");
  }

  const formData = new FormData();
  formData.append('file', file);

  const response = await axios.post(`${APP_FILE_MANAGER_URL}/private/file/upload`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: authToken,
    },
    onUploadProgress: (progressEvent) => {
      if(onUploadProgressCb) onUploadProgressCb(progressEvent);
    }
  });

  if (response.status === 201) {
    console.log('File uploaded successfully', response.data)
    return response.data;
  } else {
    throw new Error('Error uploading file');
  }

}

/*
* Utility function used to get estimated download duration
* fileSize is in KB
* downloadSpeed is in KB per second default set to 2.5 MB/s
* */
export function fileManagerGetUploadDuration(fileSize: number, uploadSpeed = 2500): number {
  const duration = fileSize / uploadSpeed;

  return duration;
}