import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { GET_COMPANY_DETAIL, UPDATE_COMPANY } from "../Queries";
import React from "react";
import { TeamSelectorField } from "../../team/Form/TeamSelector";
import { SubmitButton } from "@scanow/ui";
import { useRouter } from "next/router";


interface FormData {
  defaut_team_id: number
}

interface Props {
  company_id: string
  team_id: number
  cb_success?: any;
  cb_error?: any;
}

export function CompanyDefaultTeamUpdate({ company_id, team_id, cb_success, cb_error }: Props): JSX.Element {
  const { register, handleSubmit, watch, setValue, getValues, unregister, reset } = useForm<FormData>({
    defaultValues: { defaut_team_id: team_id }
  })
  const router = useRouter()
  const [updateMutation] = useMutation(UPDATE_COMPANY, {
    refetchQueries: [
      // GET_SETTINGS_DATA,
      GET_COMPANY_DETAIL
    ]
  })

  function submitHandler(data: FormData) {
    updateMutation({
      variables: {
        id: company_id,
        _set: data
      }
    }).then((_data) => {
      // en cas de succès de la requète
      // Verifier si une fonction callback est dispo
      if (cb_success) {
        cb_success(_data.data.company);
      } else {
        //Sinon executer fonction par défaut
        router.reload();
      }
    })
      .catch((err) => {
        console.error(err);
        // en cas de d'erreur de la requète
        // Verifier si une fonction callback est dispo
        if (cb_error) {
          cb_error(err);
        } else {
          //Sinon executer fonction par défaut
          console.error(err);
        }
      });
  }

  return (
    <form onSubmit={handleSubmit(submitHandler)}>
      <TeamSelectorField register={register("defaut_team_id")} label={"Équipe par défaut"} />
      <SubmitButton />
    </form>)
}