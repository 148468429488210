import React, {useState} from 'react'
import Link from "next/link"

type breadcrumValue = string | number | object

interface Props{
  items: Array<{label: string, value: breadcrumValue, onClick?: (value: breadcrumValue) => void}>
  selectedIndex: number
}

const SELECTED_CLASS = "text-primary-500"
const PASSED_CLASS = "text-neutral-400"
const NOT_PASSED_CLASS = "text-neutral-400"

export function Breadcrumb({items, selectedIndex}: Props): JSX.Element {
  function itemClass(index: number): string {
    if (index === selectedIndex) {
      return SELECTED_CLASS
    }
    if (index < selectedIndex) {
      return PASSED_CLASS
    }
    return NOT_PASSED_CLASS
  }

  function isLast(index: number): boolean {
    return index === items.length - 1
  }

  return <nav className="bg-secondary-200 dark:bg-neutral-800 px-5 py-3 rounded-md w-full">
    <ol className="list-reset flex">
    {items.map((item, index) => {
      return <><li
        className={`cursor-pointer text-sm ${itemClass(index)}`}
        key={index}
        onClick={() => item.onClick && item.onClick(item.value)}>
        <a>{item.label}</a>

      </li>
        {!isLast(index) && <span className="mx-2 text-secondary-800 dark:text-secondary-200">&gt;</span>}
      </>
    })
    }
    </ol>
  </nav>
}

/*
 return <div className="w-full flex flex-row justify-evenly">
    {items.map((item, index) => {
      return <><div
        className={`cursor-pointer ${itemClass(index)}`}
        key={index}
        onClick={() => item.onClick && item.onClick(item.value)}>
        {item.label}

      </div>
        {!isLast(index) && <div className="mx-2">&gt;</div>}
      </>
    })
    }
  </div>*/