import React from "react";

/* 
interface ButtonTheme {
  bgColor: string;
  radius: string;
  bordered?: boolean;
  focused?: boolean;
  shadowed?: boolean;
  size: string;
  icon: string;
}
*/
export interface ButtonProps {
  role?: string
  children?: any;
  value?: any;
  id?: string;
  title?: string;
  className?: string;
  appendClassName?: string;
  icon?: any;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  type?: "button" | "submit" | "reset";
}

export function Button({
  children, id, className, appendClassName, title,
  role,
  //themes,
  disabled, icon, onClick, type,
}: ButtonProps) {
  return (
    <button
      role={role}
      id={id}
      title={title}
      type={type || "button"}
      className={className || "cursor-pointer p-3 bg-primary rounded-lg w-full text-base font-medium focus:outline-none transition ease-in-out delay-150" + " " + appendClassName}
      onClick={onClick}
      disabled={false}
    >
      {/*<span className="grid place-items-center h-full w-12 text-gray-300">{icon}</span> TODO*/}
      {children || title}
    </button>
  );
}

