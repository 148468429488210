
// SOCIAL CONFIG
export const FACEBOOK_URL = "https://www.facebook.com/scanow";
export const TWITTER_URL = "http://twitter.fr/artdigitfr";
export const LINKEDIN_URL = "https://fr.linkedin.com/company/scanow";
export const INSTAGRAM_URL = "http://instagram.fr/artdigit_fr";
export const GITHUB_URL = "http://github.com/orgs/artdigit";

// GG CONFIG
export const GOOGLE_ANALYTICS = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS || "UA-xxxxxxxxx-2";
export const RECAPTCHA_SITE_KEY = process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY || "recaptcha_public_key";
export const RECAPTCHA_SECRET_KEY = process.env.NEXT_PUBLIC_RECAPTCHA_SECRET_KEY || "recaptcha_secret_key";


// FIREBASE CONFIG
export const FIRBASE_API_KEY_URL = process.env.NEXT_PUBLIC_FIRBASE_API_KEY || "AIzaSyCDyvekteEJCrr8OSOB89DtNtByOlL4Nco";
export const FIRBASE_AUTH_DOMAIN = process.env.NEXT_PUBLIC_FIRBASE_AUTH_DOMAIN || "scanow-454f9.firebaseapp.com";
export const FIRBASE_PROJECT_ID = process.env.NEXT_PUBLIC_FIRBASE_PROJECT_ID || "scanow-454f9";
export const FIRBASE_STORAGE_BUCKET = process.env.NEXT_PUBLIC_FIRBASE_STORAGE_BUCKET || "scanow-454f9.appspot.com";
export const FIRBASE_MESS_SENDER_ID = process.env.NEXT_PUBLIC_FIRBASE_MESS_SENDER_ID || "647867727668";
export const FIRBASE_APP_ID = process.env.NEXT_PUBLIC_FIRBASE_APP_ID || "1:647867727668:web:7b5e71371e4b9ce84d2f61";

// SENTRY CONFIG
export const SENTRY_AUTH_TOKEN = process.env.NEXT_PUBLIC_SENTRY_AUTH_TOKEN || "https://7b101606fffc488d9d0825a9f0e34ffd@sentry.tmison.ovh/9";
export const NEXT_PUBLIC_SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN || "d090bcc857b14135b20cfe8c0b9b233b9b1f203f274d4527b899418e8373dc1e";