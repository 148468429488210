interface IErrorFormat {
  type: string;
  informations: object;
  raw_error: Error;
}

import {captureException} from '@sentry/nextjs';
import "@sentry/tracing"

function parseForeignKey(err: Error): IErrorFormat {
  let reg = /".*?"/g;
  let keys = err.message.match(reg);

  let errorFormatted: IErrorFormat = {
    type: "Foreign key violation",
    /*
  TODO 
  dangerouslySetInnerHTML={{
      __html: htmlString,
    }}*/

    informations: {
      table_source: keys && keys[0].slice(1, -1),
      key: keys && keys[1].slice(1, -1),
      table_destination: keys && keys[2].slice(1, -1),
    },
    raw_error: err,
  };

  return errorFormatted;
}

export function apolloErrorParser(err: Error) {

  switch (true) {
    case err.message.includes("Foreign key violation."):
      return parseForeignKey(err);
    default:
      captureException(err, {level: 'warning', tags: {component: "ApolloErrorParser"}})  
      return { type: "not_included", informations: {}, raw_error: err };

  }
}
