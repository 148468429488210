import React from "react";
import { THEME_BUTTON, THEME_BUTTON_SECONDARY } from "@scanow/theme";

export interface SubmitButtonProps {
  value?: string;
  id?: string;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLInputElement>;
  disabled?: boolean;
  hidden?: boolean;
  appendClassName?: string;
}

export const SubmitButton = ({
  id,
  value,
  className,
  disabled,
  appendClassName = "",
  hidden,
  onClick,
}: SubmitButtonProps) => (
  <input
    type="submit"
    id={id}
    className={`${
      className || THEME_BUTTON + THEME_BUTTON_SECONDARY
    } ${appendClassName}`}
    value={value}
    onClick={onClick}
    disabled={false}
    hidden={hidden}
  />
);