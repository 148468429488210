import React from "react";
import { Button } from "../Button";
import { classNames } from "@scanow/helpers";

interface ITable {
  children?: any;
  appendClassName?:string;
}

export function TableHeader({ children }: ITable): JSX.Element {
  return (
    <header className="grid gap-y-2 grid-cols-1 lg:grid-cols-2 py-4 mb-2">
      {children}
    </header>
  );
}

export function TableBody({ children }: ITable): JSX.Element {
  return <div className={""}>{children}</div>;
}

export function TablePagination({ children }: ITable): JSX.Element {
  return (
    <div className="flex items-center justify-start lg:justify-between w-full">
      {children}
    </div>
  );
}

export function TableFooter({ children }: ITable): JSX.Element {
  return (
    <footer className="dark:text-neutral-400 p-4 flex items-center inline justify-between text-lg">
      {children}
    </footer>
  );
}

export function TablePaginationButton({ children, className, ...rest }: any) {
  return (
    <Button
        appendClassName=
        "w-9 h-9 rounded-full bg-secondary-200 dark:bg-cyan-200 text-secondary-800 hover:opacity-90 hover:text-secondary-600 duration-300 transition ease-in-out"
      {...rest}
    >
      {children}
    </Button>
  );
}