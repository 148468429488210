import React, { useState } from "react";
import { toast } from "react-toastify";
import { LegacyButton } from "@scanow/ui";
const Button = LegacyButton.Button;;
import { IoCreateOutline, } from "react-icons/io5";
import { dateFormat, userAuthReturn } from "@scanow/helpers";
import Image from "next/image";
import { Text, Modal } from "@scanow/ui";
import { ISettingsUser } from "../Interfaces";
import { UserUpdateWrapper } from "../../user/Wrapper/Update";


interface UserProps {
  user: ISettingsUser;
  role: number
  auth: userAuthReturn
  application: "sonar" | "scanow"
}

export function ServiceUser({ user, role, auth, application }: UserProps): JSX.Element {
  const [updateModal, setUpdateModal] = useState(false);

  return (
    <div>
      <header className="flex justify-between center pb-12">
        <div className={"flex"}>
          <Button
            className={
              "bg-indigo-100 text-indigo-700 text-font w-12 h-12 rounded-full mr-3"
            }
            disabled={true}
            onClick={(e) => {
              e.preventDefault();
              setUpdateModal(true);
            }}
          >
            <IoCreateOutline size={25} className="mx-auto" />
          </Button>
          <span className={"my-auto"}>
            <Text.Subtitle>
              {user.firstname} {user.lastname}
            </Text.Subtitle>
          </span>
        </div>
      </header>
      <div className="grid md:grid-cols-1 xl:grid-cols-2 md:text-sm text-font-light">
        <div className="relative w-full h-full mb-4">
          <Image
            src={user.avatar_url || "/images/no_avatar.jpg"}
            alt={user.firstname}
            layout="fill"
            objectFit="contain"
          />
        </div>
        <ul>
          <li>
            Inscrit depuis le {dateFormat(user.created_at).formatFullDate()}
          </li>
          {/*<li>Ressources commandées : {company.licence_count}</li>*/}
          <Text.Subtitle appendClassName="mt-4">Mon équipe</Text.Subtitle>

          <li className="mt-4 flex inline"><Text.Normal>{user.team.name}</Text.Normal></li>
        </ul>
      </div>
      {/* TODO Problème ERROR modal lors modif USER */}
      <Modal isOpen={updateModal} setState={setUpdateModal}>
        <UserUpdateWrapper auth={auth} id={user.id} role={role} application={application} />
      </Modal>
    </div>
  );
}