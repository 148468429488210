import { useRouter } from "next/router";
import { ILocation } from "../Interfaces";
import { Button, Tag, Text } from "@scanow/ui";
import { RiMapPin2Line } from "react-icons/ri";
import React from "react";
interface Props {
  location: ILocation;
}

export function LocationResourceList({ location }: Props): JSX.Element {
  const router = useRouter();
  return (
    <>
      <div className="flex h-full flex-col justify-center items-center gap-2">
        <RiMapPin2Line className="bg-slate-200 text-slate-700 h-16 w-16 p-2 rounded-full" />
        <Text.Title>Localité {location.name}</Text.Title>
        <Text.Subtitle>{location.company.name}</Text.Subtitle>
        <div className=" flex flex-wrap gap-4 max-w-5xl justify-center">
          {/* {location.resources.map((resource) => ( TOFIX)
              <Tag.Purple key={`LocRes-${resource.id}`}>{resource.name}</Tag.Purple>
          ))} */}
        </div>
      </div>
    </>
  );
}
