import { useRouter } from "next/router";
import React, { useState } from "react";
import {InputField, SubmitButton, Button, Text} from "@scanow/ui";
import slugify from "slugify";
import moment from "moment-timezone";
import { useMutation } from "@apollo/client";
import { CREATE_COMPANY, GET_ALL_COMPANIES } from "../Queries";
import { useForm } from "react-hook-form";
import { IoSyncOutline } from "react-icons/io5";

interface ICompany {
  name: string;
  slug: string;
}

interface CompanyProps {
  cb_success?: any;
  cb_error?: any;
}

export function CompanyCreateForm({
  cb_success,
  cb_error,
}: CompanyProps): JSX.Element {

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm<ICompany>();
  const router = useRouter();
  const [formData, setFormData] = useState<ICompany>({
    name: "",
    slug: "",
  });
  const [submittedData, setSubmitedData] = useState(null);
  const [createCompany] = useMutation(CREATE_COMPANY, {
    refetchQueries: [GET_ALL_COMPANIES],
  });

  function submitHandler(data: any) {
    createCompany({
      variables: {
        name: data.name,
        slug: data.slug,
      },
    })
      .then((data) => {
        // en cas de succès de la requète
        // Verifier si une fonction callback est dispo
        if (cb_success) {
          cb_success(data.data.company);
        } else {
          //Sinon executer fonction par défaut
          setSubmitedData(data.data.company);
          router.reload();
        }
      })
      .catch((err) => {
        // en cas de d'erreur de la requète
        // Verifier si une fonction callback est dispo
        if (cb_error) {
          cb_error(err);
        } else {
          //Sinon executer fonction par défaut
          console.error(err);
        }
      });
  }

  function generateSlug() {
    setValue(
      "slug",
      slugify(getValues("name").substring(0, 3)).toLowerCase() +
        moment().format("DDMM") +
        Math.floor(Math.random() * 10) +
        Math.floor(Math.random() * 10)
    );
  }

  return (
    <form onSubmit={handleSubmit(submitHandler)} className="font-display">
      <InputField
        label={"Nom"}
        register={register("name", {
          required: true,
          minLength: 3,
          maxLength: 32,
          /* TODO ANALYSE
                    pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    validate: {
                      requiredField: (value) => value !== "",
                      minCHAR: (value) => value.length > 3,
                    },
                    */
        })}
      />
      {errors.name && errors.name.type === "required" && (
        <Text.Small appendClassName="py-2 text-danger-500 block">
            Ce champ est requis !
        </Text.Small>
      )}
      {errors.name && errors.name.type === "minLength" && (
          <Text.Small appendClassName="py-2 text-danger-500 block ">
              Le nom doit contenir au moins 3 caractères !
          </Text.Small>
      )}

      <Button
        onClick={generateSlug}
        //icon={<IoReloadCircleSharp className="h-6 w-6" />} TODO
        appendClassName={
          "bg-primary h-12 rounded-l-lg" +
          /*THEME_BG_COLOR_PRIMARY +*/
          " px-2 py-2 text-xs leading-5 w-auto " +
          "rouded-l-lg"
        }
      >
        <IoSyncOutline className="w-6 h-6 " />
      </Button>

      <InputField
        register={register("slug", {
          required: true,
          minLength: 3,
          maxLength: 32,
        })}
        appendClassName={"rounded-r-lg "}
      />

      <SubmitButton value={"Enregistrer"} />
    </form>
  );
}