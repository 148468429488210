import React, { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { RiMoreFill } from "react-icons/ri";
import { Button } from "@scanow/ui";

import Link from "next/link";

export interface DropDownItem {
  id: string;
  name: string;
  icon?: any;
  href?: string;
  onClick?: () => void;
}

export interface DropDownProps {
  className?: string;
  panelMenusClass?: string;
  iconClass?: string;
  data: DropDownItem[];
  renderTrigger?: () => JSX.Element;
  renderItem?: (item: DropDownItem) => JSX.Element;
  title?: string;
  onClick: (item: DropDownItem) => void;
}

export const DropDown = ({
  //className = `cursor-pointer relative h-auto inline-flex items-center justify-center transition-all duration-150 rounded-full w-9 h-9 text-lg bg-neutral-200 hover:bg-neutral-300 text-neutral-700 dark:bg-neutral-800 dark:text-neutral-200 dark:hover:bg-neutral-700 p-2 text-lg`,
  className = "",
  iconClass = "",
  panelMenusClass = "origin-top-right",
  title,
  renderTrigger,
  renderItem,
  data,
  onClick,
}: DropDownProps) => {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <Menu.Button className={className} title={title}>
        {renderTrigger ? (
          renderTrigger()
        ) : (
          <RiMoreFill className={iconClass} />
        )}
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={`absolute ${panelMenusClass} right-0 w-56 mt-2 bg-white dark:bg-neutral-900 rounded-2xl divide-y divide-neutral-100 shadow-lg ring-1 ring-black dark:ring-white ring-opacity-5 dark:ring-opacity-10 focus:outline-none z-30`}
        >
          <div className="px-1 py-3 text-sm text-neutral-600 dark:text-neutral-300">
            {data.map((item) => (
              <Menu.Item
                as={"div"}
                key={`Item${item.id}`}
                data-menu-item-id={item.id}
                onClick={() => {
                  onClick(item);
                  item.onClick && item.onClick();
                }}
              >
                {() =>
                  renderItem && typeof renderItem(item) !== "undefined" ? (
                    renderItem(item)
                  ) : !!item.href ? (
                    <Link href={item.href}>
                      <a
                        className={
                          "flex items-center rounded-md w-full px-3 py-2 hover:bg-indigo-600 dark:hover:bg-neutral-800 hover:text-white dark:hover:text-neutral-100 truncate "
                        }
                      >
                        <span className="mr-1 w-5">{item.icon}</span>
                        <span className="truncate text-xs">{item.name}</span>
                      </a>
                    </Link>
                  ) : (
                    <button
                      className={
                        "flex items-center rounded-md w-full px-3 py-2 hover:bg-indigo-600 dark:hover:bg-neutral-800 hover:text-white dark:hover:text-neutral-100 truncate "
                      }
                    >
                      <span className="mr-1 w-5">{item.icon}</span>
                      <span className="truncate text-xs">{item.name}</span>
                    </button>
                  )
                }
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

//export default DropDown;
