import React from "react";
import { Modal } from "./Modal";
import { Button } from "../Button";

export interface ConfirmationModalProps {
  title: string;
  validate_callback: () => void;
  reject_callback?: () => void;
  isOpen: boolean;
  setState: (state: boolean) => void;
  children: React.ReactNode;
}

export function ConfirmationModal({
  title,
  validate_callback,
  reject_callback,
  isOpen,
  setState,
  children,
}: ConfirmationModalProps) {
  return (
    <Modal isOpen={isOpen} setState={setState} title={title}>
      <div>
        <>{children}</>
        <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-4 mt-2">
          <Button.Warning
            onClick={() => {
              validate_callback();
              setState(false);
            }}
          >
            Confirmer
          </Button.Warning>
          <Button.Default
            onClick={(e) => {
              setState(false);
              if (reject_callback) reject_callback();
            }}
          >
            Annuler
          </Button.Default>
        </div>
      </div>
    </Modal>
  );
}