import { useQuery } from "@apollo/client";
import { GET_UPDATE_USER } from "../Queries";
import { Loading, ErrorMessage } from "@scanow/ui";
import { UserUpdateForm } from "../Form/Update";
import React from "react";
import { userAuthReturn } from "@scanow/helpers";

interface Props {
  id: string;
  cb_success?: any;
  cb_error?: any;
  role: number;
  auth: userAuthReturn;
  application: "scanow" | "sonar";
}

interface IUserForm {
  id: string;
  company_id: string;
  firstname: string;
  lastname: string;
  email: string;
  avatar_url?: string;
  team_id: string;
  password: string;
  mail_notify_on_incident: boolean;
}

interface ApolloData {
  user: IUserForm;
}

export function UserUpdateWrapper({ id, ...props }: Props): JSX.Element {
  const { data, loading, error } = useQuery<ApolloData>(GET_UPDATE_USER, {
    variables: { id: id },
  });
  if (loading) {
    return <Loading />;
  }
  if (error) {
    console.error(error);
    return <ErrorMessage message="Une erreur inconnue s'est produite" />;
  }
  if (data) {
    return <UserUpdateForm user={data.user} {...props} />;
  }
  return (
    <ErrorMessage message="Une erreur inconnue est survenue. Code: 9999" />
  );
}