import React from "react";

export interface TabProps {
  children: JSX.Element | JSX.Element[];
}

export function Tab({ children }: TabProps): JSX.Element {
  if (!Array.isArray(children)) {
    children = [children];
  }

  // Flatten children
  children = children.reduce((acc: any, child): JSX.Element[] => {
    if (Array.isArray(child)) {
      return [...acc, ...child];
    }
    return [...acc, child];
  }, []);

  if (!Array.isArray(children)) {
    children = [children];
  }

  return (
    <>
      {children.map((child, index) => {
        return (
          <React.Fragment key={`Tab_${index}`}>
            <input
              type="radio"
              // key={index} TODO unique Key
              id={`tab${index + 1}`}
              name="tabGroup"
              className="tab"
              defaultChecked={index === 0}
            />
 
            <label
              htmlFor={`tab${index + 1}`}
              className="tabLabel text-slate-400 cursor-pointer m-2 text-xs md:text-sm"
              //className={`cursor-pointer text-sm p-2 m-2 w-full rounded-md border border-indigo-600 text-indigo-600` }
            >
              {/*<span className="flex md:hidden font-medium">
                {child.props?.icon}
              </span>*/}
              {child.props?.title}
            </label>
          </React.Fragment>
        );
      })}

      {children.map((child, index) => {
        return (
          <section
            key={`TabSection_${index}`}
            className="tab__Body grid rounded-r-lg rounded-b-lg"
          >
            {child}
          </section>
        );
      })}
    </>
  );
}

interface SectionProps {
  title: string;
  icon?: JSX.Element;
  children: JSX.Element | JSX.Element[];
  className?: string;
}

Tab.Section = ({ title, icon, children, ...props }: SectionProps) => {
  return <div {...props}>{children}</div>;
};

Tab.displayName = "Tab";
