import {
  FACEBOOK_URL,
  INSTAGRAM_URL,
  TWITTER_URL,
  LINKEDIN_URL,
  GITHUB_URL,
} from "@scanow/config";
import React from "react";
import {
  IoLogoFacebook,
  IoLogoInstagram,
  IoLogoGithub,
  IoLogoTwitter,
  IoLogoLinkedin,
} from "react-icons/io5";

export const SocialButtons = () => {
  return (
    <div className="inline-flex justify-center mt-4 space-x-5 sm:ml-auto sm:mt-0 sm:justify-start">
      <a
        href={FACEBOOK_URL}
        target="_blank"
        rel="noreferrer"
        className="text-gray-400 hover:text-gray-900 dark:hover:text-white"
      >
        <span className="sr-only">Facebook</span>
        <IoLogoFacebook className="w-6 h-6" size={20} />
      </a>

      <a
        href={INSTAGRAM_URL}
        target="_blank"
        rel="noreferrer"
        className="text-gray-400 dark:hover:text-white hover:text-gray-900"
      >
        <span className="sr-only">Instagram</span>
        <IoLogoInstagram className="w-6 h-6" size={20} />
      </a>

      <a
        href={TWITTER_URL}
        target="_blank"
        rel="noreferrer"
        className="text-gray-400 dark:hover:text-white hover:text-gray-900"
      >
        <span className="sr-only">Twitter</span>
        <IoLogoTwitter className="w-6 h-6" size={20} />
      </a>

      <a
        href={LINKEDIN_URL}
        target="_blank"
        rel="noreferrer"
        className="text-gray-400 dark:hover:text-white hover:text-gray-900"
      >
        <span className="sr-only">LinkedIn</span>
        <IoLogoLinkedin className="w-6 h-6" size={20} />
      </a>

      <a
        href={GITHUB_URL}
        target="_blank"
        rel="noreferrer"
        className="text-gray-400 dark:hover:text-white hover:text-gray-900"
      >
        <span className="sr-only">Github</span>
        <IoLogoGithub className="w-6 h-6" size={20} />
      </a>
    </div>
  );
};


