import { useQuery } from "@apollo/client";
import { Loading, ErrorMessage  } from "@scanow/ui";
import React from "react";
import { TeamDetail } from "../Data";
import { ITeam } from "../Interfaces";
import { GET_TEAM_DETAILS } from "../Queries";

interface TeamProps {
  id: number;
}

interface TeamData {
  team: ITeam

}

export function TeamDetailWrapper({ id }: TeamProps): JSX.Element {
  const { data, loading, error } = useQuery<TeamData>(GET_TEAM_DETAILS, { variables: { id: id } })
  if (loading) {
    return (<Loading />)
  }
  if (error) {
    console.error(error)
    return (<ErrorMessage message="Une erreur inconnue s'est produite"/>)
  }
  if (data) {
    return <TeamDetail team={data.team} />;
  }
  return (<ErrorMessage message="Une erreur inconnue est survenue. Code: 9999"/>)
};