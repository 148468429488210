import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { ImageField, InputField } from "@scanow/ui";
import { SubmitButton } from "@scanow/ui";
import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form"
import Image from "next/image"
import { CREATE_USER, GET_ALL_USERS, GET_ALL_USERS_NAME } from "../Queries";
import { capitalize, uppercase, lowercase, REGEX_EMAIL_PATTERN, userAuthReturn } from "@scanow/helpers";
import { UPLOAD_FILE } from "../../fileManager";
import { TeamSelectorField } from "../../team";
import { fileManagerUploadFile } from '@scanow/helpers';
import { toast } from 'react-toastify';

interface IUserForm {
  firstname: string;
  lastname: string;
  email: string;
  password: string;
  avatar_url?: string;
  team_id: string;
}

interface Props {
  cb_success?: any;
  cb_error?: any;
  auth: userAuthReturn
}

export function UserCreateForm({ cb_success, cb_error, auth }: Props): JSX.Element {
  const { register, handleSubmit, setValue, setError, getValues, watch,reset, formState: { errors } } = useForm<IUserForm>()
  const router = useRouter();
  const [submittedData, setSubmitedData] = useState<IUserForm | null>(null);
  const [createUser] = useMutation(CREATE_USER, {
    refetchQueries: [GET_ALL_USERS_NAME, GET_ALL_USERS],
  });
  const avatar_url_watcher = watch("avatar_url")

  useEffect(()=>{
    reset()
  }, [])

  function submitHandler(data: IUserForm) {
    data.firstname = capitalize(data.firstname)
    data.lastname = uppercase(data.lastname)
    data.email = lowercase(data.email)
    createUser({
      variables: { ...data, password: "PATATE" },
    })
      .then((data) => {
        // en cas de succès de la requète
        // Verifier si une fonction callback est dispo
        if (cb_success) {
          cb_success(data.data.user);
        } else {
          //Sinon executer fonction par défaut
          setSubmitedData(data.data.user);
          router.reload();
        }
      })
      .catch((err) => {
        // en cas de d'erreur de la requète
        // Verifier si une fonction callback est dispo
        if (cb_error) {
          cb_error(err);
        } else {
          //Sinon executer fonction par défaut
          console.error(err);
        }
      });

  }

  async function fileUpload(file: File): Promise<void> {
    const uploadedFile = await fileManagerUploadFile(file, auth!.accessToken as string);
    setValue('avatar_url', uploadedFile.full_url);
  }

  function onFileChange(file: File) {
    toast.promise(fileUpload(file), {
        pending: 'Téléchargement du fichier',
        success: 'Fichier téléchargé avec succès',
        error: 'Erreur lors du téléchargement du fichier',
      },
    );
  }

  return (
    <>
      {submittedData ? (
        <p>Equipment crée !!</p>
      ) : (
        <form onSubmit={handleSubmit(submitHandler)}>
          <InputField errors={errors} label={"Prénom"} register={register("firstname", { required: "Ce champ est requis" })} />
          <InputField errors={errors} label={"Nom"} register={register("lastname", { required: "Ce champ est requis" })} />
          <InputField errors={errors} label={"Email"} register={register("email", {
            required: "Ce champ est requis",
            pattern: {
              value: REGEX_EMAIL_PATTERN,
              message: "Ce champ doit être une adresse email valide"
            }
          })} />

          <TeamSelectorField label={"Équipe"} errors={errors} register={register("team_id",
            {
              required: "Ce champ est requis ! ",
              validate: (value) => {
                if (value && value != "null") {
                  return true
                }
                return "Ce champ est requis !"
              }
            })} />

          {avatar_url_watcher && <Image src={avatar_url_watcher} alt="" />}

          <ImageField
            register={register('avatar_url', { required: false })}
            onFileChange={onFileChange}
            setError={setError}
            errors={errors}
            value={avatar_url_watcher}
            label={'Image'}
            onReset={() => {
              //@ts-ignore
              setValue('avatar_url', null);
            }}
          />

          <SubmitButton value="Enregistrer" />
        </form>
      )}
    </>
  );
};