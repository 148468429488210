import React from "react";
import { THEME_FORM_INPUT } from "@scanow/theme";
import { Label, Text } from "@scanow/ui";
import {
  FieldErrors,
  FieldErrorsImpl,
  UseFormRegisterReturn,
} from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

export interface InputFieldProps {
  type?: string;
  label?: string | JSX.Element;
  messages?: string | JSX.Element;
  name?: string;
  onClick?: React.MouseEventHandler<HTMLInputElement>;
  onChange?: React.FormEventHandler<HTMLInputElement>;
  required?: boolean;
  className?: string;
  appendClassName?: string;
  disabled?: boolean;
  autoFocus?: boolean;
  placeholder?: any;
  register?: UseFormRegisterReturn;
  errors?: FieldErrorsImpl<any>;
  setState?: Function;
  state?: any;
  step?: string;
  autoComplete?: "on" | "off";
}

export function InputField({
  type,
  label,
  onClick,
  onChange,
  placeholder,
  className,
  disabled,
  autoComplete,
  autoFocus,
  register,
  errors,
  setState,
  state,
  appendClassName = "",
  required,
  name,
  step,
  messages,
}: InputFieldProps) {
  function defaultOnChange(e: any) {
    if (setState && state) {
      setState({
        ...state,
        [name || ""]: e.target.value,
      });
    }
  }

  if (type === "number") {
    if (step === undefined) {
      step = "1";
    }
  }

  return (
    <fieldset className="pt-8">
      <Label htmlFor={register?.name}>{label}</Label>
      {register ? (
        <>
          <input
            id={register?.name || ""}
            type={type || "text"}
            onClick={onClick}
            className={className || THEME_FORM_INPUT + " " + appendClassName}
            placeholder={placeholder}
            autoComplete={autoComplete}
            disabled={disabled}
            autoFocus={autoFocus}
            {...{ step }}
            {...register}
          />
          {errors && (
            <>
              <ErrorMessage
                errors={errors}
                name={register?.name}
                render={({ message }: any) => (
                  <Text.Small appendClassName="py-2 text-danger-500 w-full block ">
                    {message}
                  </Text.Small>
                )}
              />
              {/* TODO 
            <ErrorMessage
        errors={errors}
        name={register?.name}
        render={({ messages }) =>
          messages &&
          Object.entries(messages).map(([type, message]) => (
            <p key={type}>{message}</p>
          ))
        }
      />*/}
            </>
          )}
        </>
      ) : (
        <>
          <input
            id={name || ""}
            type={type || "text"}
            onChange={onChange || defaultOnChange}
            className={className || THEME_FORM_INPUT + " " + appendClassName}
            placeholder={placeholder}
            value={state[name || ""]}
            disabled={disabled}
            {...{ step }}
            autoFocus={autoFocus}
            required={required}
          />
        </>
      )}
    </fieldset>
  );
}

/* TODO ICON INTEGRATION 
<div className="relative text-gray-600 focus-within:text-gray-400">
<span className="absolute inset-y-0 left-0 flex items-center pl-2">
  <button type="submit" className="p-1 focus:outline-none focus:shadow-outline">
    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" strokeWidth="2" viewBox="0 0 24 24" className="w-6 h-6"><path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
  </button>
</span>
<input type="search" name="q" className="py-2 text-sm text-white bg-gray-900 rounded-md pl-10 focus:outline-none focus:bg-white focus:text-gray-900" placeholder="Search..." autocomplete="off"/>
</div>
*/