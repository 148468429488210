import { Button } from "./Default";

import React from "react";
export interface SignupButton {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const SignupButton = ({ onClick }: SignupButton) => {


  return (
    <Button
      onClick={onClick}
      className={
        "bg-primary hover:bg-primary-hover " +
        "cursor-pointer w-full mx-1 my-1 text-base font-medium focus:outline-none transition ease-in-out delay-150"
      }
    >
      Signup
    </Button>
  );
};

