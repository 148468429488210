import React from "react";
import { Text, Container, Button } from "@scanow/ui";
import { useRouter } from "next/router";
import Image404 from "./Image404";

export function Error404() {
  const router = useRouter();
  const backHome = () => {
    router.push("/");
  };

  return (
    <div className="flex items-center h-screen py-8">
      <Container>
        <div className="flex items-center justify-center text-center auto lg:px-0">
          <div>
            <Image404 />
            <Text.Title appendClassName="text-[#453F3C] dark:text-secondary-600 ">
              Page introuvable !
            </Text.Title>
          </div>
        </div>
        <div className="flex items-center justify-center text-center">
          <Text.Normal>{"La page demandée n'a pas été trouvée !"}</Text.Normal>
        </div>
        <div className="py-12 flex items-center justify-center text-center ">
          <div className="w-64">
            <Button.Default
              //color="Secondary"
              //size="Medium"
             // shape="Full"
              href={"/"}
              onClick={backHome}
            >
              {"Retourner à l'accueil"}
            </Button.Default>
          </div>
        </div>
      </Container>
    </div>
  );
}