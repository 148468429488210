import * as React from "react";

export interface IContainer {
  children: React.ReactNode;
  className?: string;
  appendClassName?: string;
}

export function Container({ children, className, appendClassName = "" }: IContainer): JSX.Element {
  return <div className={className || "container relative " + appendClassName}><>{children}</></div>
}


