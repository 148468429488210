import React from "react";

export interface ImageCoverProps {
  className?: string;
}

export const ImageCover = ({
 // className = "absolute inset-x-0 md:top-10 min-h-0 pl-20 py-24 flex overflow-hidden z-0",
 className =""
}:ImageCoverProps) => {
  return (
    <div
      className={`imgCover h-screen bg-cover bg-center ${className}`}
    >
      <img src="/images/test-mockup.png" />
    </div>
  );
};
