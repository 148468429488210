import { useRouter } from "next/router";
import React, { useEffect } from "react";
import { CheckboxField, InputField } from "@scanow/ui"
import { SubmitButton } from "@scanow/ui";
import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form"
import { ITeamLite } from "../Interfaces";
import { UPDATE_TEAM, GET_ALL_TEAMS_NAME, GET_UPDATED_TEAM } from "../Queries";
import { capitalize, lowercase, REGEX_EMAIL_PATTERN } from "@scanow/helpers";



interface Props {
  team: ITeamLite;
  cb_success?: any;
  cb_error?: any;
}

export function TeamUpdateForm({ team, cb_error, cb_success }: Props): JSX.Element {
  const router = useRouter();
  const { register, handleSubmit, watch, setValue, getValues, unregister, reset, formState: { errors } } = useForm({
    defaultValues: team
  })
  const [updateLocation] = useMutation(UPDATE_TEAM, {
    refetchQueries: [
      GET_ALL_TEAMS_NAME,
      GET_UPDATED_TEAM,
      // GET_SETTINGS_DATA
    ],
  });

  useEffect(() => {
    reset()
  }, [])



  function submitHandler(data: ITeamLite) {
    // @ts-ignore
    delete data.phone_notify_on_incident
    // @ts-ignore
    delete data.id
    data.mail = lowercase(data.mail)
    data.name = capitalize(data.name)
    updateLocation({
      variables: {
        id: team.id,
        _set: {
          ...data,
        },
      },
    })
      .then((data) => {
        // en cas de succès de la requète
        // Verifier si une fonction callback est dispo
        if (cb_success) {
          cb_success(data.data.team);
        } else {
          //Sinon executer fonction par défaut
          router.reload();
        }
      })
      .catch((err) => {
        console.error(err)
        // en cas de d'erreur de la requète
        // Verifier si une fonction callback est dispo
        if (cb_error) {
          cb_error(err);
        } else {
          //Sinon executer fonction par défaut
          console.error(err);
        }
      });

  }

  return (
    <>
      <form onSubmit={handleSubmit(submitHandler)}>
        <InputField
          label={"Nom"}
          errors={errors}
          register={register("name", { required: "Ce champ est requis" })}
        />
        <InputField label={"Téléphone"} register={register("phone", { required: false })} />
        <InputField label={"Email"}
          errors={errors}
          register={register("mail", {
            required: "Ce champ est requis",
            pattern: {
              value: REGEX_EMAIL_PATTERN,
              message: "Ce champ doit être une adresse email valide"
            }
          })} />
        <CheckboxField label={"Recevoir les notifications"} register={register("mail_notify_on_incident")} />
        <SubmitButton value="Enregistrer" />
      </form>
    </>

  );
};
