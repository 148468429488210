import React from "react";
import Link from "next/link";
import {Paragraph, Text} from "@scanow/ui";
import { dateFormat } from "@scanow/helpers";
import { useRouter } from "next/router";
import { ISettingsIncident } from "../Interfaces";

interface IncidentProps {
  incidents: ISettingsIncident[];
}

export function ServiceIncident({ incidents }: IncidentProps): JSX.Element {

  const router = useRouter()

  return (
    <div className="h-[700px] overflow-y-scroll pl-3 pr-2" >
      <ol className="border-l-2 border-purple-600  ">
        {/* TODO Z-index icon */}
        {incidents.map((incident) => (
          <li key={"notif_" + incident.id}>
            <div className="md:flex flex-start">
              <div className="bg-purple-600 w-6 h-6 flex items-center justify-center rounded-full -ml-3.5 shrink-0">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  className="text-white w-3 h-3"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                >
                  <path
                    fill="currentColor"
                    d="M0 464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V192H0v272zm64-192c0-8.8 7.2-16 16-16h288c8.8 0 16 7.2 16 16v64c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16v-64zM400 64h-48V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H160V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H48C21.5 64 0 85.5 0 112v48h448v-48c0-26.5-21.5-48-48-48z"
                  ></path>
                </svg>
              </div>
              <div className="block p-4 rounded-lg bg-stone-50 w-full ml-6 mb-4">
                <div className="flex justify-between mb-4">
                  <a
                    href="#!"
                    className="font-medium text-purple-600 hover:text-purple-700 focus:text-purple-800 duration-300 transition ease-in-out text-sm"
                  >
                    Incident
                  </a>
                  <a
                    href="#!"
                    className="font-medium text-purple-600 hover:text-purple-700 focus:text-purple-800 duration-300 transition ease-in-out text-sm"
                  >
                    {dateFormat(incident.created_at).formatFullDate()}
                  </a>
                </div>
                  <Paragraph>
                    <Text.Normal >
                      Un incident a été déclaré sur la ressource <b>{incident.resource.name} </b>
                      concernant l&apos;équipement <b>{incident.equipment.name}</b>
                    </Text.Normal>
                      {incident.closed && incident.user &&
                        <Text.Normal>
                          L&apos;incident a été cloturé par <b> {incident.user.firstname} {incident.user.lastname} </b>
                        </Text.Normal>
                      }
                  </Paragraph>

                <button
                  type="button"
                  className="inline-block px-4 py-1.5 border border-purple-700 font-medium text-xs leading-tight uppercase rounded hover:bg-purple-700 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                  data-mdb-ripple="true"
                  onClick={() => { router.push(`/admin/incident/${incident.id}`) }}
                >
                  Consulter l&apos;incident
                </button>
                {/* <button
                  type="button"
                  className="inline-block px-3.5 py-1  text-purple-600 font-medium text-xs leading-tight uppercase rounded hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
                  data-mdb-ripple="true"
                >
                  Consulter l&apos;incident
                </button> */}
              </div>
            </div>
          </li>
        ))}
      </ol >

    </div >
  )
}