import { dateFormat } from "@scanow/helpers";
import React from "react";
import { useRouter } from "next/router";
import Image from "next/image";
import moment from "moment";
import { Text, Button } from "@scanow/ui";

import { IoCalendarOutline, IoFolderOutline, IoNotificationsOutline, } from "react-icons/io5";


interface CompanyProps {
  company: any;
}

export function CompanyDetail({ company }: CompanyProps): JSX.Element {

  const router = useRouter();
  const today = moment();

  return (
    <>
      <div className="flex items-center border-b border-gray pb-6">
        <div className="relative my-2 w-32 h-12">
          {localStorage.getItem("logo_url") ? (
            <>
              <Image
                src={company.logo_url || "/images/no_avatar.png"}
                alt={company.company_name}
                layout="fill"
                objectFit="contain" />
            </>
          ) : (
            <Text.Normal>{company.company_name}</Text.Normal>
          )}
        </div>
        <div className="flex items-start justify-between w-full">
          <div className="pl-3">
            <Text.Subtitle >
              {company.name}
            </Text.Subtitle>
            <Text.Medium appendClassName="pt-2t">

              Abonné depuis le{" "}
              {dateFormat(company.created_at).formatLongDate()}
            </Text.Medium>
          </div>
          <div className="text-right">
            {today > moment(company.licence_end_date) ?
              <Text.Medium appendClassName="text-danger-500">
                Licence expirée depuis le  {" "}{dateFormat(company.licence_end_date).formatLongDate()}
              </Text.Medium>
              :
              <Text.Medium>
                Licence valide jusqu'au {" "}
                {dateFormat(
                  company.licence_end_date,
                  router.locale
                ).formatFullDate()}
              </Text.Medium>}
          </div>
        </div>
      </div>
      <div className="px-2">
        <div className="flex py-4 justify-between w-full">
          <ul className="leading-5 text-font-light focus:outline-none">
            <li className="inline-block align-middle">
              <Button
                appendClassName={"bg-warning-light text-warning w-14 h-14 mr-2" +
                " rounded-full "}
              >
                <IoNotificationsOutline size={20} className="mx-auto" />
                {company.licence_count -
                  company.resources_aggregate.aggregate.count}
              </Button>
              {" "}
              ressources restantes
            </li>
            {" "}
            <br />
           <Text.Medium>Volume incident {" "}{company.incidents_aggregate.aggregate.count} <br />
            Total équipement {" "} {company.equipments_aggregate.aggregate.count}{" "}
           </Text.Medium>

          </ul>
          <div>
            <h3 className="font-title font-semibold">Options</h3>
            <Button
              appendClassName={"bg-indigo-100 text-indigo-700 text-font w-12 h-12 mr-2" +
              " rounded-full "}
            >
              <IoCalendarOutline size={25} className="mx-auto" />
            </Button>
            <Button
                appendClassName={"bg-indigo-100 text-indigo-700 text-font w-12 h-12 " +
              " rounded-full "}
            >
              <IoFolderOutline size={25} className="mx-auto" />
            </Button>
            {/* TODO Afficher liste Options. SI null affiche aucune option avec bouton commander
            <div className="py-2 px-4 text-xs leading-3 text-indigo-700 rounded-full bg-indigo-100">
              <p>
                {" "}
                #Documentation{" "}
                {company.licence_option_documentation == true
                  ? "OUI"
                  : "NON"}{" "}
                #Maintenance{" "}
                {company.licence_option_control == true ? "OUI" : "NON"}
              </p>
                </div>*/}
          </div>
        </div>
      </div>
    </>
  );
}