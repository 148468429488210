import { parseCookies } from "../cookies";

import accessTokenParser from "./parser";


export interface userAuthReturn {
  isLogged: boolean
  isScanowAdmin: boolean
  isScanowManager: boolean
  isScanowAgent: boolean
  isAdministrationAdmin: boolean
  isSonarAdmin: boolean
  isSonarManager: boolean
  isSonarAgent: boolean
  isSonarChecking: boolean

  scanow_role_weight: number
  sonar_role_weight: number

  role_header?: string
  user?: {
    firstname: string
    lastname: string
    avatar_url: string
    id: string
    email: string
    company_id: string
  }
  licence?: {
    count: number
    end_date: string
    options: {
      control: boolean
      documentation: boolean
    }
  }
  accessToken?: string | null
}

export default function userAuth(req?: any, cookies?: any): userAuthReturn {
  let accessToken = null;
  if (process.browser) {
    let parsedCookie = parseCookies("");
    accessToken = parsedCookie.accessToken;
  } else if (req) {
    let parsedCookie = parseCookies(req);
    accessToken = parsedCookie.accessToken;
  }
  return accessTokenParser(accessToken);

}

