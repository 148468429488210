import React from "react";
import { Card } from "@scanow/ui"
import { ISettingsData } from "./Interfaces"
import { ServiceCompany, ServiceTeam, ServiceUser, } from "./Services"
import { userAuthReturn } from "@scanow/helpers";

interface SettingsProps {
  data: ISettingsData;
  role: number
  auth:userAuthReturn
  application: "sonar" | "scanow";
}

export function SettingsCards({ data, ...props }: SettingsProps): JSX.Element {
  return (
    <>
      <Card>
        <Card.Body>
          <ServiceCompany company={data.company} {...props} />
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <ServiceUser user={data.user} {...props} />
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <ServiceTeam company={data.company} {...props} />
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>Démarrer l'assistant de configuration</Card.Body>
      </Card>
    </>
  );
}