import React from "react";
import { THEME_FORM_CHECKBOX } from "@scanow/theme";
import { Label } from "@scanow/ui";
import {
  FieldErrors,
  FieldErrorsImpl,
  UseFormRegister,
  UseFormRegisterReturn,
} from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

export interface CheckboxFieldProps {
  label: string;
  className?: string;
  disabled?: boolean;
  hidden?: boolean;
  register: UseFormRegisterReturn;
  errors?: FieldErrorsImpl<any>;
  appendClassName?: string;
}

export function CheckboxField({
  label,
  className,
  disabled,
  hidden,
  errors,
  appendClassName,
  register,
}: CheckboxFieldProps) {
  return (
    <fieldset className="my-1 flex items-center">
      <input
        type={"checkbox"}
        id={register?.name || ""}
        className={className || THEME_FORM_CHECKBOX + " " + appendClassName}
        disabled={disabled}
        hidden={hidden}
        {...register}
      />
      <Label htmlFor={register?.name} className="text-xs text-neutral-900 dark:text-neutral-200">{label}</Label>
      {errors && (
        <ErrorMessage
          errors={errors}
          name={register?.name}
          render={({ message }) => (
            <small className="absolute text-danger-500 w-full h-12 block ">
              {message}
            </small>
          )}
        />
      )}
    </fieldset>
  );
}
