import { useRouter } from "next/router";
import { dateFormat } from "@scanow/helpers";
import {Text, Avatar, Button, Paragraph} from "@scanow/ui";
import { useTranslation } from "next-i18next";
import { RiMailLine, RiNotification3Line } from "react-icons/ri";
import React from "react";
import { IUser } from "../Interfaces";

interface UserProps {
  user: IUser;
}

export function UserDetail({ user }: UserProps): JSX.Element {
  const router = useRouter();
  return (
    <div className="flex flex-col h-full items-center justify-center">
      <div className="flex flex-col gap-1 text-center items-center">
        <Avatar
          appendClassName="h-32 w-32 bg-white outline outline-neutral-200 mb-4 p-4 outline-1 outline-offset-4 my-auto"
          src={user?.avatar_url || "/images/no_avatar.png"}
          alt={
            user
              ? `${user.firstname} ${user.lastname.toUpperCase()}`
              : "John Doe"
          }
          title={
            user
              ? `${user.firstname} ${user.lastname.toUpperCase()}`
              : "John Doe"
          }
        />
        <Text.Normal>
          {user.firstname} {user.lastname}
        </Text.Normal>
        <div className="flex justify-center items-center">
          <Paragraph>
           <Text.Normal> Membre depuis le {dateFormat(user.created_at).formatFullDate()}</Text.Normal>
              <Text.Normal>{user.email}</Text.Normal>
            {user.team ? (
              <Text.Normal>Mon équipe :  {user?.team?.name} </Text.Normal>
            ) : (
              <Text.Normal>
                {" "}
                L&apos;utilisateur n&apos;est dans aucune équipe{" "}
              </Text.Normal>
            )}
            <br />
          </Paragraph>
        </div>
      </div>
    </div>
  );
}