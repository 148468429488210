import { Text } from "@scanow/ui";
import React from "react"

export interface IPageTitle {
  title?: string;
}

export function Title({ title }: IPageTitle) {
  return (
    <Text.Title>{title}</Text.Title>
  );
}
 
