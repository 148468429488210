import {gql} from "@apollo/client";

export const GET_SETTINGS_DATA = gql`
    query MyQuery($company_id: uuid!, $user_id: uuid!) {
        company: administration_company_by_pk(id: $company_id) {
            id
            name
            logo_url
            created_at
            licence_count
            licence_end_date
            licence_option_control
            licence_option_documentation
            default_team {
                id
                name
                mail
                phone
                users {
                    id
                    firstname
                    lastname
                    email
                    avatar_url
                }
            }
            resources_aggregate(where: {deleted: {_eq: false}}) {
                aggregate {
                    count
                }
            }
            categories_aggregate: resource_types_aggregate {
                aggregate {
                    count
                }
            }
            equipments_aggregate(where: {deleted: {_eq: false}}) {
                aggregate {
                    count
                }
            }
            incidents_aggregate {
                aggregate {
                    count
                }
            }
        }
        user: administration_user_by_pk(id: $user_id) {
            id
            avatar_url
            email
            firstname
            created_at
            lastname
            mail_notify_on_incident
            phone_notify_on_incident
            team {
                id
                name
            }
        }
    }
`