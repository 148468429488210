import React, { useState } from "react";
import { SketchPicker, ChromePicker } from "react-color";
import { THEME_COLOR_PRIMARY_CODE } from "@scanow/theme"
import { Label } from "@scanow/ui";
import { 
  SetFieldValue,
  FieldErrors,
  UseFormRegisterReturn,
  FieldErrorsImpl,
} from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

export interface ColorPickerFieldProps {
  label?: string;
  register: UseFormRegisterReturn;
  setValue: SetFieldValue<any>;
  value: any;
  errors?: FieldErrorsImpl<any>;
}

export function ColorPickerField({
  label,
  errors,
  register,
  setValue,
  value,
}: ColorPickerFieldProps) {
  function changeHandler(e: any) {
    setValue(register.name, e.hex, true);
  }

  return (
    <fieldset className="my-5">
      <Label htmlFor={register?.name}>{label}</Label>
      <ChromePicker
        color={value || THEME_COLOR_PRIMARY_CODE}
        onChange={changeHandler}
      />
      {errors && (
        <ErrorMessage
          errors={errors}
          name={register?.name}
          render={({ message }) => (
            <small className="absolute text-xs py-1 text-danger-500 w-full h-12 block ">
              {message}
            </small>
          )}
        />
      )}
    </fieldset>
  );
};

