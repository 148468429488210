import {Card, ErrorMessage} from "@scanow/ui"
import React from "react"
import { useQuery } from "@apollo/client"
import { ICompanyHelpDeskSettings } from "../Interfaces"
import { GET_COMPANY_HELPDESK_SETTINGS } from "../Queries"
import { BsGear } from "react-icons/bs"
import { FiMail, FiPhone } from "react-icons/fi"
import { useState } from "react"
import { Loading, Text, Modal } from "@scanow/ui"
import { CompanyUpdateHelpDeskSettings } from "../Form/UpdateHelpDeskSettings"



interface Props {
  id: string
}

interface ApolloData {
  company: ICompanyHelpDeskSettings
}

export function CompanyHelpDeskSettings({ id }: Props) {
  const { data, loading, error } = useQuery<ApolloData>(GET_COMPANY_HELPDESK_SETTINGS, { variables: { id: id } })
  const [updateModal, setUpdateModal] = useState<boolean>(false)
  function cbSuccess() {
    setUpdateModal(false)
  }
  function cbError() {
    setUpdateModal(false)
  }


  if (loading) {
    return <Loading />
  }
  if (error) {
    console.error(error)
    return <ErrorMessage message="Une erreur inconnue s'est produite"/>
  }

  if (data) {
    return (
      <div className="mt-3">

        <div className="w-full flex justify-end">
          <div
            onClick={() => setUpdateModal(true)}
            className="w-9 h-9 bg-blue-100 rounded-full overflow-hidden border-sky-600 border flex items-center justify-center cursor-pointer">
            <BsGear className="text-blue-600 w-5 h-5" />
          </div>
        </div>

        <div className="grid grid-cols-1 gap-5">

          <div className="flex gap-2 items-center">
            <div className="w-9 h-9 bg-blue-100 rounded-full flex-none">
              <FiPhone className="m-2 absolute w-5 h-5 text-blue-600" />
            </div>
            <Text.Medium appendClassName="inline shrink">
              Le champ téléphone est {data.company.incident_phone_enabled ? <> affiché et est
                {data.company.incident_phone_required ? " obligatoire" : " facultatif"}
              </> : "masqué"}
            </Text.Medium>
          </div>

          <div className="flex gap-2 items-center">
            <div className="w-9 h-9 bg-blue-100 rounded-full flex-none">
              <FiMail className="m-2 absolute w-5 h-5 text-blue-600" />
            </div>
            <Text.Medium appendClassName="inline shrink">
              Le champ email est {data.company.incident_mail_enabled ? <> affiché et est
                {data.company.incident_mail_required ? " obligatoire" : " facultatif"}
              </> : "masqué"}
            </Text.Medium>

          </div>
        </div>
        <Modal isOpen={updateModal} setState={setUpdateModal} title={"Paramètres de l'entreprise " + data?.company.name}>
          {data && <CompanyUpdateHelpDeskSettings default_values={data?.company} cb_error={cbError} cb_success={cbSuccess} />}
        </Modal>
      </div>
    )
  }
  return <ErrorMessage message="Une erreur inconnue s'est produite"/>

}