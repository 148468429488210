import { UseFormRegisterReturn } from "react-hook-form";
import { useQuery } from "@apollo/client";
import { SelectField } from "@scanow/ui";
import React from "react";
import { GET_ALL_ROLES_NAME } from "../Queries";

interface Props {
  register: UseFormRegisterReturn;
  label?: string;
  evict?: number[];
  application: "scanow" | "sonar";
}

interface Query {
  roles: [{
    id: number;
    slug: string;
    name: string;
  }]
}

interface Option {
  value: string;
  name: string;
}


export function RoleListSelector({ label, register, evict, application }: Props): JSX.Element {
  const { data, error, loading } = useQuery<Query>(GET_ALL_ROLES_NAME);

  let options: Option[] = [];
  if (data) {
    //@ts-ignore
    options = data.roles
      .map((i) => {
        if (evict?.includes(i.id)) {

          return null
        } else if (i.slug.includes("admin")) {
          return null
        } else if (i.slug.includes(application)) {
          return { value: i.id, name: i.name };
        }
      })
      .filter((e) => {
        return e != null;
      });
  }

  return (
    <>
      {options.length > 0 && (
        <SelectField
          options={options}
          label={label || "Role"}
          placeholder="--- Sélectionnez les rôles ---"
          register={register}
        />
      )}
    </>
  );
}