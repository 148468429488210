import { userAuthReturn } from ".";
import jwt_decode from "jwt-decode";

export default function accessTokenParser(accessToken: string | null): userAuthReturn {
  let role_header = undefined
  let roles = {
    isLogged: false,
    isScanowAdmin: false,
    isScanowManager: false,
    isScanowAgent: false,
    isAdministrationAdmin: false,
    isSonarAdmin: false,
    isSonarManager: false,
    isSonarAgent: false,
    isSonarChecking: false,
  };
  let user = undefined
  let licence = undefined
  let scanow_role_weight = 99
  let sonar_role_weight = 99

  if (accessToken) {
    let parsedToken: any = jwt_decode(accessToken);

    if ((!parsedToken) || parsedToken.exp < Date.now() / 1000) {
      return {
        user: user,
        role_header: role_header,
        ...roles,
        scanow_role_weight: scanow_role_weight,
        sonar_role_weight: sonar_role_weight,
        licence: licence,
        accessToken: accessToken,
      }
    }




    roles = {
      isLogged: true,
      isScanowAdmin: parsedToken.claims["x-hasura-allowed-roles"].includes("scanow-admin"), // 1
      isScanowManager: parsedToken.claims["x-hasura-allowed-roles"].includes("scanow-manager"), // 2
      isScanowAgent: parsedToken.claims["x-hasura-allowed-roles"].includes("scanow-agent"), // 3
      isAdministrationAdmin: parsedToken.claims["x-hasura-allowed-roles"].includes("administration-admin"),
      isSonarAdmin: parsedToken.claims["x-hasura-allowed-roles"].includes("sonar-admin"),
      isSonarManager: parsedToken.claims["x-hasura-allowed-roles"].includes("sonar-manager"),
      isSonarAgent: parsedToken.claims["x-hasura-allowed-roles"].includes("sonar-agent"),
      isSonarChecking: parsedToken.claims["x-hasura-allowed-roles"].includes("sonar-checking"),
    };

    // Compute Scanow role weight 
    if (roles.isScanowAdmin) { scanow_role_weight = 1 } else
      if (roles.isScanowManager) { scanow_role_weight = 2 } else
        if (roles.isScanowAgent) { scanow_role_weight = 3 } else { scanow_role_weight = 4 }

    // compute Sonar role weight
    if (roles.isSonarAdmin) { sonar_role_weight = 1 } else
      if (roles.isSonarManager) { sonar_role_weight = 2 } else
        if (roles.isSonarAgent) { sonar_role_weight = 3 } else { sonar_role_weight = 4 }

    user = {
      firstname: parsedToken.firstname,
      lastname: parsedToken.lastname,
      avatar_url: parsedToken.avatar_url,
      id: parsedToken.claims["x-hasura-user-id"],
      email: parsedToken.email,
      company_id: parsedToken.claims["x-hasura-company-id"],
    };

    licence = {
      count: parsedToken.licence.count,
      end_date: parsedToken.licence.end_date,
      options: {
        control: parsedToken.licence.options.control,
        documentation: parsedToken.licence.options.documentation,
      },
    };

    switch (scanow_role_weight) {
      case 1:
        role_header = "scanow-admin"
        break;
      case 2:
        role_header = "scanow-manager"
        break;
      case 3:
        role_header = "scanow-agent"
        break;
      default:
        role_header = "public"
    }
  }


  return {
    user: user,
    role_header: role_header,
    ...roles,
    scanow_role_weight: scanow_role_weight,
    sonar_role_weight: sonar_role_weight,
    licence: licence,
    accessToken: accessToken,
  }
}


function extractData(tokenData: any){



}