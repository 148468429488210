import React from "react";
import { useRouter } from "next/router";
import { Text, Button } from "@scanow/ui";
import { ITeam } from "../Interfaces";
import {
  HiOutlineUsers,
  HiOutlineBellAlert,
  HiOutlineBellSlash,
  HiOutlinePhone,
  HiOutlineEnvelope,
} from "react-icons/hi2";

interface TeamProps {
  team: ITeam;
}

 

export function TeamDetail({ team }: TeamProps): JSX.Element {
  const router = useRouter();
  return (
    <section className="flex h-full flex-col justify-center items-center gap-2">
      <div
        className="flex items-center justify-center bg-slate-200 text-slate-700 h-16 w-16 p-2 rounded-full"
        mx-auto
      >
        <HiOutlineUsers size={32} />
      </div>
      <Text.Title>Équipe {team.name}</Text.Title>
      {/*<Text.Subtitle>{team.company.name}</Text.Subtitle>
      <Text.Normal>
        {team.users.length === 0 && "Aucun membre dans cette équipe"}
        {team.users.length === 1 && "1 membre"}
        {team.users.length > 1 && `${team.users.length} membres`}
      </Text.Normal>*/}
      <div className="grid gap-y-4">
        <div className="flex items-center justify-between gap-2">
          <HiOutlineEnvelope className="text-slate-700 h-9 w-9 p-2 bg-slate-200 rounded-full" />
          <Text.Normal>{team.mail}</Text.Normal>
          <Button.Circle>
            {team.mail_notify_on_incident ? (
              <>
                <HiOutlineBellAlert
                  className="w-5 h-5"
                  title="Notifications activées"
                />
              </>
            ) : (
              <>
                <HiOutlineBellSlash
                  className="w-5 h-5"
                  title="Notifications désactivées"
                />
              </>
            )}
          </Button.Circle>
        </div>
        <div className="flex items-center justify-between">
          <HiOutlinePhone className="text-slate-700 h-9 w-9 p-2 bg-slate-200 rounded-full" />
          <Text.Normal>{team.phone}</Text.Normal>
          <Button.Circle>
            {team.phone_notify_on_incident ? (
              <>
                <HiOutlineBellAlert
                  className="w-5 h-5"
                  title="Notifications activées"
                />
              </>
            ) : (
              <>
                <HiOutlineBellSlash
                  className="w-5 h-5"
                  title="Notifications désactivées"
                />
              </>
            )}
          </Button.Circle>
        </div>
      </div>
    </section>
  );
}
