import React, { Fragment, useState } from "react";
import Link from "next/link";
import { toast } from "react-toastify";
import { IoEllipsisHorizontal, IoPeopleOutline, IoRocketOutline, IoSettingsOutline, } from "react-icons/io5";
import {Text, Avatar, Modal, Paragraph} from "@scanow/ui";
import { Menu, Transition } from "@headlessui/react";
import { CompanyDefaultTeamUpdate } from "../../company";
import { TeamUpdateWrapper } from "../../team";
import { ISettingsCompany } from "../Interfaces";


interface TeamProps {
  company: ISettingsCompany;
}

export function ServiceTeam({ company }: TeamProps): JSX.Element {

  const [updateTeamModal, setUpdateTeamModal] = useState(false);
  const [defaultTeamModal, setDefaultTeamModal] = useState(false);



  return (
    <>
      <div className="flex justify-between">
          <Text.Subtitle>Mon équipe</Text.Subtitle>
          <Text.Normal>{company.default_team?.name}</Text.Normal>

        <Menu as="li" className="relative inline-block ">
          <Menu.Button
            //onClick={}
            className="relative align-middle"
          >
            <IoEllipsisHorizontal className="w-5 h-5" aria-hidden="true" />
          </Menu.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              as="ul"
              className="absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              <div className="py-1">
                <Menu.Item as="li">
                  <Link href="#">
                    <a
                      className="inline-flex items-center px-4 py-2 text-sm"
                      onClick={(e) => {
                        e.preventDefault();
                        setDefaultTeamModal(true);
                      }}
                    >
                      <IoRocketOutline size={20} className="mx-auto" />
                      <span className="ml-4">Changer l'équipe par défaut</span>
                    </a>
                  </Link>
                </Menu.Item>
                <Menu.Item as="li">
                  <Link href="#">
                    <a
                      className="inline-flex items-center px-4 py-2 text-sm"
                      onClick={(e) => {
                        e.preventDefault();
                        setUpdateTeamModal(true);
                      }}
                    >
                      <IoSettingsOutline size={20} />
                      <Text.Normal appendClassName="ml-4">
                        Configurer l'équipe {company.default_team?.name}
                      </Text.Normal>
                    </a>
                  </Link>
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
      <div className="flex mt-6 md:columns-1 columns-2 gap-6 text-font-light">
        <Paragraph>
            <Text.Normal>Email: {company.default_team?.mail}</Text.Normal>
          <br />
            <Text.Normal>Téléphone : {company.default_team?.phone}</Text.Normal>
        </Paragraph>
        <div></div>
      </div>
      <div className="flex items-center mt-12 ">
        <ul>
          <>
            <div className="flex items-center overflow-hidden mt-2">
              <IoPeopleOutline className="inline-block h-6 w-6 rounded-full text-white border-2 border-white object-cover object-center" />
              {company.default_team?.users.map((user) => (
                <Link href={"/admin/user/" + user.id}>
                  <li
                    key={user.id}
                    className="cursor-pointer -ml-2 inline-block h-12 w-12 rounded-full text-white border-2 border-white object-cover object-center"
                  >
                    <Avatar
                      src={user.avatar_url || "/images/no_avatar.png"}
                      alt={user.firstname + " " + user.lastname.toUpperCase()}
                      title={user.firstname + " " + user.lastname.toUpperCase()}
                      className="w-16 h-16"
                    />
                  </li>
                </Link>
              ))}
            </div>
          </>
        </ul>
      </div>

      {/*Update Modal*/}
      <Modal
        isOpen={updateTeamModal}
        setState={setUpdateTeamModal}
        //TODO: Traduction du title
        title={"Configurer " + " " + company.default_team?.name}
      >
        {company.default_team && (
          <TeamUpdateWrapper
            id={company.default_team.id}
            cb_success={(data: any) => {
              setUpdateTeamModal(false);
              toast.success("Équipe modifiée avec succès");
            }}
            cb_error={(err: any) => {
              toast.error("Erreur lors de la modification de l'équipe");
            }}
          />
        )}
      </Modal>

      <Modal
        isOpen={defaultTeamModal}
        setState={setDefaultTeamModal}
        //TODO: Traduction du title
        title={"Changer l'équipe par défaut"}
      >
        {company.default_team && (
          <CompanyDefaultTeamUpdate
            company_id={company.id}
            team_id={company.default_team.id}
            cb_success={(data: any) => {
              setDefaultTeamModal(false);
              toast.success("Équipe modifiée avec succès");
            }}
            cb_error={(err: any) => {
              toast.error("Erreur lors de la modification de l'équipe");
            }}
          />
        )}
      </Modal>
      {/*FIN Update Modal*/}
    </>
  );
}