import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import { useRouter } from "next/router";
import React from "react";
import { Text, InputField, SubmitButton } from "@scanow/ui";
import { generateHash } from "@scanow/helpers";
import { UPDATE_USER, GET_ALL_USERS, GET_ALL_USERS_NAME, GET_UPDATE_USER } from "../Queries";

interface Props {
  id: string
  cb_success: any
  cb_error: any
}

interface IFormData {
  password: string
  passwordConfirmation: string
}

export function UserPasswordResetForm({ id, cb_success, cb_error }: Props): JSX.Element {
  const { register, handleSubmit, watch, setValue, getValues, unregister, reset, formState: { errors } } = useForm<IFormData>()
  const router = useRouter()
  const password_watch = watch("password")
  const [updateUser] = useMutation(UPDATE_USER, {
    refetchQueries: [
      GET_ALL_USERS,
      GET_ALL_USERS_NAME,
      GET_UPDATE_USER
    ],
  });

  function submitHandler(data: IFormData) {

    updateUser({
      variables: {
        id: id,
        _set: {
          password: generateHash(data.password)
        }
      },
    })
      .then((data) => {
        // en cas de succès de la requète
        // Verifier si une fonction callback est dispo
        if (cb_success) {
          cb_success(data.data.user);
        } else {
          //Sinon executer fonction par défaut
          router.reload();
        }
      })
      .catch((err) => {
        console.error(err)
        // en cas de d'erreur de la requète
        // Verifier si une fonction callback est dispo
        if (cb_error) {
          cb_error(err);
        } else {
          //Sinon executer fonction par défaut
          console.error(err);
        }
      });

  }

  return (
    <form onSubmit={handleSubmit(submitHandler)}>
      <InputField type="password" label={"Mot de passe"} register={register("password", {
        required: true,
        minLength: 8
      })} />
      {errors.password && <Text.Medium appendClassName={"text-danger-500"}>Mot de passe au moins de 8 caractères</Text.Medium>}

      <InputField type="password" label={"Confirmation du mot de passe"} register={register("passwordConfirmation", {
        validate: value => {
          return (value === password_watch)
        }
      })} />
      {errors.passwordConfirmation && <Text.Small appendClassName={"text-danger-500"}>Les mots de passes ne sont pas identiques</Text.Small>}
      <SubmitButton />

    </form>)
}