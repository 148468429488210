import React from "react";
import { SelectField } from "@scanow/ui";
import { useQuery } from "@apollo/client";
import { GET_ALL_TEAMS_NAME } from "../Queries";
import { FieldErrorsImpl, UseFormRegisterReturn } from "react-hook-form";

interface TeamProps {
  register: UseFormRegisterReturn;
  label?: string;
  errors?: FieldErrorsImpl<any>;
}

interface ITeams {
  id: string;
  name: string;
}

interface TeamData {
  teams: ITeams[];
}

export function TeamSelectorField({
  label,
  errors,
  register,
}: TeamProps): JSX.Element {
  const { data, error, loading } = useQuery<TeamData>(GET_ALL_TEAMS_NAME);

  return (
    <>
      {data && (
        <SelectField
          errors={errors}
          options={data.teams.map((i) => {
            return { value: i.id, name: i.name };
          })}
          label={label || "Équipe par défaut"}
          placeholder="--- Sélectionnez l'équipe par défaut ---"
          register={register}
        />
      )}
    </>
  );
}
