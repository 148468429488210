import moment from "moment-timezone";
import "moment/locale/fr";

/**
 * Format datetime
 * Ex: dateFormat(datetime).formatDayMonth()
 * @param input_datetime laisser vide pour date du jour 
 */




export function dateFormat(input_datetime: string = "today", locale: string = "fr") {
  const _moment = moment(input_datetime != "today" ? input_datetime : undefined).tz("Europe/Paris").locale(locale);
  //const _today = moment().format("DD/MM/YYYY [à] H:mm");
  //moment().tz("Europe/Paris").locale(locale);

function formatTodayDate(){
  return moment().format("DD/MM/YYYY"); 
}

function formatTodayDateTime(){
  //return moment().format("DD/MM/YYYY H:mm"); 
  switch (locale) {
    case "fr":
      return _moment.format("DD/MM/YYYY [à] H:mm");
    case "en":
      return _moment.format("DD/MM/YYYY [at] H:mm");
  }
}

  function formatDayMonth() {
    return _moment.format("DD/MM");
  }

  function formatFullDate() {
    return _moment.format("DD/MM/YYYY");
  }

  function formatFullDateTime() {
    switch (locale) {
      case "fr":
        return _moment.format("DD/MM/YYYY [à] H:mm");
      case "en":
        return _moment.format("DD/MM/YYYY [at] H:mm");
    }
  }

  function formatLongDate() {
    return _moment.format("dddd DD MMMM YYYY");
  }

  function formatLongDateTime() {
    switch (locale) {
      case "fr":
        return _moment.format("dddd DD MMMM YYYY [à] H:mm");
      case "en":
        return _moment.format("dddd DD MMMM YYYY [at] H:mm");
    }
  }

  function formatItilFullDate() {
    return _moment.format("YYYYMMDDHHmmss");
  }

  return {
    formatTodayDate: formatTodayDate,
    formatTodayDateTime: formatTodayDateTime,
    formatDayMonth: formatDayMonth,
    formatFullDate: formatFullDate,
    formatFullDateTime: formatFullDateTime,
    formatLongDate: formatLongDate,
    formatLongDateTime: formatLongDateTime,
    formatItilFullDate: formatItilFullDate,
    moment: _moment
  };
}