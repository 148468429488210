import React from "react";
import Link from "next/link";
import { APP_NAME, APP_VERSION } from "@scanow/config";

export interface ILogo {
  img?: string;
  imgDark?: string;
  width?: string;
  height?: string;
  className?: string;
}
export function Logo({
  img = "/images/logo-280X56.png",
  imgDark = "/images/logo-280X56-pink.png",
  width = " ",
  height = " ",
}: ILogo) {
  return (
    <>
      {img ? (
        <>
          <img
            className={`block ${width} ${height} ${
              imgDark ? "dark:hidden" : ""
            }`}
            src={img}
            alt={APP_NAME + " " + APP_VERSION}
            title={APP_NAME + " " + APP_VERSION}
          />
        </>
      ) : (
        <span className={`block ${imgDark ? "dark:hidden" : ""}`}>
          {APP_NAME + " "} <small>{APP_VERSION}</small>
        </span>
      )}
      {imgDark ? (
        <>
          <img
            className={`hidden ${width} ${height}  ${
              imgDark ? "dark:block" : ""
            }`}
            src={imgDark}
            alt={APP_NAME + " " + APP_VERSION}
            title={APP_NAME + " " + APP_VERSION}
          />
        </>
      ) : (
        <span className={`hidden ${imgDark ? "dark:block" : ""}`}>
          {APP_NAME + " "} <small>{APP_VERSION}</small>
        </span>
      )}
    </>
  );
}
