import React, { FormEventHandler, useState } from "react";
import { Table } from "@scanow/ui";
import { ActionCell } from "@scanow/ui";
import { Modal } from "@scanow/ui";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { useTranslation } from "next-i18next";
import { RiSettings3Line } from "react-icons/ri";
import { apolloErrorParser } from "@scanow/helpers";
import { LocationCreateForm } from "../Form/Create";
import { ILocation } from "../Interfaces";
import { DELETE_LOCATION, GET_ALL_LOCATIONS, GET_ALL_LOCATIONS_NAME } from "../Queries";
import { LocationDetailWrapper } from "../Wrapper/Detail";
import { LocationUpdateWrapper } from "../Wrapper/Update";
import { useRouter } from "next/router";

interface LocationProps {
  locations: ILocation[];
  role: number;

  readOnly?: boolean;
}

export function LocationList({ locations, role, readOnly}: LocationProps): JSX.Element {
  const { t } = useTranslation("location");
  const [updateModalOpen, setUpdateModalOpen] = useState<boolean>(false);
  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);
  const [detailModalOpen, setDetailModalOpen] = useState<boolean>(false);
  const [selectedLocation, setSelectedLocation] = useState<ILocation | null>(
    null
  );
  // TODO Faire le soft delete pour les locations
  const [deleteLocation] = useMutation(DELETE_LOCATION, {
    refetchQueries: [GET_ALL_LOCATIONS, GET_ALL_LOCATIONS_NAME],
  });

  const router = useRouter()

  const columns = React.useMemo(
    () => [
      {
        Header: "Localité",
        accessor: "name",
      },
      // {
      //   Header: t("table.created_at"),
      //   accessor: (row: any) => dateFormat(row.created_at).formatFullDate(),
      // },
      {
        //@ts-ignore
        Header: <RiSettings3Line size={22} alt="Action" />,
        accessor: "action",
        disableSortBy: true,
        Cell: (props: any) => {
          function showHandler(event: FormEventHandler) {



            setSelectedLocation(props.cell.row.original);
            setDetailModalOpen(true);
          }

          function updateHandler(event: FormEventHandler) {



            setSelectedLocation(props.cell.row.original);
            setUpdateModalOpen(true);
          }

          function deleteHandler(event: any) {

            deleteLocation({ variables: { id: props.cell.row.original.id } })
              .then((data: any) => {
                toast.success("Localité supprimée avec succès !");
              })
              .catch((err) => {
                let parsed_error = apolloErrorParser(err);
                toast.error("Erreur lors de la tentative de suppression de la localité.");
              });
          }

          return ActionCell({
            showHandler: showHandler,
            updateHandler: readOnly ? undefined : updateHandler,
            deleteHandler: readOnly ? undefined : deleteHandler,
            ...props,
          });
        },
      },
    ],
    []
  );

  return (
    <div>
      <Table
        columns={columns}
        data={locations}
        cb_add={readOnly ? undefined :() => {
          setCreateModalOpen(true);
        }}
        onRowClick={(row: any) => {
          router.push(`/admin/location/${row.original.id}`);
        }}
      />

      {/*Create Modal*/}
      <Modal
        isOpen={createModalOpen}
        setState={setCreateModalOpen}
        title="Créer une localité"
      >
        <LocationCreateForm
          cb_success={(data: any) => {
            setCreateModalOpen(false);
            toast.success("Localité créée avec succès !");
          }}
          cb_error={(err: any) => {

            toast.error("Erreur lors de la tentative de création de la localité.");
          }}
        />
      </Modal>
      {/*Fin Create Modal*/}

      {/*Update Modal*/}
      <Modal
        isOpen={updateModalOpen}
        setState={setUpdateModalOpen}
        title={"Modifier" + " " + selectedLocation?.name }
      >
        <>
          {selectedLocation && (
            <LocationUpdateWrapper
              role={role}
              id={selectedLocation.id}
              cb_success={(data: any) => {
                setUpdateModalOpen(false);
                toast.success("Localité modifiée avec succès !");
              }}
              cb_error={(err: any) => {
                toast.error("Erreur lors de la tentative de modification de la localité.");
              }}
            />
          )}
        </>
      </Modal>
      {/*FIN Update Modal*/}

      {/*Detail Modal*/}
      <Modal
        isOpen={detailModalOpen}
        setState={setDetailModalOpen}
        title={"Localité "+ selectedLocation?.name}
      >
        <>
          {selectedLocation && (
            <LocationDetailWrapper id={selectedLocation.id} />
          )}
        </>
      </Modal>
    </div>
  );
}