import moment from "moment";
import { useTranslation } from "next-i18next";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { ICompanyLicence } from "../Interfaces";
import { InputField, CheckboxField, SubmitButton } from "@scanow/ui";
import { useMutation } from "@apollo/client";
import { LICENCE_REQUEST } from "../Queries";
import { toast } from "react-toastify";
import React from "react";


interface CompanyProps {
  company: ICompanyLicence
  type: "renew" | "update";
}

interface FormData {
  company_id: string;
  company_name: string;
  licence_count: number
  licence_end_date: string
  licence_option_control: boolean
  licence_option_documentation: boolean
}

export function LicenceRequestForm({ company, type = "update" }: CompanyProps): JSX.Element {
  const { t } = useTranslation("company");
  const { register, handleSubmit, watch, setValue, getValues, unregister, reset, formState: { errors } } = useForm<FormData>({
    defaultValues: {
      company_id: company.id,
      company_name: company.name,
      licence_count: company.licence_count,
      licence_option_control: company.licence_option_control,
      licence_option_documentation: company.licence_option_documentation,
      licence_end_date: moment(company.licence_end_date).format("YYYY-MM-DD[T]HH:mm")
    },
  });

  const [licenceNotifyMutation] = useMutation(LICENCE_REQUEST);

  function submitHandler(data: FormData) {


    licenceNotifyMutation({
      variables: {
        data: {
          company_id: data.company_id,
          company_name: data.company_name,
          //@ts-ignore
          licence_count: parseInt(data.licence_count),
          //@ts-ignore
          licence_option_control: data.licence_option_control == "true" ? true : false,
          //@ts-ignore
          licence_option_documentation: data.licence_option_documentation == "true" ? true : false,
          licence_end_date: data.licence_end_date
        }
      }
    }).then(res => {
      toast.success("La demande de licence a été envoyée, nous vous contacterons dans les plus brefs délais.");

    }).catch(err => {
      console.error(err);
      toast.error("Erreur lros de la tentative d'envoi du formaulaire de demande de licence.");
    });
  }

  useEffect(() => {
    reset();
  }, []);

  const remainingResource =
    company.licence_count - company.resources_aggregate.aggregate.count;


  return (
    <form onSubmit={handleSubmit(submitHandler)}>
      <fieldset className="border border-black p-4" >
        <legend className="text-center p-3 border border-black">{"Licence"}</legend>

        <InputField
          label={`${remainingResource} disponibles`}
          register={register("licence_count", {
            required: "Ce champ est requis !",
          })}
          errors={errors}
        />

        <InputField
          label="Date de fin"
          type="datetime-local"
          register={register("licence_end_date", {
            required: "Ce champ est requis !",
          })}
          errors={errors}
        />
      </fieldset>
      <br />
      <fieldset className="border border-black p-4" >
        <legend className="text-center p-3 border border-black">{"Options"}</legend>
        <CheckboxField label="Documentation" register={register("licence_option_documentation")} />
        <CheckboxField label="Maintenance" register={register("licence_option_control")} />
      </fieldset>
      <SubmitButton className="w-full my-5 p-3" />
    </form>
  );



}