import React, { Fragment, MouseEventHandler, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import {
  HiOutlineEllipsisHorizontal,
  HiOutlineEye,
  HiOutlinePencil,
  HiOutlineLockClosed,
  HiOutlineTrash,
  HiOutlineBell,
} from "react-icons/hi2";
import { Text, Modal, Button } from "@scanow/ui";

export interface ActionCellProps {
  showHandler: MouseEventHandler<HTMLButtonElement>;
  updateHandler: MouseEventHandler<HTMLButtonElement>;
  deleteHandler?: MouseEventHandler<HTMLButtonElement>;
  passwordResetHandler?: MouseEventHandler<HTMLButtonElement>;
  closeIncidentHandler?: MouseEventHandler<HTMLButtonElement>;
  declareIncidentHandler?: MouseEventHandler<HTMLButtonElement>;
}

export function ActionCell({
  showHandler,
  updateHandler,
  deleteHandler,
  passwordResetHandler,
  closeIncidentHandler,
  declareIncidentHandler,
}: ActionCellProps) {
  const [deleteModal, setDeleteModal] = useState<boolean>(false);

  return (
    <>
      <Menu as="div" className="relative inline-block text-left">
        <Menu.Button className="relative align-middle">
          <HiOutlineEllipsisHorizontal className="w-5 h-5" aria-hidden="true" />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            as="ul"
            className={`absolute origin-top-left lg:right-0 w-56 mt-2 bg-white dark:bg-neutral-900 rounded-2xl divide-y divide-neutral-100 shadow-lg ring-1 ring-black dark:ring-white ring-opacity-5 dark:ring-opacity-10 focus:outline-none z-30`}
          >
            <div className="text-xs px-1 py-3 text-sm text-neutral-60 dark:text-neutral-300">
              {showHandler && (
                <Menu.Item
                  as={"button"}
                  onClick={showHandler}
                  className="px-3 py-2 w-full hover:bg-secondary-600 hover:text-white flex items-center dark:hover:bg-neutral-700 transition ease-out duration-300"
                >
                  <HiOutlineEye className="w-4 h-4" />
                  <span className="ml-4">Afficher</span>
                </Menu.Item>
              )}
              {updateHandler && (
                <Menu.Item
                  as="button"
                  onClick={updateHandler}
                  className="px-3 py-2 w-full hover:bg-secondary-600 hover:text-white flex items-center dark:hover:bg-neutral-700 transition ease-out duration-300"
                >
                  <HiOutlinePencil className="w-4 h-4" />
                  <span className="ml-4">Modifier</span>
                </Menu.Item>
              )}

              {passwordResetHandler && (
                <Menu.Item
                  as="button"
                  onClick={passwordResetHandler}
                  className="px-3 py-2 w-full hover:bg-secondary-600 hover:text-white flex items-center dark:hover:bg-neutral-700 transition ease-out duration-300"
                >
                  <HiOutlineLockClosed className="w-4 h-4" />
                  <span className="ml-4">Modifier mot de passe</span>
                </Menu.Item>
              )}
              {deleteHandler && (
                <Menu.Item
                  as="button"
                  onClick={() => setDeleteModal(true)}
                  className="px-3 py-2 w-full hover:bg-secondary-600 hover:text-white flex items-center dark:hover:bg-neutral-700 transition ease-out duration-300"
                >
                  <HiOutlineTrash className="w-4 h-4" />
                  <span className="ml-4">Supprimer</span>
                </Menu.Item>
              )}

              {closeIncidentHandler && (
                <Menu.Item
                  as="button"
                  onClick={closeIncidentHandler}
                  className="px-3 py-2 w-full hover:bg-secondary-600 hover:text-white flex items-center dark:hover:bg-neutral-700 transition ease-out duration-300"
                >
                  <HiOutlineTrash className="w-4 h-4" />
                  <span className="ml-4">Clôturer incident</span>
                </Menu.Item>
              )}

              {declareIncidentHandler && (
                <Menu.Item
                  as="button"
                  onClick={declareIncidentHandler}
                  className="px-3 py-2 w-full hover:bg-secondary-600 hover:text-white flex items-center dark:hover:bg-neutral-700 transition ease-out duration-300"
                >
                  <HiOutlineBell className="w-4 h-4" />
                  <span className="ml-4">Déclarer un incident</span>
                </Menu.Item>
              )}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
      <Modal
        isOpen={deleteModal}
        setState={setDeleteModal}
        title="Confirmer la suppression ?"
      >
        <div>
          <Text.Normal>
            Cette action est irréversible, êtes-vous sûr de vouloir continuer ?
          </Text.Normal>
          <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-4 mt-2">
            <Button.Danger
              onClick={(e) => {
                deleteHandler && deleteHandler(e);
                setDeleteModal(false);
              }}
            >
              Supprimer
            </Button.Danger>
            <Button.Default
              onClick={() => {
                setDeleteModal(false);
              }}
            >
              Annuler
            </Button.Default>
          </div>
        </div>
      </Modal>
    </>
  );
}