import React from "react";
import { SelectField } from "@scanow/ui";
import { useQuery } from "@apollo/client";
import { UseFormRegisterReturn } from "react-hook-form";
import { GET_ALL_COMPANIES_NAME } from "../Queries";

interface CompanyProps {
  register: UseFormRegisterReturn;
  label?: string;
}

interface ICompany {
  id: string;
  name: string;
}

interface Query {
  companies: ICompany[];
}

export function CompanySelectorField({ label, register }: CompanyProps): JSX.Element {
  const { data, error, loading } = useQuery<Query>(GET_ALL_COMPANIES_NAME);
  //TODO Traductions
  return (
    <>
      {data && (
        <SelectField
          options={data.companies.map((i) => {
            return { value: i.id, name: i.name };
          })}
          label={label || "Entreprise"}
          placeholder="--- Sélectionnez l'entreprise ---"
          register={register}
        />
      )}
    </>
  );
}