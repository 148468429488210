import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { InputField, SubmitButton, Text } from '@scanow/ui';
import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { CREATE_LOCATION, GET_ALL_LOCATIONS, GET_ALL_LOCATIONS_LIST } from '../Queries';
import { capitalizeIfNeeded } from '@scanow/helpers';


interface ILocationFormData {
  name: string;
  latitude?: string;
  longitude?: string;
}

interface LocationProps {
  cb_success?: any;
  cb_error?: any;
}

export function LocationCreateForm({ cb_success, cb_error }: LocationProps): JSX.Element {
  const { register, handleSubmit, setValue, getValues, formState: { errors } } = useForm();
  const router = useRouter();
  const [submittedData, setSubmitedData] = useState<ILocationFormData | null>(null);
  const [createLocation] = useMutation(CREATE_LOCATION, {
    refetchQueries: [GET_ALL_LOCATIONS, GET_ALL_LOCATIONS_LIST],
  });

  function submitHandler(data: any) {
    createLocation({
      variables: {
        name: capitalizeIfNeeded(data.name),
      },
    })
      .then((data) => {
        // en cas de succès de la requète
        // Verifier si une fonction callback est dispo
        if (cb_success) {
          cb_success(data.data.company);
        } else {
          //Sinon executer fonction par défaut
          setSubmitedData(data.data.company);
          router.reload();
        }
      })
      .catch((err) => {
        // en cas de d'erreur de la requète
        // Verifier si une fonction callback est dispo
        if (cb_error) {
          cb_error(err);
        } else {
          //Sinon executer fonction par défaut
          console.error(err);
        }
      });

  }


  return (
    <>
      {submittedData ? (
        <Text.Normal>Localité créée avec succès !</Text.Normal>
      ) : (
        <form onSubmit={handleSubmit(submitHandler)}>
          <InputField
            errors={errors}
            label={'Nom'}
            register={register('name', { required: 'Ce champ est requis !' })}/>

          <SubmitButton value="Enregistrer"/>
        </form>
      )}
    </>
  );
}