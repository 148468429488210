import { useQuery } from "@apollo/client";
import { Loading, ErrorMessage } from "@scanow/ui"
import React from "react";
import { LocationDetail } from "../Data/Detail";
import { ILocation } from "../Interfaces";
import { GET_LOCATION_DETAIL } from "../Queries";

interface Props {
  id: string | string[];
}
interface LocationData {
  location: ILocation
}

export function LocationDetailWrapper({ id }: Props): JSX.Element {
  const { data, loading, error } = useQuery<LocationData>(GET_LOCATION_DETAIL, { variables: { id: id } });
  if (loading) {
    return (<Loading />);
  }
  if (error) {
    console.error(error);
    return (<ErrorMessage message="Une erreur inconnue s'est produite"/>)
  }
  if (data) {
    return <LocationDetail location={data.location} />;
  }

  return (<ErrorMessage message="Une erreur inconnue s'est produite"/>)
}