import React from "react";
import Image from "next/image";

interface IAvatar{
  src: string;
  alt?: string;
  title?: string;
  className?: string;
  appendClassName?: string;
} 

export function Avatar({src, alt, title, className="relative overflow-hidden rounded-full", appendClassName =" "}: IAvatar): JSX.Element {
  return (
    <div className={className  + " " + appendClassName}>
      <Image
        src={src}
        alt={alt}
        //className={className}
        title={title}
        objectFit="cover"
        layout="fill" />
    </div>
  )}

