import React from "react"
import {THEME_TYPO_PARAGRAPH} from "@scanow/theme";

export interface IPageParagraph {
  children: React.ReactNode;
  className?: string;
  appendClassName?: string;
}

export function Paragraph({ children, className, appendClassName }: IPageParagraph) {
  return (
    <p className={`${THEME_TYPO_PARAGRAPH} ${appendClassName}`}>{children}</p>
  );
}